import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import type { Theme } from '@reaidy/components';
import { Button } from '@xtreamsrl/react-ui-kit/Button';

const panelShadow = (theme: Theme) =>
  `${theme.palette.brand[2]} 0px 0px 3px 2px`;

const panelBorder = (theme: Theme) => `solid 1px ${theme.palette.brand[7]}`;

export const Panel = styled(Box)<{
  minWidth?: string | number;
  maxWidth?: string | number;
  minHeight?: string | number;
  maxHeight?: string | number;
  width?: string | number;
}>(({ theme, minWidth, maxWidth, minHeight, maxHeight, width }) => ({
  borderRadius: '8px',
  boxShadow: panelShadow(theme),
  border: panelBorder(theme),
  backgroundColor: theme.palette.white?.main,
  overflow: 'auto',
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  width,
}));

export const ButtonShadow = styled(Button)(({ theme }) => ({
  boxShadow: panelShadow(theme),
}));

export const InputShadow = styled('textarea')(({ theme }) => ({
  boxShadow: panelShadow(theme),
  backgroundColor: theme.palette.white?.main,
  border: panelBorder(theme),
  appearance: 'none',
  padding: '0.5rem',
  borderRadius: '8px',
  outline: 'none',
}));
