import { identifyUser, setCommonEventProps } from '@xtreamsrl/react-analytics';
import { getIdToken, signInWithEmailAndPassword } from 'firebase/auth';
import { getUserAPI } from 'src/_shared/apis/getUser';
import { auth } from '../../_shared/config';
import { setConfig } from '../../_shared/fetch';
import { UserInfo } from '../../_shared/types';

export async function loginAPI(data: {
  email: string;
  password: string;
}): Promise<UserInfo> {
  const userCredentials = await signInWithEmailAndPassword(
    auth,
    data.email,
    data.password,
  );
  const user = auth.currentUser;
  // if the user is present the token is set in the fetch config to be used in the requests
  if (user && userCredentials.user.email) {
    setConfig({
      getToken: () => getIdToken(user),
      //TODO: implement isTokenExpired properly
      isTokenExpired: () => Promise.resolve(false),
    });
  }

  // if the token has been properly set the user is fetched otherwise an error is thrown
  const userData = await getUserAPI();
  identifyUser({
    id: userData.id,
    email: userData.email,
    providerId: userData.providerId,
  });
  setCommonEventProps({ workspaceId: userData.workspaceId });
  return userData;
}
