export const translations = {
  it: {
    sideMenu: {
      home: 'Dashboard',
      write: 'Scrivi',
      archive: 'Archivio',
      templates: 'Templates',
      user: 'Nome utente',
      reduce: 'Riduci',
    },
    communicationTypeSelector: {
      header: 'Che tipo di comunicazione devi inviare?',
    },
    shared: {
      communicationTypes: {
        DEM: 'DEM',
        SMS: 'SMS',
        PAPER_MAIL: 'Mail cartacea',
        LANDING_PAGE: 'Landing page',
        PUSH_NOTIFICATION: 'Notifica push',
        SCRIPT: 'Script',
        RCS: 'RCS',
      },
      aimTypes: {
        COMMERCIAL: 'Comunicazione commerciale',
        INFOTAINMENT: 'Comunicazione di infotainment',
        SERVICE: 'Comunicazione di servizio',
      },
      copy: 'Copia',
      copied: 'Copiato',
      save: 'Salva',
      retrying: 'È in corso un tentativo di salvataggio...',
      cancel: 'Annulla',
      ok: 'Ok',
      delete: 'Elimina',
      proceed: 'Procedi',
      close: 'Chiudi',
    },
    commEditing: {
      status: {
        loading: 'Caricamento in corso...',
        loaded: 'Caricamento completato',
        error: 'Errore durante il caricamento',
      },
      draftList: {
        title: 'Proposte di Contenuto',
        renameForm: {
          label: 'Rinomina',
        },
        inlineMenu: {
          rename: 'Rinomina',
          delete: 'Elimina',
        },
        blockMenu: {
          duplicate: 'Duplica',
          variant: 'Duplica con variazione',
        },
      },
      newDraftModal: {
        title: 'Nuova proposta di contenuto',
        subtitle: 'Crea una nuova versione da zero partendo dal brief.',
        paragraph:
          'Riscrivi totalmente la proposta di contenuto, partendo dal brief. Le altre bozze rimarranno disponibili e immutate. La nuova proposta terrà conto della customer persona che indichi e di qualunque istruzione aggiunta.',
        form: {
          personaSelector: {
            label: 'Persona',
          },
          promptInput: {
            label: 'Indicazioni di cui tener conto',
          },
          submitButton: {
            label: 'Genera',
          },
        },
      },
      variantModal: {
        title: 'Duplica con variazione',
        subtitle: 'Crea una variante del contenuto',
        paragraph:
          "Puoi dare commenti o chiedere modifiche alla proposta di contenuto attuale, con la possibilità di indicare la persona per cui scrivere la comunicazione. La nuova proposta sarà comunque vicina all'originale.",
        form: {
          personaSelector: {
            label: 'Persona',
          },
          promptInput: {
            label: 'Indicazioni su cosa variare',
          },
          submitButton: {
            label: 'Genera',
          },
        },
      },
      duplicateModal: {
        title: 'Duplica',
        subtitle: 'Crea una copia esatta del contenuto',
        paragraph:
          'Puoi usare la nuova copia per sperimentare apportando modifiche al testo, senza influire sul contenuto della bozza originale.',
        form: {
          personaSelector: {
            label: 'Persona',
          },
          submitButton: {
            label: 'Genera',
          },
        },
      },
    },
    login: {
      welcome:
        'Reaidy ti aiuta a creare comunicazioni efficaci e personalizzate per qualsiasi destinatario.',
      emailRequired: 'Il campo email è obbligatorio',
      emailInvalid: 'Inserisci un indirizzo email valido',
      passwordRequired: 'Il campo password è obbligatorio',
      errorHeader: 'Login fallito',
      errorContent: 'Email o password non validi, riprova.',
    },
    dashboard: {
      lastProjects: 'Ultimi progetti',
      write: 'Scrivi un nuovo contenuto',
      archive: "Vai all'archivio",
      hero: {
        title: 'Buongiorno!',
        subtitle:
          'Crea un nuovo testo oppure edita e modifica i contenuti in archivio. Scegli ciò che preferisci.',
      },
    },
    brief: {
      header: 'Brief - Contenuto della comunicazione',
      indications:
        'Descrivi il contenuto della comunicazione nel modo più chiaro e completo possibile. Non preoccuparti della forma, ci penseremo noi.',
      generate: 'Genera il testo',
      name: 'Nome del prodotto o dell’iniziativa',
      persona: 'Persona',
      description: 'Descrizione',
      plusOrIncentives: 'Aspetti da evidenziare',
      error: {
        name: "È necessario inserire il nome del prodotto o dell'iniziativa",
        personaId: 'È necessario selezionare una persona',
        tooLongName:
          "Il nome del prodotto o dell'iniziativa è troppo lungo, il limite è di 40 caratteri",
        description: 'È necessario inserire una descrizione',
        plusOrIncentives: 'È necessario inserire degli aspetti da evidenziare',
      },
      regenerateHeader: 'Attenzione',
      regenerateContent:
        'Sei sicuro di voler rigenerare il testo? Le precedenti proposte verranno sovrascritte.',
      creationFailureHeader: 'Errore nella creazione',
      creationFailureContent:
        'Si è verificato un errore durante la creazione della comunicazione, assicurati di aver scelto un nome univoco e riprova.',
      generationFailureContent:
        'Si è verificato un errore durante la generazione del testo, riprova più tardi.',
      creationSuccessHeader: 'Comunicazione creata',
      creationSuccessContent:
        'La comunicazione è stata creata con successo, potrai trovarla nell’archivio.',
    },
    aimTypes: {
      header: "Qual è l'obiettivo della comunicazione?",
      indications:
        'Scegli la categoria che meglio rappresenta l’obiettivo della tua comunicazione. Questo aiuterà a generare un testo più adatto alle tue esigenze.',
    },
    template: {
      header: 'Per quale modello di layout desideri generare il testo?',
      indications:
        'Scegli il template che meglio si adatta alle tue esigenze e otterrai un testo adattato al tuo design.',
      choose: {
        header: 'Conferma la scelta: {{name}}',
        content:
          'Il template che hai selezionato è il seguente. Sei sicuro di voler procedere con la scelta?',
        confirm: 'Conferma',
        cancel: 'Annulla',
      },
      create: 'Crea un nuovo template',
      creationDescription:
        'Configura i campi per generare testi conformi al tuo template.',
      createNow: 'Crea ora',
      creation: {
        DEM: {
          subject: 'Oggetto',
          mainTitle: 'Titolo principale',
          subjectField: 'Oggetto dell’email',
          mainTitleField: 'Titolo principale',
        },
        PAPER_MAIL: {
          subject: 'Titolo busta',
          mainTitle: 'Titolo principale',
          subjectField: 'Titolo busta',
          mainTitleField: 'Titolo principale',
        },
        header: 'Creazione nuovo template',
        subject: 'Oggetto',
        section: 'Sezione {{index}}',
        addTitle: 'Aggiungi titolo principale',
        addSection: 'Aggiungi sezione',
        chars: 'caratteri',
        communicationType: 'Tipo di comunicazione',
        SECTION_TITLE: 'Titolo',
        TEXT: 'Testo',
        BULLET_POINT: 'Punto elenco',
        TITLED_BULLET_POINT: 'Punto elenco con titolo',
        CTA: 'Call to action',
        bulletMaxTitleLength: 'Titolo',
        bulletMaxContentLength: 'Testo',
        description: 'Descrizione (obbligatoria)',
        missingDescription: 'È necessario inserire una descrizione',
        descriptionOptional: 'Descrizione',
        submit: 'Crea il template',
        cancel: 'Annulla',
        preview:
          "In questa sezione sarà mostrata l'anteprima del template che stai creando. Puoi visualizzare il formato del testo generato e controllare che sia perfetto per te.",
        failure: {
          header: 'Creazione fallita',
          409: 'Impossibile creare il template poiché esiste già un template con lo stesso nome. Scegli un altro nome e riprova.',
        },
        //the following value is empty because we do not want the error message to be shown.
        // At the same time this i18n entry is necessary because the validation schema uses it,
        // and it is not possible to remove it (removing it would cause the insertion of a default error message)
        invalidMaxLength: '',
      },
      update: {
        submit: 'Salva',
      },
    },
    select: 'Seleziona',
    loading: 'Caricamento in corso...',
    editing: {
      yourDraft: 'La tua bozza',
      editBrief: 'Modifica il brief',
      proposal1: 'Proposta 1',
      proposal2: 'Proposta 2',
      proposal3: 'Proposta 3',
      undo: 'Annulla',
      redo: 'Ripristina',
      createDraft: 'Seleziona questa proposta',
      modify: 'Modifica',
      enterDirections: 'Inserisci le tue indicazioni',
      improve: 'Migliora',
      shorten: 'Accorcia',
      expand: 'Espandi',
      rephrase: 'Riformula',
      accept: 'Accetta',
      discard: 'Scarta',
      shareDraft: 'Copia link della bozza',
      saved: 'Tutte le modifiche sono state salvate',
      unsaved: 'Ci sono modifiche non salvate, attendi il salvataggio...',
      communicationNotFound:
        "Dev'esserci stato un errore, impossibile trovare la comunicazione",
      proposalSubtitle: 'Proposte contenuto',
      proposalsDescription:
        'Abbiamo creato tre proposte di testo personalizzate per te. Puoi apportare le modifiche che preferisci e creare il testo perfetto che meglio risponde alle tue specifiche esigenze e obiettivi. \n\n' +
        "Dopo aver elaborato la tua proposta, selezionala per procedere con l'invio o la declinazione in base ai profili delle Personas.",
      viewSubtitle: 'Preview contenuto',
      viewDescription:
        'Qui puoi visualizzare il testo della comunicazione e controllare che sia perfetto per te. In questa modalità non è possibile apportare modifiche.',
      changeNameFailed: 'Impossibile cambiare il nome del progetto',
      changeNameFailedMessage:
        'Il nome della comunicazione deve essere unico e non può essere vuoto',
      discardBriefEdits: {
        header: 'Chiusura del brief',
        content:
          'Sei sicuro di voler scartare tutte le modifiche apportate al brief?',
      },
      overwriteDraft: {
        button: 'Riparti da questa proposta',
        header: 'Overwriting della bozza',
        content:
          'Sei sicuro di voler sovrascrivere la bozza attuale? Tutte le modifiche effettuate andranno perse.',
      },
      selectPersonas: 'Selezione personas',
      personalisedCommunications: {
        share: 'Condividi le versioni',
        subtitle: 'Versioni personalizzate per le Personas',
        description:
          'Abbiamo creato una versione personalizzata per ciascuna delle Personas selezionate.\nPuoi visualizzare e condividere le versioni per ottenere feedback e approvazioni.',
        versionItem: 'Versione per {{name}}',
      },
      errors: {
        saving: {
          header: 'Errore durante il salvataggio',
          content:
            'Si è verificato un errore durante il salvataggio, qui di seguito ci sono le versioni che non siamo riusciti a salvare. Puoi copiare il contenuto per non perdere le modifiche.',
          retry: 'Riprova il salvataggio',
          discard: 'Chiudi e scarta le modifiche',
        },
      },
    },
    personasSelection: {
      proceed: 'Procedi',
      header: 'Declinazione contenuto su personas',
      indications:
        'Seleziona una o più delle "Personas",  adattaremo il messaggio per rispondere in modo preciso alle diverse esigenze e aspettative.',
      overwrite: {
        header: 'Sovrascrittura comunicazioni',
        content:
          'Sei sicuro di voler sovrascrivere le comunicazioni personalizzate già generate?',
      },
      error: {
        header: 'Impossibile procedere',
        content:
          'Selezionare almeno una persona per cui personalizzare la comunicazione',
      },
    },
    stepper: {
      contentType: 'Tipologia contenuto',
      aimSelection: 'Scopo',
      template: 'Template',
      templateDescription: 'Disponibile per DEM',
      brief: 'Brief',
      proposalSelection: 'Selezione',
      send: 'Invia',
      personasSelection: 'Personas',
      versions: 'Versioni',
      communicationEditing: 'Modifica',
    },
    archive: {
      header: 'Archivio contenuti',
      content:
        'Qui troverai tutte le comunicazioni create. Il colore del cerchio ti permetterà di identificare lo stato della comunicazione. Il rosso indica che la comunicazione è stata creata ma non è stata generata alcuna bozza. Il giallo che la bozza è stata generata ma non è stata ancora personalizzata. Il verde che la comunicazione è pronta per essere inviata nelle sue versioni adattate al profilo delle Personas selezionate.',
      filters: 'Filtri',
      filterBy: {
        createdByMe: 'Creato da me',
      },
      write: 'Scrivi',
      date: 'Data',
      project: 'Progetto',
      type: 'Formato',
      status: 'Stato',
      view: 'Visualizza',
      edit: 'Modifica',
      duplicateFromBrief: 'Duplica dal brief',
      noCommunications: 'Non ci sono ancora comunicazioni da mostrare',
      createNow: 'Creane una ora',
      communicationDeletion: {
        header: 'Eliminazione comunicazione',
        checkMessage:
          'Sei sicuro di voler cancellare definitivamente la comunicazione "{{communicationName}}"?',
        failureHeader: 'Cancellazione fallita',
        failure: {
          generic: 'Si è verificato un errore, riprova più tardi',
        },
      },
      duplicate: {
        buttonText: 'Duplica',
        header: 'Duplica Comunicazione',
        content:
          'Scegli il nome per la nuova comunicazione, ricorda che deve essere unico',
        failureHeader: 'Duplicazione fallita',
        failure: {
          404: 'Impossibile duplicare poiché la comunicazione non esiste.',
          409: 'Impossibile duplicare poiché esiste già una comunicazione con lo stesso nome con cui verrà generata la nuova comunicazione. Scegli un altro nome e riprova.',
        },
      },
      duplicateFromDraft: {
        buttonText: 'Duplica dalla bozza',
        header: 'Duplicazione dalla bozza',
        content:
          'Scegli il nome per la nuova comunicazione, ricorda che deve essere unico',
        failureHeader: 'Duplicazione dalla bozza fallita',
        failure: {
          404: 'Impossibile duplicare dalla bozza poiché la comunicazione non esiste.',
          409: 'Impossibile duplicare dalla bozza poiché esiste già una comunicazione con lo stesso nome con cui verrà generata la nuova comunicazione. Scegli un altro nome e riprova.',
        },
      },
      duplicateFromPersonas: {
        buttonText: 'Duplica dalle personas',
        header: 'Duplicazione dalla selezione delle personas',
        content:
          'Scegli il nome per la nuova comunicazione, ricorda che deve essere unico',
        failureHeader: 'Duplicazione da personas fallita',
        failure: {
          404: 'Impossibile duplicare da personas poiché la comunicazione non esiste.',
          409: 'Impossibile duplicare da personas poiché esiste già una comunicazione con lo stesso nome con cui verrà generata la nuova comunicazione. Scegli un altro nome e riprova.',
        },
      },
    },
    templatesArchive: {
      header: 'Archivio template',
      newTemplate: 'Nuovo template',
      content:
        'Questa sezione è dedicata alla gestione dei template DEM. Qui potrai modificare e duplicare i tuoi template per far sì che essi soddisfino tutte le esigenze.',
      filters: 'Filtri',
      createdAt: 'Data',
      name: 'Nome',
      communicationType: 'Formato',
      sections: 'Sezioni',
      noTemplates: 'Non ci sono ancora template da mostrare',
      edit: 'Modifica',
      duplicate: 'Duplica',
      filterByType: 'Filtra i template per tipo:',
      info: 'Per facilitare la ricerca di un template puoi filtrare i risultati in base al tipo di comunicazione. In più, puoi ordinare i template cliccando sui titoli delle colonne (Data, Nome e Formato), ricliccando su di essi invertirai l’ordine e facendolo un’altra volta tornerai alla configurazione di ordinamento iniziale.',
      preview: {
        header: 'Anteprima template',
        tooltip: 'Mostra anteprima',
        error:
          'Si è verificato un errore durante il caricamento dell’anteprima',
      },
      delete: {
        header: 'Eliminazione template',
        content:
          'Sei sicuro di voler cancellare definitivamente il template "{{templateName}}"?',
        error: {
          header: 'Eliminazione template fallita',
          content:
            'Si è verificato un errore durante l’eliminazione del template, riprova più tardi.',
        },
      },
    },
    genericError: 'Si è verificato un errore, riprova più tardi',
    time: {
      formats: {
        fullDateTime: '%d/%m/%Y %H:%M',
      },
    },
    date: {
      formats: {
        default: '%Y-%m',
        full: '%d/%m/%Y',
      },
    },
  },
};

export enum DateTimeFormat {
  ONLY_YEAR_MONTH_STANDARD = 'date.formats.default',
  FULL_DATE = 'date.formats.full',
  FULL_DATE_TIME = 'time.formats.fullDateTime',
}

type ObjectPaths<T, Prefix extends string = ''> = {
  [K in keyof T]: T[K] extends object
    ? ObjectPaths<T[K], `${Prefix}${Extract<K, string>}.`>
    : `${Prefix}${Extract<K, string>}`;
}[keyof T];

export type TranslationKeys = ObjectPaths<(typeof translations)['it']>;
