import { useTranslate } from '@xtreamsrl/react-i18n';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Spinner } from '@xtreamsrl/react-ui-kit/Spinner';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { useEffect, useRef } from 'react';
import { useActiveStep } from '../hooks/useActiveStep.ts';
import { useRetrieveCommunication } from '../hooks/useRetrieveCommunication.ts';
import { ContentCreator } from './ContentCreator.tsx';
import {
  setEditingViewData,
  resetEditingViewData,
} from '../slice/contentCreationSlice';

export function ContentEditor() {
  const t = useTranslate();
  const [_, setActiveStep] = useActiveStep();
  const { communication, isLoading } = useRetrieveCommunication();
  const firstRender = useRef(true);

  useEffect(() => {
    if (communication) {
      setEditingViewData(communication);
      if (firstRender.current) {
        setActiveStep('communicationEditing');
        firstRender.current = false;
      }
    }
    return () => {
      resetEditingViewData();
    };
  }, [communication, setActiveStep]);

  // this should run only when the component unmounts and not when the communication changes
  useEffect(() => {
    return () => {
      setActiveStep('aimSelection');
      firstRender.current = true;
    };
  }, [setActiveStep]);

  return isLoading ? (
    <Flex
      direction="column"
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      gap="sm-2"
    >
      <Typography variant="body/base/regular">{t('loading')}</Typography>
      <Spinner size={20} state="indeterminate" />
    </Flex>
  ) : communication ? (
    <ContentCreator />
  ) : (
    <Flex
      direction="column"
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      gap="sm-2"
    >
      <Typography variant="body/base/regular">
        {t('editing.communicationNotFound')}
      </Typography>
    </Flex>
  );
}
