import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

export type MenuPosition = { x: number; y: number };

export const Menu = styled('div')<{ position?: MenuPosition }>(
  ({ position }) => ({
    position: 'absolute',
    zIndex: 1,
    top: position?.y || '-10000px',
    left: position?.x || '-10000px',
    opacity: position ? 1 : 0,
    transition: 'opacity 0.75s',
  }),
);

export function MenuPortal({ children }: { children?: ReactNode }) {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null;
}
