import { theme } from '@reaidy/components';
import { Divider } from '@xtreamsrl/react-ui-kit/Divider';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { ReactNode } from 'react';

export function EditorHeader({ children }: { children?: ReactNode }) {
  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        paddingY="sm-3"
        paddingX="md-5"
        backgroundColor={theme.palette.background.default}
        height="3rem"
      >
        {children}
      </Flex>
      <Divider />
    </>
  );
}
