import { Card } from '@xtreamsrl/react-ui-kit/Card';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';
import StyledCard from '../../../_shared/components/StyledCard';
import { Template } from '../../types';

export interface TemplateCardProps {
  template: Template;
  onClick: () => void;
}
const TemplateCard: React.FC<TemplateCardProps> = ({ template, onClick }) => (
  <StyledCard
    key={template.id}
    testId={`template-${template.id}`}
    height="50vh"
  >
    <Card.Content>
      <Box height="40vh" onClick={onClick}>
        <Flex
          direction="column"
          gap="sm-8"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <img src={template.imageUrl} alt={template.name + ' image'} />
          <Typography variant="body/sm/bold">{template.name}</Typography>
        </Flex>
      </Box>
    </Card.Content>
  </StyledCard>
);

TemplateCard.displayName = 'TemplateCard';
export default TemplateCard;
