import { SVGProps } from 'react';

export const DemSvg = (_props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="communicationType-svg"
      width="91"
      height="91"
      viewBox="0 0 91 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.25"
        y="1.5"
        width="88"
        height="88"
        rx="44"
        stroke="#5F4DDB"
        strokeWidth="2"
      />
      <path
        d="M61 32.375H29.5C29.1519 32.375 28.8181 32.5133 28.5719 32.7594C28.3258 33.0056 28.1875 33.3394 28.1875 33.6875V56C28.1875 56.6962 28.4641 57.3639 28.9563 57.8562C29.4486 58.3484 30.1163 58.625 30.8125 58.625H59.6875C60.3837 58.625 61.0514 58.3484 61.5437 57.8562C62.0359 57.3639 62.3125 56.6962 62.3125 56V33.6875C62.3125 33.3394 62.1742 33.0056 61.9281 32.7594C61.6819 32.5133 61.3481 32.375 61 32.375ZM40.4446 45.5L30.8125 54.3282V36.6718L40.4446 45.5ZM42.3871 47.2801L44.3559 49.093C44.598 49.3153 44.9147 49.4386 45.2434 49.4386C45.5721 49.4386 45.8889 49.3153 46.131 49.093L48.0998 47.2801L57.6154 56H32.8748L42.3871 47.2801ZM50.0554 45.5L59.6875 36.6702V54.3298L50.0554 45.5Z"
        fill="#43DB37"
      />
    </svg>
  );
};
