import { theme } from '@reaidy/components';
import { FormSelectBasic } from '@xtreamsrl/react-form-input-kit/FormSelectBasic';
import { FormTextInputBasic } from '@xtreamsrl/react-form-input-kit/FormTextInputBasic';
import { useFormContext } from '@xtreamsrl/react-forms';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { TextInput } from '@xtreamsrl/react-ui-kit/TextInput';
import { useContext } from 'react';
import { useFormContext as useReactHookFormContext } from 'react-hook-form';
import { CommunicationType } from '../../contentCreation/types';
import { ComponentType, SectionFieldComponent } from '../types';
import { CommunicationTypeContext } from '../views/TemplateCreator.tsx';

const emptyField: SectionFieldComponent = {
  type: ComponentType.TEXT,
  maxLength: 200,
};

const options = {
  //  [CommunicationType.SMS]: () => [ComponentType.TEXT],
  [CommunicationType.DEM]: (firstField: boolean) =>
    firstField
      ? [
          ComponentType.SECTION_TITLE,
          ComponentType.TEXT,
          ComponentType.CTA,
          ComponentType.BULLET_POINT,
          ComponentType.TITLED_BULLET_POINT,
        ]
      : [
          ComponentType.TEXT,
          ComponentType.CTA,
          ComponentType.BULLET_POINT,
          ComponentType.TITLED_BULLET_POINT,
        ],
  [CommunicationType.PAPER_MAIL]: (firstField: boolean) =>
    firstField
      ? [
          ComponentType.SECTION_TITLE,
          ComponentType.TEXT,
          ComponentType.BULLET_POINT,
          ComponentType.TITLED_BULLET_POINT,
        ]
      : [
          ComponentType.TEXT,
          ComponentType.BULLET_POINT,
          ComponentType.TITLED_BULLET_POINT,
        ],
  [CommunicationType.RCS]: (firstField: boolean) =>
    firstField
      ? [
          ComponentType.SECTION_TITLE,
          ComponentType.TEXT,
          ComponentType.TITLED_BULLET_POINT,
        ]
      : [
          ComponentType.TEXT,
          ComponentType.TITLED_BULLET_POINT,
          ComponentType.CTA,
        ],
  // eslint-disable-next-line
} satisfies Record<CommunicationType, (...args: any[]) => ComponentType[]>;

export const TemplateSectionField = ({
  sectionIndex,
  index,
  isFirstAndOnly,
  insert,
  remove,
}: {
  sectionIndex: number;
  index: number;
  isFirstAndOnly: boolean;
  insert: (index: number, value: SectionFieldComponent) => void;
  remove: (index: number) => void;
}) => {
  const t = useTranslate();
  const firstField = index === 0;
  const communicationType = useContext(CommunicationTypeContext);
  const { watch } = useFormContext();
  const { setValue } = useReactHookFormContext();
  const isBulletPointWithTitle =
    watch(`sections.${sectionIndex}.components.${index}.type`) ===
    ComponentType.TITLED_BULLET_POINT;

  return (
    <Flex
      direction="column"
      gap={isBulletPointWithTitle ? 'sm-2' : 0}
      padding={isBulletPointWithTitle ? 'sm-2' : 0}
      pl="sm-2"
      pr="sm-2"
      border={
        isBulletPointWithTitle
          ? `1px solid ${theme.palette.grey[5]}`
          : undefined
      }
      // @ts-expect-error style is not accepted by Flex component
      style={{ borderRadius: '10px' }}
    >
      <Flex gap="sm-4">
        <Box flex={10}>
          <FormSelectBasic
            name={`sections.${sectionIndex}.components.${index}.type`}
            onChange={(e) => {
              const value = e.target.value as ComponentType;
              // if we are selecting titled bullet point coming from a field that has only one length to specify
              if (value === ComponentType.TITLED_BULLET_POINT) {
                setValue(
                  `sections.${sectionIndex}.components.${index}.maxTitleLength`,
                  30,
                );
                setValue(
                  `sections.${sectionIndex}.components.${index}.maxContentLength`,
                  200,
                );

                setValue(
                  `sections.${sectionIndex}.components.${index}.maxLength`,
                  undefined,
                );
              }
              // if we are selecting a field that has only one length to specify and the currently selected is a titled bullet point
              else if (isBulletPointWithTitle) {
                setValue(
                  `sections.${sectionIndex}.components.${index}.maxLength`,
                  200,
                );

                setValue(
                  `sections.${sectionIndex}.components.${index}.maxTitleLength`,
                  undefined,
                );
                setValue(
                  `sections.${sectionIndex}.components.${index}.maxContentLength`,
                  undefined,
                );
              }
            }}
          >
            {options[communicationType](firstField).map((option) => (
              <FormSelectBasic.Option key={option} value={option}>
                {t(`template.creation.${option}`)}
              </FormSelectBasic.Option>
            ))}
          </FormSelectBasic>
        </Box>
        {!isBulletPointWithTitle ? (
          <Box flex={1} flexBasis="min-content">
            <FormTextInputBasic
              type="number"
              name={`sections.${sectionIndex}.components.${index}.maxLength`}
              endInputAddOn={
                <TextInput.AddOn>
                  {t('template.creation.chars')}
                </TextInput.AddOn>
              }
            />
          </Box>
        ) : null}
        <Flex flex={1}>
          <Button
            variant="plain"
            onlyIcon={true}
            onClick={() => insert(index + 1, emptyField)}
            icon={<Icon name="Plus" />}
            color="green"
          />
          <Button
            variant="plain"
            onlyIcon={true}
            disabled={firstField && isFirstAndOnly}
            onClick={() => remove(index)}
            icon={<Icon name="Minus" />}
            color="red"
          />
        </Flex>
      </Flex>
      {isBulletPointWithTitle ? (
        <Flex gap="sm-4">
          <FormTextInputBasic
            type="number"
            name={`sections.${sectionIndex}.components.${index}.maxTitleLength`}
            startInputAddOn={
              <TextInput.AddOn>
                {t('template.creation.bulletMaxTitleLength') + ':'}
              </TextInput.AddOn>
            }
            endInputAddOn={
              <TextInput.AddOn>{t('template.creation.chars')}</TextInput.AddOn>
            }
          />
          <FormTextInputBasic
            type="number"
            name={`sections.${sectionIndex}.components.${index}.maxContentLength`}
            startInputAddOn={
              <TextInput.AddOn>
                {t('template.creation.bulletMaxContentLength') + ':'}
              </TextInput.AddOn>
            }
            endInputAddOn={
              <TextInput.AddOn>{t('template.creation.chars')}</TextInput.AddOn>
            }
          />
        </Flex>
      ) : null}
    </Flex>
  );
};
