// This will be used to generate the cards to allow the user to select the type of communication they want to create
import {
  ContactCenterSvg,
  DemSvg,
  LandingPageSvg,
  PushNotificationSvg,
} from '@reaidy/icons';
import { Features } from '@xtreamsrl/react-feature-flags';
import { ElementType, ReactNode } from 'react';
import { Descendant } from 'slate';

type FlowDependencies = {
  communicationType?: CommunicationType;
};

export type AvailableStep = {
  id: StepKey;
  label: string;
  element: ReactNode;
  isInFlow: boolean | ((data?: FlowDependencies) => boolean);
  findNextStep?: (data: FlowDependencies) => StepKey;
  findPreviousStep?: (data: FlowDependencies) => StepKey;
};

export enum CommunicationType {
  DEM = 'DEM',
  PAPER_MAIL = 'PAPER_MAIL',
  RCS = 'RCS',
}

export const communicationsTypes: readonly {
  code: CommunicationType;
  icon: ElementType;
  text: string;
  isImplemented: boolean;
  feature: keyof Features;
}[] = [
  {
    code: CommunicationType.DEM,
    icon: DemSvg,
    text: 'shared.communicationTypes.DEM',
    isImplemented: true,
    feature: 'ShowCommunicationTypeDem',
  },
  /*{
    code: CommunicationType.SMS,
    icon: ContactCenterSvg,
    text: 'communicationTypes.SMS',
  },*/
  {
    code: CommunicationType.PAPER_MAIL,
    icon: DemSvg,
    text: 'shared.communicationTypes.PAPER_MAIL',
    isImplemented: true,
    feature: 'ShowCommunicationTypePaperMail',
  },
  {
    code: CommunicationType.RCS,
    icon: DemSvg,
    text: 'shared.communicationTypes.RCS',
    isImplemented: true,
    feature: 'ShowCommunicationTypeRcs',
  },
  {
    code: CommunicationType.PAPER_MAIL,
    icon: LandingPageSvg,
    text: 'shared.communicationTypes.LANDING_PAGE',
    isImplemented: false,
    feature: 'ShowCommunicationTypeLandingPage',
  },
  {
    code: CommunicationType.PAPER_MAIL,
    icon: PushNotificationSvg,
    text: 'shared.communicationTypes.PUSH_NOTIFICATION',
    isImplemented: false,
    feature: 'ShowCommunicationTypePushNotification',
  },
  {
    code: CommunicationType.PAPER_MAIL,
    icon: ContactCenterSvg,
    text: 'shared.communicationTypes.SCRIPT',
    isImplemented: false,
    feature: 'ShowCommunicationTypeScript',
  },
] as const;

export type BriefDEMFormFields = {
  name: string;
  description: string;
  plusOrIncentives: string;
  personaId: string;
};

export type StepKey =
  | 'communicationTypeSelection'
  | 'aimSelection'
  | 'templateSelection'
  | 'briefForm'
  | 'proposalSelection'
  | 'personasSelection'
  | 'PersonalisedCommunicationsEditing'
  | 'communicationEditing';

export type EditorSlice = {
  value?: Descendant[];
  setValue: (value: Descendant[]) => void;
};

export type PersonalisedCommunication = {
  id: string;
  personaId: string;
  name: string;
  draft: string;
};

export type PersonaDraft = {
  id: string;
  status: string;
  name: string;
  personaId: string;
  personaName: string;
  draft?: string;
  _optimistic?: boolean;
};

export type Communication = {
  id: string;
  name: string;
  details: CommunicationDetails;
  proposal1: string;
  proposal2: string;
  proposal3: string;
  workspaceId: string;
  status: string;
  selectedProposal: ProposalSelection | null;
  draft: string | null;
  createdAt: string;
  personalisedCommunications: PersonalisedCommunication[];
  personaDrafts: PersonaDraft[];
  _optimistic?: boolean;
};

export type BECommunication = {
  id: string;
  name: string;
  details: CommunicationDetails;
  proposal1: string;
  proposal2: string;
  proposal3: string;
  workspaceId: string;
  status: string;
  selectedProposal: ProposalSelection | null;
  draft: string | null;
  createdAt: string;
  personaDrafts: PersonalisedCommunication[];
};

export interface CommunicationDetails {
  type: CommunicationType;
  description: string;
  plusOrIncentives: string;
  templateId: string;
}

export type BriefData = BriefDEMFormFields;

export enum ProposalSelection {
  PROPOSAL_1 = 'proposal1',
  PROPOSAL_2 = 'proposal2',
  PROPOSAL_3 = 'proposal3',
}

// Personas -> TODO: update if necessary
export type Persona = {
  id: string;
  name: string;
  description: string;
  default?: boolean;
};

export type CommunicationBeingSaved = {
  id: string;
  name: string;
  content: Descendant[];
};
