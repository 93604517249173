import { useQuery } from '@tanstack/react-query';
import { startTransition, useCallback, useState } from 'react';
import { slateToRemark } from 'remark-slate-transformer';
import { Editor, Node, Point, Range, Transforms, createEditor } from 'slate';
import { useSlate } from 'slate-react';
import { processor, slateToMarkdownProcessor } from 'src/_shared/utils/slate';
import {
  ContentCreationEvents,
  useTracker,
} from 'src/contentCreation/analytics';
import { useStepper } from 'src/contentCreation/hooks/useStepper';
import {
  TextModTypes,
  useGenerateTextModMutation,
} from 'src/contentCreation/mutations/useGenerateTextModMutation';
import { getCommPersonaDraftQuery } from 'src/contentCreation/queries/useCommPersonaDraftQuery';

export function useMenuActions({
  draftId,
  commId,
  closeMenu,
}: {
  draftId: string;
  commId: string;
  closeMenu: () => void;
}) {
  const { activeStepKey } = useStepper();
  const { track } = useTracker();

  // Editor instance, it looks to be stable
  const editor = useSlate();

  // Actual content of the text modification
  const [generationContent, setGenerationContent] = useState('');
  const [generationError, setGenerationError] = useState<unknown>(null);

  // Status flags for the text modification generation
  const [generationStarted, setGenerationStarted] = useState(false);
  const [generationInProgress, setGenerationInProgress] = useState(false);
  const [generationComplete, setGenerationComplete] = useState(false);
  const [generationEnded, setGenerationEnded] = useState(false);

  // Call this to reset the state for the next generation
  const resetGenerationState = useCallback(() => {
    setGenerationStarted(false);
    setGenerationInProgress(false);
    setGenerationError(null);
    setGenerationComplete(false);
    setGenerationEnded(false);
  }, []);

  const trackEvent = useCallback(
    (event: ContentCreationEvents) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      track(event, {
        communicationId: commId,
        activeStep: activeStepKey,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    },
    [track, commId, activeStepKey],
  );

  // Simply copies the selection to the clipboard
  const handleCopy = useCallback(() => {
    trackEvent(ContentCreationEvents.CopySelection);
    navigator.clipboard
      .writeText(Editor.string(editor, editor.selection!))
      .catch(() => {
        console.error('Failed to copy selection to clipboard');
      });
  }, [editor, trackEvent]);

  // Pastes the clipboard content to the selection
  const handlePaste = useCallback(() => {
    trackEvent(ContentCreationEvents.PasteSelection);
    navigator.clipboard
      .readText()
      .then((text) => {
        editor.insertText(text, { at: editor.selection! });
      })
      .catch(() => {
        console.error('Failed to paste selection to clipboard');
      });
  }, [editor, trackEvent]);

  // Cuts the selection to the clipboard
  const handleCut = useCallback(() => {
    trackEvent(ContentCreationEvents.CutSelection);
    navigator.clipboard
      .writeText(Editor.string(editor, editor.selection!))
      .then(() => editor.deleteFragment())
      .catch(() => {
        console.error('Failed to copy selection to clipboard');
      });
  }, [editor, trackEvent]);

  // Deletes the text and closes the menu
  const handleDelete = useCallback(() => {
    trackEvent(ContentCreationEvents.DeleteSelection);
    editor.deleteFragment();
    closeMenu();
  }, [editor, closeMenu, trackEvent]);

  // Asks the backend to generate a text modification,
  // and updates the content as the backend sends chunks
  const { mutate: generateTextMod } = useGenerateTextModMutation({
    onStreamStart() {
      setGenerationStarted(true);
      setGenerationInProgress(true);
      setGenerationError(null);
      setGenerationComplete(false);
      setGenerationContent('');
    },
    onStreamChunk(chunk) {
      startTransition(() => {
        setGenerationContent((prev) => prev + chunk.delta);
      });
    },
    onStreamComplete() {
      setGenerationComplete(true);
    },
    onStreamError(error) {
      setGenerationError(error);
    },
    onStreamEnd() {
      setGenerationInProgress(false);
      setGenerationEnded(true);
    },
  });

  // We need the full draft to generate the text modification
  const { data: draft } = useQuery({
    ...getCommPersonaDraftQuery({ draftId, commId }),
    select: (data) => data?.draft,
  });

  // Generates the text modification
  const handleGeneration = useCallback(
    (modType: TextModTypes, params: { directions?: string } = {}) => {
      if (!draft) {
        console.error('Draft not found');
        return;
      }
      generateTextMod({
        draftId,
        modType,
        fullText: draft,
        textToMod: addMarkersToSelection(editor, editor.selection!),
        ...params,
      });
    },
    [draft, draftId, editor, generateTextMod],
  );

  // Modifies the prompt with custom directions
  const handleModify = useCallback(
    (directions: string) => {
      handleGeneration('CUSTOM_PROMPT_MODIFICATION', {
        directions,
      });
    },
    [handleGeneration],
  );

  // Improves the text
  const handleEnhance = useCallback(
    () => handleGeneration('ENHANCE'),
    [handleGeneration],
  );

  // Shortens the text
  const handleShorten = useCallback(
    () => handleGeneration('SUMMARIZE'),
    [handleGeneration],
  );

  // Expands the text
  const handleExpand = useCallback(
    () => handleGeneration('EXPAND'),
    [handleGeneration],
  );

  // Rephrases the text
  const handleRephrase = useCallback(
    () => handleGeneration('REPHRASE'),
    [handleGeneration],
  );

  // Inserts the generated content into the editor at current selection
  const handleAcceptMod = useCallback(() => {
    console.log('Accepting modification');
    trackEvent(ContentCreationEvents.AcceptAIChanges);
    processor
      .process(generationContent)
      .then((node) => {
        editor.insertFragment(node.result, { at: editor.selection! });
        editor.setSelection({}); // unselect
        resetGenerationState();
        closeMenu();
      })
      .catch((e) => {
        console.error(e);
        alert('Failed to accept modification');
      });
  }, [trackEvent, generationContent, editor, resetGenerationState, closeMenu]);

  // Rejects the modification and resets the state
  const handleRejectMod = useCallback(() => {
    trackEvent(ContentCreationEvents.RejectAIChanges);
    resetGenerationState();
    closeMenu();
  }, [resetGenerationState, closeMenu, trackEvent]);

  return {
    generationContent,
    generationStarted,
    generationInProgress,
    generationError,
    generationComplete,
    generationEnded,
    handleModify,
    handleCopy,
    handlePaste,
    handleCut,
    handleEnhance,
    handleShorten,
    handleExpand,
    handleRephrase,
    handleDelete,
    handleAcceptMod,
    handleRejectMod,
  };
}

function addMarkersToSelection(editor: Editor, selection: Range) {
  const start: Point = Range.start(selection);
  const end: Point = Range.end(selection);
  const newEditor = createEditor();
  newEditor.select(selection);

  Transforms.insertNodes(newEditor, editor.children, { at: [0] });
  // Optionally, inspect and remove the last node if it's an unwanted newline
  const lastNodePath = [newEditor.children.length - 1];
  const lastNode = Editor.node(newEditor, lastNodePath);
  if (lastNode && Node.string(lastNode[0]).trim() === '') {
    Transforms.removeNodes(newEditor, { at: lastNodePath });
  }

  // the end should be done first to avoid changing the start point
  newEditor.insertText('&&&', { at: end });
  newEditor.insertText('&&&', { at: start });

  return slateToMarkdownProcessor.stringify(slateToRemark(newEditor.children));
}
