import React, { PropsWithChildren } from 'react';
import { ConfirmationModal } from '../ConfirmationModal';
import { FeedbackModal } from '../FeedbackModal';

export type ModalState = 'CONFIRMATION' | 'FEEDBACK' | undefined;

type Props = {
  state: 'CONFIRMATION' | 'FEEDBACK' | undefined;
  confirmationHeaderKey: string;
  feedbackText: {
    headerKey: string;
    contentKey: string;
  };
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmationWithFeedbackModal: React.FC<PropsWithChildren<Props>> = ({
  state,
  onConfirm,
  onCancel,
  confirmationHeaderKey,
  feedbackText,
  children,
}) => {
  return (
    <>
      <ConfirmationModal
        visible={state === 'CONFIRMATION'}
        primaryAction={onConfirm}
        secondaryAction={onCancel}
        headerKey={confirmationHeaderKey}
      >
        {children}
      </ConfirmationModal>
      <FeedbackModal
        visible={state === 'FEEDBACK'}
        headerKey={feedbackText.headerKey}
        contentKey={feedbackText.contentKey}
        primaryAction={onCancel}
      />
    </>
  );
};

ConfirmationWithFeedbackModal.displayName = 'ConfirmationWithFeedbackModal';
export default ConfirmationWithFeedbackModal;
