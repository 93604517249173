import { useTranslate } from '@xtreamsrl/react-i18n';
import { FormEventHandler, useCallback, useMemo, useState } from 'react';
import type { TranslationKeys } from 'src/_shared/assets/i18n';
import { useReworkPersonaDraftMutation } from '../mutations/useReworkPersonaDraftMutation';
import { usePersonaListQuery } from '../queries/usePersonaListQuery';
import { Persona, PersonaDraft } from '../types';
import {
  CommEditingModal,
  Emphasis,
  PersonaSelector,
  PromptInput,
  SubmitButton,
} from './CommEditingModal';

export function CommEditingVariantModal({
  isOpen,
  onClose,
  onNewDraftPending,
  onNewDraftCancelled,
  draft,
  commId,
}: {
  isOpen: boolean;
  onNewDraftPending: (pendingDraft: PersonaDraft) => void;
  onNewDraftCancelled: (cancelledDraft: PersonaDraft) => void;
  onClose: () => void;
  draft: PersonaDraft;
  commId: string;
}) {
  const t = useTranslate();
  const subtitle = (
    <span>
      {t('commEditing.variantModal.subtitle' satisfies TranslationKeys)}:
      <Emphasis>"{draft.name}"</Emphasis>
    </span>
  );
  const { data: personas = [] } = usePersonaListQuery();

  return (
    <CommEditingModal
      iconName="Sparkles"
      isOpen={isOpen}
      onClose={onClose}
      title={t('commEditing.variantModal.title' satisfies TranslationKeys)}
      subtitle={subtitle}
      paragraph={t(
        'commEditing.variantModal.paragraph' satisfies TranslationKeys,
      )}
    >
      {personas.length > 0 ? (
        <ModalForm
          commId={commId}
          draftId={draft.id}
          personaId={draft.personaId}
          personas={personas}
          onNewDraftPending={onNewDraftPending}
          onNewDraftCancelled={onNewDraftCancelled}
        />
      ) : (
        <p>Loading...</p>
      )}
    </CommEditingModal>
  );
}

function ModalForm({
  commId,
  draftId,
  personaId,
  personas,
  onNewDraftPending,
  onNewDraftCancelled,
}: {
  commId: string;
  draftId: string;
  personaId: string;
  personas: Persona[];
  onNewDraftPending: (pendingDraft: PersonaDraft) => void;
  onNewDraftCancelled: (cancelledDraft: PersonaDraft) => void;
}) {
  const t = useTranslate();
  const [prompt, setPrompt] = useState('');

  const initialPersona = useMemo(
    () => personas.find((persona) => persona.id === personaId),
    [personaId, personas],
  );
  const [selectedPersona, setSelectedPersona] = useState(initialPersona);
  const submitDisabled = !selectedPersona;

  const { mutate: reworkPersonaDraft } = useReworkPersonaDraftMutation({
    onNewDraftPending,
    onNewDraftCancelled,
  });

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();
      if (!selectedPersona) return;
      reworkPersonaDraft({
        commId,
        draftId,
        newPersonaId: selectedPersona.id,
        directions: prompt,
      });
    },
    [selectedPersona, prompt, reworkPersonaDraft, commId, draftId],
  );

  return (
    <form onSubmit={handleSubmit}>
      <PersonaSelector
        label={t(
          'commEditing.variantModal.form.personaSelector.label' satisfies TranslationKeys,
        )}
        selectedPersona={selectedPersona}
        setSelectedPersona={setSelectedPersona}
      />
      <PromptInput
        label={t(
          'commEditing.variantModal.form.promptInput.label' satisfies TranslationKeys,
        )}
        prompt={prompt}
        setPrompt={setPrompt}
      />
      <SubmitButton isDisabled={submitDisabled}>
        {t(
          'commEditing.variantModal.form.submitButton.label' satisfies TranslationKeys,
        )}
      </SubmitButton>
    </form>
  );
}
