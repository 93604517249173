import { ThemeProvider } from '@reaidy/components';
import { pages } from '@reaidy/pages';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AnalyticsProvider } from '@xtreamsrl/react-analytics';
import { FeatureFlagProvider } from '@xtreamsrl/react-feature-flags';
import { GrowthbookProvider } from '@xtreamsrl/react-feature-flags-growthbook';
import { I18nProvider } from '@xtreamsrl/react-i18n';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Spinner } from '@xtreamsrl/react-ui-kit/Spinner';
import { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { Layout } from './Layout';
import { pageViewMap } from './_shared/analytics/pageViews.ts';
import { growthbook, queryClientConfig } from './_shared/config';
import { featuresMap } from './_shared/featureFlags/featuresMap.ts';
import { useUserInfoSlice } from './_shared/slice/userInfoSlice';
import { Login } from './login/view/Login';

const queryClient = new QueryClient(queryClientConfig);

function App() {
  // TODO: get locale from redux store
  const [localeFromSlice, setLocaleFromSlice] = useState('it');
  const { userInfo, isUserLoading } = useUserInfoSlice((state) => ({
    userInfo: state.userInfo,
    isUserLoading: state.isUserLoading,
  }));

  return (
    <GrowthbookProvider manager={growthbook}>
      <FeatureFlagProvider featuresMap={featuresMap}>
        <AnalyticsProvider value={{ pageViewMap }}>
          <QueryClientProvider client={queryClient}>
            <I18nProvider
              locale={localeFromSlice}
              changeLocale={setLocaleFromSlice}
            >
              <ThemeProvider>
                <BrowserRouter>
                  {isUserLoading ? (
                    <Flex
                      direction="column"
                      height="100vh"
                      width="100vw"
                      alignItems="center"
                      justifyContent="center"
                      gap="sm-2"
                    >
                      <Spinner size={32} state="indeterminate" />
                    </Flex>
                  ) : (
                    <Routes>
                      {!userInfo ? (
                        <>
                          <Route path={pages.login} element={<Login />} />
                          <Route
                            path="*"
                            element={
                              <Navigate to={pages.login} replace={true} />
                            }
                          />
                        </>
                      ) : (
                        <Route path="*" element={<Layout />} />
                      )}
                    </Routes>
                  )}
                </BrowserRouter>
              </ThemeProvider>
            </I18nProvider>
          </QueryClientProvider>
        </AnalyticsProvider>
      </FeatureFlagProvider>
    </GrowthbookProvider>
  );
}

export default App;
