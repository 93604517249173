import { useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { useContext } from 'react';
import { useFieldArray } from 'react-hook-form';
import { emptyTemplateSection } from '../utils';
import { GenericTemplateComponent } from '../types';
import { CommunicationTypeContext } from '../views/TemplateCreator.tsx';
import { TemplateSection } from './TemplateSection';

export function TemplateSections() {
  const t = useTranslate();
  const communicationType = useContext(CommunicationTypeContext);
  const {
    fields: sections,
    remove: removeSection,
    insert: insertSection,
  } = useFieldArray<GenericTemplateComponent, 'sections'>({
    name: 'sections',
  });

  return (
    <>
      <Box alignSelf="center">
        <Button
          variant="tinted"
          onClick={() =>
            insertSection(0, emptyTemplateSection[communicationType])
          }
        >
          {t('template.creation.addSection')}
        </Button>
      </Box>
      {sections.map((field, indexSection) => (
        <TemplateSection
          key={field.id}
          index={indexSection}
          remove={removeSection}
          insert={insertSection}
        />
      ))}
    </>
  );
}
