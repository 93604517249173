import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { Range } from 'slate';
import { useSlate } from 'slate-react';
import { MenuPosition } from './Menu';

export function useMenuPosition({
  containerRef,
}: {
  /** The id of the container element */
  containerRef?: MutableRefObject<HTMLElement | null>;
}) {
  const [menuPosition, setMenuPosition] = useState<MenuPosition>();

  // This object appears to be consistent
  const editor = useSlate();

  const resetPosition = useCallback(() => {
    setMenuPosition(undefined);
  }, []);

  useEffect(() => {
    let isSelecting = false;

    // Wait for the user to select something
    const handleSelection = () => {
      const selection = window.getSelection();
      if (!selection) return;

      // When user clicks away, hide the menu
      if (
        selection.isCollapsed &&
        selection.anchorNode instanceof HTMLElement &&
        containerRef?.current &&
        !containerRef.current.contains(selection.anchorNode)
      ) {
        isSelecting = false;
        resetPosition();
        return;
      }

      // Check if selection happened inside the editor
      if (
        selection?.isCollapsed === true ||
        editor.selection == null ||
        Range.isCollapsed(editor.selection)
      ) {
        isSelecting = false;
      } else {
        // Give the OK to show the menu
        isSelecting = true;
      }
    };

    // Show menu only after the user has finished selecting
    const handleMouseUp = () => {
      // Check if we have the OK to show the menu
      if (isSelecting) {
        const selection = window.getSelection();

        // Check if the selection is not collapsed
        if (selection?.isCollapsed === false) {
          // Get bounding client rect of the selection
          const range = selection.getRangeAt(0);
          const rect = range.getBoundingClientRect();

          // Calculate the position of the menu
          const space = 10;
          const x = rect.left + window.scrollX;
          const y = rect.top + window.scrollY + rect.height + space;
          setMenuPosition({ x, y });
        }
        isSelecting = false;
      }
    };

    document.addEventListener('selectionchange', handleSelection);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('selectionchange', handleSelection);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [containerRef, editor, resetPosition]);

  return {
    menuPosition,
    resetPosition,
  };
}
