import { useDateTimeFormat, useTranslate } from '@xtreamsrl/react-i18n';
import { TextInput } from '@xtreamsrl/react-ui-kit/TextInput';
import { Accordion } from '@xtreamsrl/react-ui-kit/Accordion';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { DateTimeFormat } from '../../_shared/assets/i18n';
import { ConfirmationWithFeedbackModal } from '../../_shared/components/ConfirmationWithFeedbackModal';
import StyledAccordion from '../../_shared/components/StyledAccordion.tsx';
import { useCommunicationItemActions } from '../hooks/useCommunicationItemActions.ts';
import { CommunicationElement } from '../types';

export function CommunicationItem({
  communication,
}: {
  communication: CommunicationElement;
}) {
  const dtf = useDateTimeFormat();
  const t = useTranslate();

  const {
    op,
    nameAfterDuplication,
    setNameAfterDuplication,
    modalState,
    failureCode,
    view,
    edit,
    dupFromBrief,
    openConfirmation,
    onConfirm,
    onCancel,
  } = useCommunicationItemActions(communication);

  const operationsKey: {
    DUP_FROM_DRAFT: 'duplicateFromDraft';
    DUP_FROM_PERSONAS: 'duplicateFromPersonas';
    DELETE: 'communicationDeletion';
  } = {
    DUP_FROM_DRAFT: 'duplicateFromDraft',
    DUP_FROM_PERSONAS: 'duplicateFromPersonas',
    DELETE: 'communicationDeletion',
  };

  const feedbackText = {
    headerKey: `archive.${operationsKey[op]}.failureHeader`,
    contentKey: `archive.${operationsKey[op]}.failure.${failureCode}`,
  };

  return (
    <>
      <ConfirmationWithFeedbackModal
        onConfirm={onConfirm[op]}
        confirmationHeaderKey={`archive.${operationsKey[op]}.header`}
        feedbackText={feedbackText}
        state={modalState}
        onCancel={onCancel[op]}
      >
        {op !== 'DELETE' ? (
          <>
            <Typography variant="body/sm/regular">
              {t(`archive.${operationsKey[op]}.content`)}
            </Typography>
            <TextInput
              label=""
              name="name"
              value={nameAfterDuplication}
              onChange={(event) => setNameAfterDuplication(event.target.value)}
            />
          </>
        ) : (
          <Typography variant="body/sm/regular">
            {t('archive.communicationDeletion.checkMessage', {
              communicationName: communication.name,
            })}
          </Typography>
        )}
      </ConfirmationWithFeedbackModal>
      <StyledAccordion variant="block" testId={`item-${communication.id}`}>
        <Accordion.Summary expandIcon={undefined}>
          <Flex width="100%" gap="md-4" alignItems="center">
            <Box flex="2">
              <Typography variant="body/sm/regular">
                {dtf(communication.createdAt, DateTimeFormat.FULL_DATE)}
              </Typography>
            </Box>
            <Box flex="8">
              <Typography variant="body/sm/semibold">
                {communication.name}
              </Typography>
            </Box>

            <Box flex="3">
              <Typography textAlign="center" variant="body/sm/regular">
                {t(`shared.communicationTypes.${communication.type}`)}
              </Typography>
            </Box>
            <Flex
              flex="1"
              alignItems="center"
              justifyContent="flex-end"
              gap="sm-4"
            >
              <Box
                style={{ borderRadius: 50 }}
                textAlign="right"
                width="10px"
                height="10px"
                backgroundColor={
                  communication.status === 'PERSONA_DRAFTS_ADDED'
                    ? 'green.8'
                    : communication.status === 'DRAFT_ADDED'
                      ? 'warning.6'
                      : 'red.9'
                }
              />
              <Button
                testId={`item-${communication.id}-delete`}
                variant="plain"
                size="sm"
                color="grey"
                icon={<Icon name="Trash2" />}
                onlyIcon={true}
                onClick={(event) => {
                  event.stopPropagation();
                  openConfirmation('DELETE');
                }}
              />
            </Flex>
          </Flex>
        </Accordion.Summary>
        <Accordion.Details>
          {communication.status === 'GENERATED' ? null : (
            <Button
              testId={`item-${communication.id}-view`}
              size="sm"
              variant="plain"
              onClick={() => {
                view();
              }}
            >
              {t('archive.view')}
            </Button>
          )}
          <Button
            testId={`item-${communication.id}-edit`}
            size="sm"
            variant="plain"
            onClick={() => edit()}
          >
            {t('archive.edit')}
          </Button>
          <Button
            testId={`item-${communication.id}-dupFromDraft`}
            size="sm"
            variant="plain"
            onClick={() => openConfirmation('DUP_FROM_DRAFT')}
          >
            {t('archive.duplicate.buttonText')}
          </Button>
          <Button
            testId={`item-${communication.id}-dupFromBrief`}
            size="sm"
            variant="plain"
            onClick={() => dupFromBrief()}
          >
            {t('archive.duplicateFromBrief')}
          </Button>
          {communication.status === 'PERSONA_DRAFTS_ADDED' ? (
            <Button
              testId={`item-${communication.id}-dupFromPersonas`}
              size="sm"
              variant="plain"
              onClick={() => openConfirmation('DUP_FROM_PERSONAS')}
            >
              {t('archive.duplicateFromPersonas.buttonText')}
            </Button>
          ) : null}
        </Accordion.Details>
      </StyledAccordion>
    </>
  );
}
