import { useQuery } from '@tanstack/react-query';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { useState } from 'react';
import { CommEditingDraftList } from '../components/CommEditingDraftList';
import { CommEditingHeader } from '../components/CommEditingHeader';
import { EditingLayout } from '../components/CommEditingLayout';
import { CommEditingNewDraft } from '../components/CommEditingNewDraft';
import { PersonaDraftEditor } from '../components/DraftEditor/PersonaDraftEditor';
import { PersonaDraftStream } from '../components/DraftEditor/PersonaDraftStream';
import { useCommunication } from '../hooks/useCommunication';
import { getCommDetailsQuery } from '../queries/useCommDetailsQuery';
import { useCommPersonaDraftQuery } from '../queries/useCommPersonaDraftQuery';
import { Communication, PersonaDraft } from '../types';

export function CommEditing() {
  const communication = useCommunication();
  return <CommEditingView communication={communication} />;
}

function CommEditingView({ communication }: { communication: Communication }) {
  const commId = communication.id;
  const { data: personaDrafts = [] } = useQuery({
    ...getCommDetailsQuery(commId),
    select: (data) => data?.personaDrafts,
    initialData: communication,
  });

  const [selectedDraft, setSelectedDraft] = useState<PersonaDraft | undefined>(
    personaDrafts[0],
  );

  return (
    <EditingLayout
      heading={<CommEditingHeader communication={communication} />}
      leftPanel={
        selectedDraft && (
          <LeftPanel commId={commId} draftId={selectedDraft?.id} />
        )
      }
      rightPanel={
        <RightPanel
          commId={commId}
          selectedDraft={selectedDraft}
          setSelectedDraft={setSelectedDraft}
          personaDrafts={personaDrafts}
        />
      }
    />
  );
}

function LeftPanel({ draftId, commId }: { draftId: string; commId: string }) {
  const { data: personaDraft } = useCommPersonaDraftQuery({
    commId,
    draftId,
  });
  const isEditable = personaDraft?.status === 'DRAFT_SAVED';
  return (
    <Box height="100%">
      {isEditable ? (
        <PersonaDraftEditor commId={commId} draftId={draftId} />
      ) : (
        <PersonaDraftStream commId={commId} draftId={draftId} />
      )}
    </Box>
  );
}

function RightPanel({
  commId,
  selectedDraft,
  setSelectedDraft,
  personaDrafts,
}: {
  commId: string;
  selectedDraft?: PersonaDraft;
  setSelectedDraft: (draft: PersonaDraft) => void;
  personaDrafts: PersonaDraft[];
}) {
  return (
    <Flex direction="column" gap="3rem">
      <CommEditingDraftList
        commId={commId}
        selectedDraft={selectedDraft}
        personaDrafts={personaDrafts}
        setSelectedDraft={setSelectedDraft}
      />
      <CommEditingNewDraft onDraftCreated={setSelectedDraft} />
    </Flex>
  );
}
