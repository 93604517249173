import { AnalyticsEvent } from '@xtreamsrl/react-analytics';
import { DashboardEvents } from './events';

export const dashboardEventsMapping = {
  [DashboardEvents.GoToContentCreation]: () => ({
    type: 'goToContentCreationFromDashboard',
  }),
  [DashboardEvents.GoToArchive]: () => ({
    type: 'goToArchive',
  }),
  // eslint-disable-next-line
} satisfies Record<DashboardEvents, (...props: any[]) => AnalyticsEvent>;
