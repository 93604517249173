import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTemplate } from '../apis/updateTemplate.ts';
import { templateDetailsQueryKeys } from '../queries/useTemplateDetailsQuery.ts';
import { TemplateData } from '../types/index.ts';
import { templatesListQueryKeys } from '../queries/useTemplateListQuery.ts';

export function useUpdateTemplateMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateTemplate,
    async onMutate({ templateId }) {
      // Cancel ongoing details query
      await queryClient.cancelQueries({
        queryKey: templateDetailsQueryKeys.byId(templateId),
        exact: true,
      });
    },
    async onSuccess(data, { templateId }) {
      // Update cache
      queryClient.setQueryData<TemplateData>(
        templateDetailsQueryKeys.byId(templateId),
        data,
      );
      // Revalidate list query
      await queryClient.invalidateQueries({
        queryKey: templatesListQueryKeys.all,
      });
    },
  });
}
