import { useTranslate } from '@xtreamsrl/react-i18n';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { FormEventHandler, useCallback, useMemo, useState } from 'react';
import type { TranslationKeys } from 'src/_shared/assets/i18n';
import { CreatePersonaDraftParams } from '../apis/createPersonaDraft';
import { useCommunication } from '../hooks/useCommunication';
import { useCreatePersonaDraftMutation } from '../mutations/useCreatePersonaDraftMutation';
import { usePersonaListQuery } from '../queries/usePersonaListQuery';
import { Persona, PersonaDraft } from '../types';
import {
  CommEditingModal,
  PersonaSelector,
  PromptInput,
  SubmitButton,
} from './CommEditingModal';

export function CommEditingNewDraft({
  onDraftCreated,
}: {
  onDraftCreated: (draft: PersonaDraft) => void;
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpen = useCallback(() => setModalIsOpen(true), []);
  const handleClose = useCallback(() => setModalIsOpen(false), []);

  const handleNewDraft = useCallback(
    (draft: PersonaDraft) => {
      handleClose();
      onDraftCreated(draft);
    },
    [onDraftCreated, handleClose],
  );

  const { data: personas } = usePersonaListQuery();

  const handleStreamError = useCallback((error: unknown) => {
    console.error('Stream error', error);
  }, []);

  const { mutate: createDraft, isPending: isCreating } =
    useCreatePersonaDraftMutation({
      onStreamError: handleStreamError,
    });

  const handleSubmit = useCallback(
    (input: CreatePersonaDraftParams) => {
      createDraft(input, {
        onSuccess: handleNewDraft,
      });
    },
    [createDraft, handleNewDraft],
  );

  return (
    <>
      <Button
        variant="outlined"
        leftIcon={<Icon name="Sparkles" />}
        onClick={handleOpen}
        style={{ width: 'max-content', margin: '0 auto' }}
      >
        Crea nuova versione
      </Button>
      <NewDraftModal isOpen={modalIsOpen} onClose={handleClose}>
        {personas && (
          <NewDraftForm
            onSubmit={handleSubmit}
            personas={personas}
            isLoading={isCreating}
          />
        )}
      </NewDraftModal>
    </>
  );
}

function NewDraftModal({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}) {
  const t = useTranslate();
  return (
    <CommEditingModal
      iconName="Sparkles"
      isOpen={isOpen}
      onClose={onClose}
      title={t('commEditing.newDraftModal.title' satisfies TranslationKeys)}
      subtitle={t(
        'commEditing.newDraftModal.subtitle' satisfies TranslationKeys,
      )}
      paragraph={t(
        'commEditing.newDraftModal.paragraph' satisfies TranslationKeys,
      )}
    >
      {children}
    </CommEditingModal>
  );
}

function NewDraftForm({
  onSubmit,
  personas,
  isLoading,
}: {
  onSubmit: (input: CreatePersonaDraftParams) => void;
  personas: Persona[];
  isLoading: boolean;
}) {
  const initialPersona = useMemo(
    () => personas.find((persona) => persona.default),
    [personas],
  );
  const [persona, setPersona] = useState(initialPersona);
  const [prompt, setPrompt] = useState('');
  const t = useTranslate();
  const { id: commId } = useCommunication();

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();
      if (!persona) return;
      onSubmit({
        personaId: persona.id,
        directions: prompt,
        commId,
      });
    },
    [persona, onSubmit, prompt, commId],
  );

  return (
    <form onSubmit={handleSubmit}>
      <PersonaSelector
        label={t(
          'commEditing.newDraftModal.form.personaSelector.label' satisfies TranslationKeys,
        )}
        selectedPersona={persona}
        setSelectedPersona={setPersona}
      />
      <PromptInput
        label={t(
          'commEditing.newDraftModal.form.promptInput.label' satisfies TranslationKeys,
        )}
        prompt={prompt}
        setPrompt={setPrompt}
      />
      <SubmitButton isLoading={isLoading}>
        {t(
          'commEditing.newDraftModal.form.submitButton.label' satisfies TranslationKeys,
        )}
      </SubmitButton>
    </form>
  );
}
