import { SVGProps } from 'react';

export const ContactCenterSvg = (_props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={'communicationType-svg'}
      width="91"
      height="91"
      viewBox="0 0 91 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.75"
        y="1.5"
        width="88"
        height="88"
        rx="44"
        stroke="#5F4DDB"
        strokeWidth="2"
      />
      <path
        d="M62.8125 45.5004V58.6254C62.8125 60.3659 62.1211 62.0351 60.8904 63.2658C59.6597 64.4965 57.9905 65.1879 56.25 65.1879H47.0625C46.7144 65.1879 46.3806 65.0497 46.1344 64.8035C45.8883 64.5574 45.75 64.2235 45.75 63.8754C45.75 63.5273 45.8883 63.1935 46.1344 62.9474C46.3806 62.7012 46.7144 62.5629 47.0625 62.5629H56.25C57.2943 62.5629 58.2958 62.1481 59.0342 61.4097C59.7727 60.6712 60.1875 59.6697 60.1875 58.6254H56.25C55.2057 58.6254 54.2042 58.2106 53.4658 57.4722C52.7273 56.7337 52.3125 55.7322 52.3125 54.6879V48.1254C52.3125 47.0811 52.7273 46.0796 53.4658 45.3412C54.2042 44.6028 55.2057 44.1879 56.25 44.1879H60.1301C59.8817 41.4595 58.8621 38.858 57.1904 36.6874C55.5187 34.5168 53.2639 32.8667 50.6893 31.9297C48.1147 30.9928 45.3267 30.8078 42.6509 31.3963C39.9751 31.9847 37.522 33.3224 35.5781 35.2531C33.1609 37.6384 31.67 40.8051 31.3716 44.1879H35.25C36.2943 44.1879 37.2958 44.6028 38.0342 45.3412C38.7727 46.0796 39.1875 47.0811 39.1875 48.1254V54.6879C39.1875 55.7322 38.7727 56.7337 38.0342 57.4722C37.2958 58.2106 36.2943 58.6254 35.25 58.6254H32.625C31.5807 58.6254 30.5792 58.2106 29.8408 57.4722C29.1023 56.7337 28.6875 55.7322 28.6875 54.6879V45.5004C28.6912 42.1206 29.6975 38.8178 31.5791 36.0101C33.4607 33.2025 36.133 31.0161 39.2577 29.7279C42.3824 28.4396 45.8191 28.1074 49.1327 28.7732C52.4464 29.439 55.488 31.0729 57.8726 33.4681C59.447 35.0502 60.6937 36.9273 61.5414 38.9921C62.3891 41.0568 62.821 43.2685 62.8125 45.5004Z"
        fill="#43DB37"
      />
    </svg>
  );
};
