import { ReaidyLogoInlineSvg } from '@reaidy/icons';
import { FormTextInput } from '@xtreamsrl/react-form-input-kit/FormTextInput';
import { FormProvider } from '@xtreamsrl/react-forms';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';
import { FeedbackModal } from '../../../_shared/components/FeedbackModal';
import { useLogin } from '../../hooks/useLogin.ts';

const Login: React.FC = () => {
  const t = useTranslate();
  const { form, showModal, setShowModal, login, isPending } = useLogin();

  return (
    <Flex
      height="100vh"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <FeedbackModal
        visible={showModal}
        primaryAction={() => setShowModal(false)}
        headerKey="login.errorHeader"
        contentKey="login.errorContent"
      />
      <Flex width="80%" justifyContent="space-between">
        <Box flex={2} padding="sm-4">
          <ReaidyLogoInlineSvg width="250px" height="70px" />
          <Typography style={{ marginTop: '8px' }} variant="header/sm/regular">
            {t('login.welcome')}
          </Typography>
        </Box>
        <Box flex={0.5} />
        <Flex flex={1.5} direction="column">
          <FormProvider {...form.formProps}>
            <form
              onSubmit={(e) => {
                const handler = form.formProps.handleSubmit((values, event) => {
                  event?.preventDefault();
                  login(values);
                });
                handler(e).catch((error) => {
                  console.error('Failed to submit form:', error);
                });
              }}
            >
              <FormTextInput
                label="Email"
                name="email"
                type="email"
                autoComplete="username"
              />
              <FormTextInput
                label="Password"
                name="password"
                type="password"
                autoComplete="current-password"
              />
              <Button testId="login-button" type="submit" loading={isPending}>
                Login
              </Button>
            </form>
          </FormProvider>
        </Flex>
      </Flex>
    </Flex>
  );
};

Login.displayName = 'Login';
export default Login;
