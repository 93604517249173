import fetch from '../../_shared/fetch';

export function deleteCommunicationAPI({
  communicationId,
}: {
  communicationId: string;
}) {
  return fetch.delete(`/communications/${communicationId}`, {
    extractJson: false,
  });
}
