import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { styled } from '@xtreamsrl/react-ui-kit/styles';
import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useStepper } from '../hooks/useStepper';
import { useUpdateCommMutation } from '../mutations/useUpdateCommMutation';
import { Communication } from '../types';

export const SubHeading = styled('p')(({ theme }) => ({
  ...theme.typography['body/base/regular'],
  color: theme.palette.grey[12],
  margin: 0,
}));

export const Heading = styled('h1')(({ theme }) => ({
  ...theme.typography['body/xxl/semibold'],
  margin: 0,
  padding: '0.25rem',
  textAlign: 'center',
  color: theme.palette.brand[10],
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'transparent',
  boxSizing: 'border-box',
}));

export const Input = styled(Heading.withComponent('input'))(({ theme }) => ({
  appearance: 'none',
  borderRadius: theme.spacing('sm-4'),
  borderColor: theme.palette.brand[8],
  width: '30ch',
  '&:focus': {
    outline: 'none',
  },
}));

export function CommEditingHeader({
  communication,
}: {
  communication: Communication;
}) {
  const commType = communication.details.type;
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const handleEdit = useCallback(() => setMode('edit'), []);
  const handleView = useCallback(() => setMode('view'), []);

  return (
    <Flex width="100%" justifyContent="space-between" alignItems="center">
      <BackButton />
      <Flex
        gap={0}
        direction="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {commType && <SubHeading>{commType}</SubHeading>}
        <EditableHeading
          mode={mode}
          communication={communication}
          onEdit={handleEdit}
          onView={handleView}
        />
      </Flex>
      <EditButton onClick={handleEdit} />
    </Flex>
  );
}

function EditableHeading({
  communication,
  mode,
  onEdit,
  onView,
}: {
  communication: Communication;
  mode: 'view' | 'edit';
  onEdit: () => void;
  onView: () => void;
}) {
  const commId = communication.id;
  const { mutate: updateComm } = useUpdateCommMutation();

  const handleSubmit = useCallback(
    (newName: string) => {
      onView();
      updateComm({
        commId,
        changes: { name: newName },
      });
    },
    [commId, updateComm, onView],
  );

  return (
    <Flex alignItems="center" justifyContent="center" gap="1rem">
      {mode === 'view' && (
        <Heading onClick={onEdit}>{communication.name}</Heading>
      )}
      {mode === 'edit' && (
        <RenameForm communication={communication} onSubmit={handleSubmit} />
      )}
    </Flex>
  );
}

function RenameForm({
  communication,
  onSubmit,
}: {
  communication: Communication;
  onSubmit: (name: string) => void;
}) {
  const [name, setName] = useState(communication.name);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => setName(e.target.value),
    [],
  );

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const trimmed = name.trim();
      if (trimmed) onSubmit(name);
    },
    [name, onSubmit],
  );

  const handleBlur = useCallback(() => {
    buttonRef.current?.click();
  }, []);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Input
        ref={inputRef}
        value={name}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <button ref={buttonRef} hidden />
    </form>
  );
}

const RoundButton = styled(Button)({
  borderRadius: '99999999px',
});

function BackButton() {
  const stepper = useStepper();
  return (
    <RoundButton
      testId="previous-button"
      size="md"
      variant="outlined"
      onlyIcon={true}
      icon={<Icon name="ArrowLeft" />}
      onClick={stepper.previous}
    />
  );
}

function EditButton({
  onClick,
  isDisabled,
}: {
  onClick: () => void;
  isDisabled?: boolean;
}) {
  return (
    <RoundButton
      icon={<Icon name="Pencil" />}
      onlyIcon
      variant="outlined"
      size="md"
      onClick={onClick}
      disabled={isDisabled}
    />
  );
}
