import { useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Step } from '@xtreamsrl/react-ui-kit/Step';
import { Stepper } from '@xtreamsrl/react-ui-kit/Stepper';
import { TopBar } from '../../_shared/components/TopBar.tsx';
import { useStepper } from '../hooks/useStepper';
import { useSteps } from '../hooks/useSteps';
import { AvailableStep, StepKey } from '../types';
import { stepByKey } from '../utils/utils';
import { StepIcon } from '../components/StepIcon';

const componentToRender = (activeStep: StepKey) => {
  const stepEl = stepByKey[activeStep];
  if (stepEl?.element) return stepEl.element;
  else throw new Error('Step not found');
};

export function ContentCreator() {
  const t = useTranslate();
  const { steps } = useSteps();
  const { activeStepNumber, activeStepKey } = useStepper();

  return (
    <Flex direction="column" width="100%" height="100%">
      <TopBar>
        <Stepper activeStep={activeStepNumber}>
          {steps.map((step: AvailableStep) => (
            <Step
              StepIconComponent={StepIcon}
              key={step.id}
              label={t(step.label)}
            />
          ))}
        </Stepper>
      </TopBar>

      <Box overflow="auto" flex="1">
        {componentToRender(activeStepKey)}
      </Box>
    </Flex>
  );
}
