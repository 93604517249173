import {
  configureAnalytics,
  identifyUser,
  setCommonEventProps,
} from '@xtreamsrl/react-analytics';
import { DummyAnalyticsManager } from '@xtreamsrl/react-analytics-dummy';
import { Mixpanel } from '@xtreamsrl/react-analytics-mixpanel';
import { Config, config, configureEnvVars } from '@xtreamsrl/react-config';
import {
  configureFeatureFlags,
  configureManager,
} from '@xtreamsrl/react-feature-flags';
import {
  Growthbook,
  useFeatureFlag,
} from '@xtreamsrl/react-feature-flags-growthbook';
import { configureTranslation } from '@xtreamsrl/react-i18n';
import { initializeApp } from 'firebase/app';
import { getAuth, getIdToken, onAuthStateChanged } from 'firebase/auth';
import { getUserAPI } from '../apis/getUser.ts';
import { translations } from '../assets/i18n';
import { setConfig } from '../fetch';
import { useUserInfoSlice } from '../slice/userInfoSlice.ts';
import { QueryClientConfig } from '@tanstack/react-query';

configureEnvVars((env) => {
  const firebaseConfigRaw: unknown = JSON.parse(env.VITE_FIREBASE_CONFIG);
  const firebaseConfig = firebaseConfigRaw as Config['firebaseConfig'];

  return {
    baseUrl: env.VITE_API_BASE_URL,
    enableMock: env.VITE_ENABLE_MOCK === 'true',
    firebaseConfig,
    mixpanelTrackingToken: env.VITE_MIXPANEL_TRACKING_TOKEN,
    enableMixpanelTracking: env.VITE_ENABLE_MIXPANEL_TRACKING === 'true',
    growthBookKey: env.VITE_GROWTH_BOOK_KEY,
  };
});

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 60 * 5,
    },
  },
};

configureTranslation(translations);

setConfig({ baseUrl: config.baseUrl });
configureAnalytics(
  config.enableMixpanelTracking
    ? new Mixpanel(config.mixpanelTrackingToken)
    : new DummyAnalyticsManager(),
);

export const growthbook = new Growthbook({ clientKey: config.growthBookKey });
configureManager({
  manager: growthbook,
});

configureFeatureFlags({
  useFeatureFlagValue: useFeatureFlag,
});

// firebase config
const app = initializeApp(config.firebaseConfig);
export const auth = getAuth(app);

onAuthStateChanged(auth, (user) => {
  const { setIsUserLoading, setUserInfo } = useUserInfoSlice.getState();

  if (user) {
    setConfig({
      getToken: () => getIdToken(user),
      //TODO: implement isTokenExpired properly
      isTokenExpired: () => Promise.resolve(false),
    });
    getUserAPI()
      .then((data) => {
        identifyUser({
          id: data.id,
          email: data.email,
          providerId: data.providerId,
        });
        setCommonEventProps({ workspaceId: data.workspaceId });
        setUserInfo(data);
      })
      .catch((error) => console.error('Error fetching user info', error))
      .finally(() => setIsUserLoading(false));
  } else {
    setIsUserLoading(false);
  }
});
