import { useParams } from '@xtreamsrl/react-routing';
import { useCommDetailsQuery } from '../queries/useCommDetailsQuery.ts';

export function useRetrieveCommunication() {
  const { communicationId } = useParams<{ communicationId: string }>();
  if (!communicationId) {
    throw new Error('Communication id is required');
  }
  const { data, isLoading } = useCommDetailsQuery(communicationId);
  return { communication: data, isLoading };
}
