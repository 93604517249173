import { pages } from '@reaidy/pages';
import { useDateTimeFormat, useTranslate } from '@xtreamsrl/react-i18n';
import { useBrowserNavigation } from '@xtreamsrl/react-routing';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { DateTimeFormat } from '../../_shared/assets/i18n';
import { resetState } from '../../contentCreation/slice/contentCreationSlice.ts';
import { ArchiveEvents, useTracker } from '../analytics';
import { CommunicationList } from '../components/CommunicationList';

Archive.Container = styled('section')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing('sm-8'),
  gap: theme.spacing('sm-4'),
  overflow: 'auto',
}));

export function Archive() {
  return (
    <Archive.Container>
      <Header />
      <CommunicationList />
    </Archive.Container>
  );
}

function Header() {
  const t = useTranslate();
  const dtf = useDateTimeFormat();
  const { goTo } = useBrowserNavigation();
  const { track } = useTracker();
  const date = new Date();

  return (
    <>
      <Flex alignItems="center" alignSelf="flex-end" gap="sm-4">
        <Icon name="Clock3" color="grey.12" />
        <Typography variant="body/sm/regular">
          {dtf(date, DateTimeFormat.FULL_DATE_TIME)}
        </Typography>
      </Flex>
      <Typography variant="body/xl/bold" color="brand.9">
        {t('archive.header')}
      </Typography>
      <Typography variant="body/lg/regular">{t('archive.content')}</Typography>
      <Flex gap="sm-4" alignSelf="flex-end" marginBottom="sm-4">
        <Button
          leftIcon={<Icon name="Pencil" />}
          onClick={() => {
            resetState();
            track(ArchiveEvents.GoToContentCreation);
            goTo(pages.contentCreation);
          }}
        >
          {t('archive.write')}
        </Button>
      </Flex>
    </>
  );
}
