import { useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { CSSProperties } from 'react';
import { ColumnName, SortDirection } from '../types';

export function ColumnHeader({
  flex,
  columnName,
  updateSortDirection,
  sortDirection,
  justifyContent,
}: {
  flex: CSSProperties['flex'];
  columnName: ColumnName;
  updateSortDirection: (columnName: ColumnName) => void;
  sortDirection: SortDirection;
  justifyContent?: CSSProperties['justifyContent'];
}) {
  const t = useTranslate();

  return (
    <Box
      style={{ cursor: 'pointer' }}
      flex={flex}
      onClick={() => updateSortDirection(columnName)}
    >
      <Flex gap="sm-1" alignItems="center" justifyContent={justifyContent}>
        <Typography variant="body/sm/semibold">
          {t(`templatesArchive.${columnName}`)}
        </Typography>
        {sortDirection === 'desc' ? (
          <Icon name="MoveDown" color="grey.12" fontSize="small" />
        ) : sortDirection === 'asc' ? (
          <Icon name="MoveUp" color="grey.12" fontSize="small" />
        ) : null}
      </Flex>
    </Box>
  );
}
