import { theme } from '@reaidy/components';
import { FormTextInputBasic } from '@xtreamsrl/react-form-input-kit/FormTextInputBasic';
import { FormSelectBasic } from '@xtreamsrl/react-form-input-kit/FormSelectBasic';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { TextInput } from '@xtreamsrl/react-ui-kit/TextInput';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { CommunicationType } from '../../contentCreation/types';

export function TemplateFixedItem({
  communicationType,
  fieldName,
  value,
  remove,
}: {
  communicationType: CommunicationType;
  fieldName: string;
  value: string;
  remove?: () => void;
}) {
  const t = useTranslate();

  return (
    <Box
      padding="sm-6"
      pt="sm-2"
      border={`1px solid ${theme.palette.grey[5]}`}
      style={{ borderRadius: '10px' }}
    >
      <Flex
        justifyContent="space-between"
        marginBottom="sm-4"
        alignItems="center"
      >
        <Flex gap="sm-4" alignItems="center">
          <Icon fontSize="large" name="TextSelect" />
          <Typography>
            {t(`template.creation.${communicationType}.${fieldName}`)}
          </Typography>
        </Flex>
        <Button
          variant="plain"
          onlyIcon={true}
          disabled={!remove}
          onClick={() => {
            if (remove) remove();
          }}
          icon={<Icon color={theme.palette.grey[9]} name="Trash2" />}
        />
      </Flex>
      <Flex direction="column" gap="sm-2">
        <FormTextInputBasic
          testId={`${fieldName}.description`}
          name={`${fieldName}.description`}
          multiline={true}
          placeholder={t('template.creation.descriptionOptional')}
        />
        <Flex gap="sm-4">
          <Box flex={10}>
            <FormSelectBasic name={`${fieldName}.type`} disabled={true}>
              <FormSelectBasic.Option value={value} selected={true}>
                {t(`template.creation.${communicationType}.${fieldName}Field`)}
              </FormSelectBasic.Option>
            </FormSelectBasic>
          </Box>

          <Box flex={1} flexBasis="min-content">
            <FormTextInputBasic
              type="number"
              name={`${fieldName}.maxLength`}
              endInputAddOn={
                <TextInput.AddOn>
                  {t('template.creation.chars')}
                </TextInput.AddOn>
              }
            />
          </Box>
          <Flex flex={1}>
            <Button
              variant="plain"
              onlyIcon={true}
              disabled={true}
              icon={<Icon name="Plus" />}
              color="green"
            />
            <Button
              variant="plain"
              onlyIcon={true}
              disabled={true}
              icon={<Icon name="Minus" />}
              color="red"
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
