/// <reference types="vite-plugin-svgr/client" />
import './_shared/config';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { enableMocking } from './_shared/mock';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

enableMocking()
  .then(() => {
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </React.StrictMode>,
    );
  })
  .catch((err) => {
    console.error('Failed to enable mocking:', err);
  });
