import { useMutation } from '@tanstack/react-query';
import { default as fetch } from 'src/_shared/fetch';
import { NdjsonDecoderStream } from '../utils/utils';

export type TextModChunk = {
  delta: string;
};

export type TextModTypes =
  | 'ENHANCE'
  | 'REPHRASE'
  | 'SUMMARIZE'
  | 'EXPAND'
  | 'CUSTOM_PROMPT_MODIFICATION';

const noop = () => {};

export function useGenerateTextModMutation({
  onStreamChunk = noop,
  onStreamStart = noop,
  onStreamEnd = noop,
  onStreamError = noop,
  onStreamComplete = noop,
}: {
  onStreamChunk: ({ delta }: { delta: string }) => void;
  onStreamEnd?: () => void;
  onStreamError?: (error: unknown) => void;
  onStreamStart?: () => void;
  onStreamComplete?: () => void;
}) {
  return useMutation({
    mutationFn: generateTextMod,
    async onSuccess(stream) {
      try {
        onStreamStart();
        for await (const chunk of readStream(stream)) {
          onStreamChunk(chunk as TextModChunk);
        }
        onStreamComplete();
      } catch (e) {
        onStreamError(e);
      } finally {
        onStreamEnd();
      }
    },
  });
}

async function generateTextMod({
  draftId,
  fullText,
  textToMod,
  directions,
  modType,
}: {
  draftId: string;
  fullText: string;
  textToMod?: string;
  directions?: string;
  modType: TextModTypes;
}) {
  const response = await fetch.post<Response>(
    '/text-modification',
    {
      draftId,
      draft: fullText,
      text: textToMod,
      operationType: modType,
      directions:
        modType === 'CUSTOM_PROMPT_MODIFICATION' ? directions : undefined,
    },
    {
      extractJson: false,
    },
  );
  const stream = response.body;
  if (!stream) {
    throw new Error('No stream');
  }
  return stream;
}

async function* readStream(stream: ReadableStream<Uint8Array>) {
  const reader = stream
    .pipeThrough(new TextDecoderStream())
    .pipeThrough(new NdjsonDecoderStream())
    .getReader();

  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }
    yield value;
  }
}
