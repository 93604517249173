import { useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Spinner } from '@xtreamsrl/react-ui-kit/Spinner';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { useRef } from 'react';
import { CommunicationType } from '../../contentCreation/types';
import { useTemplates } from '../hooks/useTemplates.ts';
import { ColumnName, SortDirection } from '../types';
import { TemplateItem } from './TemplateItem.tsx';

const Scroller = styled('div')({
  position: 'absolute',
  width: '1px',
  height: '10px',
});

export function TemplatesList({
  createNewTemplate,
  communicationType,
  sortBy,
}: {
  createNewTemplate: () => void;
  communicationType: CommunicationType | undefined;
  sortBy: Record<ColumnName, SortDirection> | undefined;
}) {
  const t = useTranslate();

  const scrollerRef = useRef<HTMLDivElement>(null);

  const { isInitialLoading, templates, isFetchingNextPage } = useTemplates({
    commType: communicationType,
    sortBy,
    scrollerRef,
  });

  if (isInitialLoading) {
    return (
      <Flex
        direction="column"
        height="100%"
        alignItems="center"
        justifyContent="center"
        gap="sm-6"
      >
        <Spinner state="indeterminate" size={32} />
      </Flex>
    );
  }
  return !templates?.length ? (
    <Flex
      direction="column"
      height="100%"
      alignItems="center"
      justifyContent="center"
      gap="sm-6"
    >
      <Icon name="Frown" fontSize="80px" />
      <Typography variant="body/base/regular">
        {t('templatesArchive.noTemplates')}
      </Typography>
      <Button onClick={createNewTemplate}>
        {t('templatesArchive.newTemplate')}
      </Button>
    </Flex>
  ) : (
    <Flex direction="column" width="100%" gap="sm-4" alignItems="stretch">
      {templates?.map((template) => {
        return <TemplateItem key={template.id} template={template} />;
      })}
      <Scroller ref={scrollerRef} />
      <Box overflow="hidden">
        {isFetchingNextPage && <Spinner state="indeterminate" />}
      </Box>
    </Flex>
  );
}
