import { StepDone, StepError, StepInProgress, StepToDo } from '@reaidy/icons';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';

export const StepIcon: React.FC = ({
  active,
  completed,
  error,
  icon: text,
}: {
  active?: boolean;
  completed?: boolean;
  error?: boolean;
  icon?: string;
}) => {
  const showIndicator = active;
  const _icon = () => {
    if (active) {
      return <StepInProgress />;
    }
    if (completed) {
      return <StepDone />;
    }
    if (error) {
      return <StepError />;
    }
    return <StepToDo />;
  };

  return (
    <Flex justifyContent="center" alignItems="center">
      <Icon>{_icon()}</Icon>
      {showIndicator && (
        <div
          style={{
            position: 'absolute',
            top: 'auto',
            left: 'auto',
            right: 'auto',
            bottom: 'auto',
          }}
        >
          <Typography
            variant="body/xs/medium"
            color={active ? 'brand.8' : 'white'}
          >
            {text}
          </Typography>
        </div>
      )}
    </Flex>
  );
};
