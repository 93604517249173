import { useTranslate } from '@xtreamsrl/react-i18n';
import { FormEventHandler, useCallback, useState } from 'react';
import type { TranslationKeys } from 'src/_shared/assets/i18n';
import { useDuplicatePersonaDraftMutation } from '../mutations/useDuplicatePersonaDraftMutation';
import { usePersonaListQuery } from '../queries/usePersonaListQuery';
import { Persona, PersonaDraft } from '../types';
import {
  CommEditingModal,
  Emphasis,
  PersonaSelector,
  SubmitButton,
} from './CommEditingModal';

export function CommEditingDuplicateModal({
  isOpen,
  onClose,
  onDraftCreated,
  draft,
  commId,
}: {
  isOpen: boolean;
  onDraftCreated: (draft: PersonaDraft) => void;
  onClose: () => void;
  draft: PersonaDraft;
  commId: string;
}) {
  const t = useTranslate();
  const subtitle = (
    <span>
      {t('commEditing.duplicateModal.subtitle' satisfies TranslationKeys)}:{' '}
      <Emphasis>"{draft.name}"</Emphasis>
    </span>
  );
  const { data: personas = [], isLoading: personasLoading } =
    usePersonaListQuery();

  return (
    <CommEditingModal
      iconName="Sparkles"
      isOpen={isOpen}
      onClose={onClose}
      title={t('commEditing.duplicateModal.title' satisfies TranslationKeys)}
      subtitle={subtitle}
      paragraph={t(
        'commEditing.duplicateModal.paragraph' satisfies TranslationKeys,
      )}
    >
      {personasLoading ? (
        <p>Loading...</p>
      ) : (
        <ModalForm
          onDraftCreated={onDraftCreated}
          commId={commId}
          draft={draft}
          personas={personas}
        />
      )}
    </CommEditingModal>
  );
}

function ModalForm({
  commId,
  draft,
  onDraftCreated,
  personas,
}: {
  commId: string;
  draft: PersonaDraft;
  personas: Persona[];
  onDraftCreated: (draft: PersonaDraft) => void;
}) {
  const [initialPersona] = useState(() =>
    personas.find((persona) => persona.id === draft.personaId),
  );
  const [selectedPersona, setSelectedPersona] = useState(initialPersona);

  const t = useTranslate();
  const { mutate: duplicatePersonaDraft } = useDuplicatePersonaDraftMutation();
  const draftId = draft.id;

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();
      duplicatePersonaDraft(
        {
          commId,
          draftId,
          personaId: selectedPersona?.id,
        },
        {
          onSuccess(data) {
            onDraftCreated(data);
          },
        },
      );
    },
    [duplicatePersonaDraft, commId, draftId, selectedPersona, onDraftCreated],
  );

  return (
    <form onSubmit={handleSubmit}>
      <PersonaSelector
        label={t(
          'commEditing.duplicateModal.form.personaSelector.label' satisfies TranslationKeys,
        )}
        selectedPersona={selectedPersona}
        setSelectedPersona={setSelectedPersona}
      />
      <SubmitButton>
        {t(
          'commEditing.duplicateModal.form.submitButton.label' satisfies TranslationKeys,
        )}
      </SubmitButton>
    </form>
  );
}
