import { useTranslate } from '@xtreamsrl/react-i18n';
import React from 'react';
import { resetState } from '../../../contentCreation/slice/contentCreationSlice.ts';
import { DashboardEvents, useTracker } from '../../analytics';
import HeroSvg from './hero-image.svg?react';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { useBrowserNavigation } from '@xtreamsrl/react-routing';
import { pages } from '@reaidy/pages';

const HeroSection: React.FC = () => {
  const t = useTranslate();
  const { goTo } = useBrowserNavigation();
  const { track } = useTracker();

  return (
    <Flex direction="row" gap="md-4" alignItems="center">
      <HeroSvg />
      <Flex direction="column" gap="sm-4">
        <Box mb="sm-7">
          <Typography variant="header/md/bold">
            {t('dashboard.hero.title')}
          </Typography>
          <Typography variant="body/base/regular">
            {t('dashboard.hero.subtitle')}
          </Typography>
        </Box>
        <Flex direction="row" gap="sm-4">
          {/* todo expose theme augmentation and add into i18n */}
          <Button
            color={'secondary' as never}
            onClick={() => {
              track(DashboardEvents.GoToContentCreation);
              resetState();
              goTo(pages.contentCreation);
            }}
          >
            {t('dashboard.write')}
          </Button>
          <Button
            onClick={() => {
              track(DashboardEvents.GoToArchive);
              goTo(pages.archive);
            }}
          >
            {t('dashboard.archive')}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

HeroSection.displayName = 'HeroSection';

export default HeroSection;
