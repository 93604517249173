import { pages } from '@reaidy/pages';
import { useForm } from '@xtreamsrl/react-forms';
import { useBrowserNavigation } from '@xtreamsrl/react-routing';
import React from 'react';
import * as yup from 'yup';
import { useUserInfoSlice } from '../../_shared/slice/userInfoSlice.ts';
import { useLoginMutation } from '../mutations/useLoginMutation.ts';

type LoginData = {
  email: string;
  password: string;
};
const initialValues: LoginData = {
  email: '',
  password: '',
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('login.emailInvalid')
    .required('login.emailRequired'),
  password: yup.string().required('login.passwordRequired'),
});

export function useLogin() {
  const { goTo } = useBrowserNavigation();
  const form = useForm<LoginData>({
    initialValues,
    validationSchema,
    mode: 'onBlur',
  });
  const { mutate, isPending } = useLoginMutation();
  const [showModal, setShowModal] = React.useState(false);

  const login = (values: LoginData) => {
    mutate(values, {
      onSuccess: (data) => {
        useUserInfoSlice.getState().setUserInfo(data);
        goTo(pages.home);
      },
      onError: () => {
        setShowModal(true);
        form.formProps.reset();
      },
    });
  };

  return {
    showModal,
    setShowModal,
    form,
    login,
    isPending,
  };
}
