import { SVGProps } from 'react';

export const ReaidyLogoInlineSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || '97'}
      height={props.height || '26'}
      viewBox="0 0 97 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.9415 17.0597V10.995C43.9415 10.2369 43.9415 9.47882 43.9255 8.72073H46.4572C46.505 9.02719 46.5369 9.94658 46.5369 10.3982C46.9986 9.3014 48.0017 8.47879 49.7055 8.51105V10.9143C47.8425 10.8821 46.5369 11.6402 46.5369 14.0112V17.0597H43.9415Z"
        fill="#384455"
      />
      <path
        d="M59.6914 13.5273H53.2427C53.3064 14.5435 54.0229 15.3338 55.2649 15.3338C56.2361 15.3338 56.6979 15.0274 57.0004 14.5112H59.6118C59.1022 15.9951 57.8762 17.2855 55.233 17.2855C51.8574 17.2855 50.6473 15.0274 50.6473 12.8983C50.6473 10.5595 52.2077 8.49492 55.2171 8.49492C58.4175 8.49492 59.7073 10.7369 59.7073 12.8983C59.7073 13.108 59.7073 13.2692 59.6914 13.5273ZM53.2745 11.9144H57.0641C57.0164 11.2853 56.682 10.3498 55.2012 10.3498C53.8477 10.3498 53.3382 11.0918 53.2745 11.9144Z"
        fill="#384455"
      />
      <path
        d="M69.5552 11.5918V14.7209C69.5552 15.8016 69.603 16.8823 69.6189 17.0597H67.1668C67.1031 16.8661 67.0872 16.35 67.0713 16.1242C66.6254 16.8339 65.686 17.2855 64.1256 17.2855C61.8805 17.2855 60.9729 15.8984 60.9729 14.5596C60.9729 12.3821 62.8677 11.7692 65.2879 11.7692H67.0076V11.5111C67.0076 10.8337 66.721 10.2853 65.5109 10.2853C64.3963 10.2853 64.1415 10.7047 64.03 11.1885H61.4347C61.4983 10.1563 62.2149 8.49492 65.479 8.49492C69.0457 8.49492 69.5552 10.2046 69.5552 11.5918ZM67.0076 13.5596V13.4305C66.8165 13.4144 66.2592 13.4144 65.3357 13.4144C64.0141 13.4144 63.4887 13.7693 63.4887 14.4306C63.4887 14.979 63.9186 15.4145 64.874 15.4145C66.2911 15.4145 67.0076 14.7532 67.0076 13.5596Z"
        fill="#384455"
      />
      <path
        d="M71.7595 17.0597V8.72073H74.3549V17.0597H71.7595ZM74.3549 7.15618H71.7595V5.04321H74.3549V7.15618Z"
        fill="#384455"
      />
      <path
        d="M82.806 5.04321H85.4173V14.7209C85.4173 15.4951 85.4173 16.2855 85.4333 17.0597H82.8697C82.8379 16.8177 82.8219 16.4145 82.806 16.0919C82.3443 16.7371 81.771 17.2855 80.0673 17.2855C77.7744 17.2855 76.0707 15.5596 76.0707 12.9628C76.0707 10.1563 78.268 8.49492 80.3061 8.49492C81.9143 8.49492 82.5672 9.04332 82.806 9.36591V5.04321ZM78.7457 12.8983C78.7457 14.2209 79.4941 15.2209 80.8316 15.2209C82.1691 15.2209 82.8697 14.3338 82.8697 12.8983C82.8697 11.4627 82.3124 10.5434 80.8316 10.5434C79.4304 10.5434 78.7457 11.6724 78.7457 12.8983Z"
        fill="#384455"
      />
      <path
        d="M86.7872 8.72073H89.6373C91.0545 12.3337 91.6595 14.3499 91.8187 14.9467H91.8665C92.0894 14.1241 92.7263 11.9144 93.8409 8.72073H96.5L93.7454 15.8661C92.3283 19.592 91.8984 20.8017 89.08 20.8017C88.7138 20.8017 88.1088 20.7695 87.8062 20.7049V18.5758C88.1884 18.6081 88.3794 18.6242 88.6342 18.6242C89.7647 18.6242 90.0195 18.2533 90.4494 17.3823L86.7872 8.72073Z"
        fill="#384455"
      />
      <path
        d="M14.6669 25.3085C14.4348 15.0205 1.06828 14.9005 0.5 14.9005V11.1406C1.06828 11.1406 14.4348 11.0206 14.6669 0.732666L18.4287 0.820665C18.2766 7.35661 14.2347 11.0686 9.68046 13.0206C14.2347 14.9725 18.2766 18.6845 18.4287 25.2205L14.6669 25.3085Z"
        fill="#43DB37"
      />
      <path
        d="M25.5917 25.2834L21.4697 25.1874C21.6138 18.7395 25.5037 15.0115 29.9538 12.9875C25.5037 10.9716 21.6218 7.2356 21.4697 0.78765L25.5917 0.69165C25.8238 10.8036 39.0142 10.9236 39.5825 10.9236V15.0435C39.0222 15.0435 25.8238 15.1635 25.5917 25.2754V25.2834Z"
        fill="#5F4DDB"
      />
    </svg>
  );
};
