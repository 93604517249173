import { useTranslate } from '@xtreamsrl/react-i18n';
import { EditorHeader } from './EditorHeader';
import { EditorStatus } from './EditorStatus';
import { TranslationKeys } from 'src/_shared/assets/i18n';

export type HeaderStatus = 'loading' | 'loaded' | 'error';

export function MarkdownViewerHeader({ status }: { status: HeaderStatus }) {
  const t = useTranslate();
  const isError = status === 'error';
  return (
    <EditorHeader>
      <EditorStatus pinColor={isError ? 'error.10' : 'brand.6'}>
        {status === 'loading' &&
          t('commEditing.status.loading' satisfies TranslationKeys)}
        {status === 'loaded' &&
          t('commEditing.status.loaded' satisfies TranslationKeys)}
        {status === 'error' &&
          t('commEditing.status.error' satisfies TranslationKeys)}
      </EditorStatus>
    </EditorHeader>
  );
}
