import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updatePersonaDraft as updatePersonaDraftAPI } from '../apis/updatePersonaDraft.ts';
import { commPersonaDraftQueryKeys } from '../queries/useCommPersonaDraftQuery.ts';
import { PersonaDraft } from '../types/index.ts';

/** @see https://tanstack.com/query/v5/docs/framework/react/guides/optimistic-updates */
export function useUpdatePersonaDraftMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updatePersonaDraftAPI,
    async onMutate({ commId, draftId, update }) {
      const draftQueryKey = commPersonaDraftQueryKeys.byCommIdAndDraftId(
        commId,
        draftId,
      );

      // Cancel any outgoing refetches
      const cancelPromise = queryClient.cancelQueries({
        queryKey: draftQueryKey,
      });

      // Snapshot the previous value
      const previousDraft =
        queryClient.getQueryData<PersonaDraft>(draftQueryKey);

      if (!previousDraft) {
        throw new Error('Draft not found');
      }

      // Optimistically update the cache
      queryClient.setQueryData<PersonaDraft>(draftQueryKey, {
        ...previousDraft,
        ...update,
        _optimistic: true,
      });

      // It's important to cancel outgoing refetches asap,
      // but awaiting directly makes the optimistic update laggy
      await cancelPromise;

      // Return a context object with the snapshotted value
      return { previousDraft };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError(error, variables, context) {
      console.error(error);
      if (context) {
        const queryKey = commPersonaDraftQueryKeys.byCommIdAndDraftId(
          variables.commId,
          variables.draftId,
        );
        queryClient.setQueryData(queryKey, context.previousDraft);
      }
    },
    // Always refetch after error or success:
    onSettled(_, __, variables) {
      const queryKey = commPersonaDraftQueryKeys.byCommIdAndDraftId(
        variables.commId,
        variables.draftId,
      );
      queryClient.invalidateQueries({ queryKey }).catch(console.error);
    },
  });
}
