import { useRef } from 'react';
import { ActionMenu } from './ActionMenu';
import { MenuPortal } from './Menu';
import { ReviewMenu } from './ReviewMenu';
import { useMenuActions } from './useMenuActions';
import { useMenuPosition } from './useMenuPosition';

export default function SelectionToolbar({
  draftId,
  commId,
}: {
  draftId: string;
  commId: string;
}) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { menuPosition, resetPosition: closeMenu } = useMenuPosition({
    containerRef,
  });

  const {
    generationContent,
    generationStarted,
    generationInProgress,
    // generationComplete,
    // generationError,
    // generationEnded,
    handleCopy,
    handlePaste,
    handleCut,
    handleModify,
    handleEnhance,
    handleShorten,
    handleExpand,
    handleRephrase,
    handleDelete,
    handleAcceptMod,
    handleRejectMod,
  } = useMenuActions({
    draftId,
    commId,
    closeMenu,
  });

  if (!menuPosition) return null;

  return (
    <MenuPortal>
      <div ref={containerRef}>
        {generationStarted ? (
          <ReviewMenu
            menuPosition={menuPosition}
            content={generationContent}
            onAcceptMod={handleAcceptMod}
            onRejectMod={handleRejectMod}
            isLoading={generationInProgress}
          />
        ) : (
          <ActionMenu
            menuPosition={menuPosition}
            onCopy={handleCopy}
            onPaste={handlePaste}
            onCut={handleCut}
            onEnhance={handleEnhance}
            onShorten={handleShorten}
            onExpand={handleExpand}
            onRephrase={handleRephrase}
            onDelete={handleDelete}
            onModify={handleModify}
          />
        )}
      </div>
    </MenuPortal>
  );
}
