import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';
import {
  SearchCommFilters,
  searchCommunicationsAPI,
} from '../apis/searchCommunications';

export const searchCommsListQueryKeys = {
  all: ['comms', 'search'] as const,
  filtered: (filters: SearchCommFilters) =>
    ['comms', 'search', filters] as const,
};

export function getSearchCommsQueryOptions(filters: SearchCommFilters = {}) {
  const queryKey = searchCommsListQueryKeys.filtered(filters);
  return infiniteQueryOptions({
    queryKey,
    queryFn({ pageParam, queryKey }) {
      return searchCommunicationsAPI(pageParam, queryKey[2]);
    },
    initialPageParam: 0,
    getNextPageParam: ({ pageInfo }) => pageInfo.nextPage,
  });
}

export function useSearchCommsQuery({
  filters,
}: {
  filters?: SearchCommFilters;
}) {
  return useInfiniteQuery(getSearchCommsQueryOptions(filters));
}
