import { AnalyticsEvent } from '@xtreamsrl/react-analytics';
import { ArchiveEvents } from './events';

interface CommunicationRelatedEventProps {
  communicationId: string;
}

export const archiveEventsMapping = {
  [ArchiveEvents.GoToContentCreation]: () => ({
    type: 'goToContentCreationFromArchive',
  }),
  [ArchiveEvents.ViewCommunication]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: 'viewCommunication',
    props,
  }),
  [ArchiveEvents.EditCommunication]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: 'editCommunication',
    props,
  }),
  [ArchiveEvents.DuplicateFromBrief]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: 'duplicateFromBrief',
    props,
  }),
  [ArchiveEvents.OpenDuplicateFromDraftDialog]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: 'openDuplicateFromDraftDialog',
    props,
  }),
  [ArchiveEvents.DuplicateFromDraft]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: 'duplicateFromDraft',
    props,
  }),
  [ArchiveEvents.OpenDuplicateFromPersonasDialog]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: 'openDuplicateFromPersonasDialog',
    props,
  }),
  [ArchiveEvents.DuplicateFromPersonas]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: 'duplicateFromPersonas',
    props,
  }),
  [ArchiveEvents.OpenDeleteDialog]: (
    props: CommunicationRelatedEventProps,
  ) => ({
    type: 'openDeleteDialog',
    props,
  }),
  // eslint-disable-next-line
} satisfies Record<ArchiveEvents, (...props: any[]) => AnalyticsEvent>;
