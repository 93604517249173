// 'overrides' explanation can be found on remark-slate-transformer's README
import { unified } from 'unified';
import markdown from 'remark-parse';
import { remarkToSlate } from 'remark-slate-transformer';
import { HistoryEditor, withHistory } from 'slate-history';
import { BaseEditor, BaseText, createEditor, Transforms } from 'slate';
import { ReactEditor, RenderLeafProps, withReact } from 'slate-react';
import * as MDAST from 'mdast';
import stringify from 'remark-stringify';

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: MDAST.RootContentMap[keyof MDAST.RootContentMap];
  }
}

declare module 'slate' {
  interface CustomTypes {
    Text: BaseText & { strong?: boolean };
  }
}

export const processor = unified().use(markdown).use(remarkToSlate);
export const slateToMarkdownProcessor = unified().use(stringify);

export type BHREditor = BaseEditor & ReactEditor & HistoryEditor;
export function instantiateEditor(): BHREditor {
  return withReact(withHistory(createEditor()));
}

export const renderElement = ({
  children,
  element,
}: {
  children: string;
  element: MDAST.RootContentMap[keyof MDAST.RootContentMap];
}) => {
  switch (element.type) {
    case 'heading':
      if (element.depth === 1) return <h1>{children}</h1>;
      if (element.depth === 2) return <h2>{children}</h2>;
      return <h3>{children}</h3>;
    case 'list':
      return <ul>{children}</ul>;
    case 'listItem':
      return <li>{children}</li>;
    default:
      return <p>{children}</p>;
  }
};

export const renderLeaf = (props: RenderLeafProps) => {
  return (
    <span
      {...props.attributes}
      style={{ fontWeight: props.leaf.strong ? 'bold' : undefined }}
    >
      {props.children}
    </span>
  );
};

export function withSingleLine<T extends BHREditor>(editor: T) {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length > 1) {
        Transforms.mergeNodes(editor);
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
}
