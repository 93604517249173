import Grid from '@mui/material/Grid';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Card } from '@xtreamsrl/react-ui-kit/Card';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { SidePanel } from '@xtreamsrl/react-ui-kit/SidePanel';
import { Spinner } from '@xtreamsrl/react-ui-kit/Spinner';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { useRef, useState } from 'react';
import { Divider } from '@xtreamsrl/react-ui-kit/Divider';
import { useTemplateDetailsQuery } from 'src/templates/queries/useTemplateDetailsQuery.ts';
import { generateLoremIpsumMarkdown } from 'src/templates/utils/index.ts';
import StyledCard from '../../_shared/components/StyledCard.tsx';
import { TemplateCard } from '../../templates/components/TemplateCard';
import { useTemplates } from '../../templates/hooks/useTemplates.ts';
import { Template } from '../../templates/types';
import { TemplateCreator } from '../../templates/views/TemplateCreator.tsx';
import { ContentCreationEvents, useTracker } from '../analytics';
import { MarkdownViewer } from '../components/MarkdownViewer.tsx';
import { useCommunicationType } from '../hooks/useCommunicationType.ts';
import { useStepper } from '../hooks/useStepper';
import { useTemplateIdSetter } from '../hooks/useTemplateIdSetter.ts';

const TemplateLoadingCard = () => (
  <StyledCard height="50vh">
    <Card.Content>
      <Box height="40vh">
        <Flex
          direction="column"
          gap="sm-8"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Spinner size={20} state="indeterminate" />
        </Flex>
      </Box>
    </Card.Content>
  </StyledCard>
);

export function TemplateSelector() {
  const t = useTranslate();
  const { previous, next } = useStepper();
  const communicationType = useCommunicationType();
  const scrollerRef = useRef<HTMLDivElement>(null);

  const { templates, isInitialLoading: isLoading } = useTemplates({
    commType: communicationType,
    scrollerRef,
  });

  const setTemplate = useTemplateIdSetter();
  const [isCreatorOpen, setIsCreatorOpen] = useState(false);
  const { track } = useTracker();

  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();

  if (!communicationType) {
    throw new Error('Communication type not found');
  }

  return (
    <Flex gap="sm-3" direction="column" padding="sm-4" height="100%">
      <SidePanel
        open={isCreatorOpen}
        onClose={() => setIsCreatorOpen(false)}
        anchor="right"
      >
        <SidePanel.Content width="80vw">
          <TemplateCreator
            closeCreator={() => {
              setIsCreatorOpen(false);
            }}
            communicationType={communicationType}
          />
        </SidePanel.Content>
      </SidePanel>

      <SidePanel
        open={!!selectedTemplateId}
        onClose={() => setSelectedTemplateId(undefined)}
        anchor="right"
      >
        <SidePanel.Content width="80vw">
          {selectedTemplateId ? (
            <TemplatePreview
              templateId={selectedTemplateId}
              onCancel={() => setSelectedTemplateId(undefined)}
              onConfirm={() => {
                track(ContentCreationEvents.SelectTemplate);
                setTemplate(selectedTemplateId);
                next();
              }}
            />
          ) : (
            <Typography variant="body/sm/regular">
              {t('template.choose.error')}
            </Typography>
          )}
        </SidePanel.Content>
      </SidePanel>
      <Flex
        direction="column"
        gap="sm-6"
        padding="sm-8"
        alignItems="flex-start"
        justifyContent="center"
        flexBasis="25vh"
        flexShrink="0"
      >
        <Button
          testId="previous-button"
          style={{ borderRadius: '50px' }}
          size="sm"
          variant="outlined"
          onlyIcon={true}
          icon={<Icon name="ArrowLeft" />}
          onClick={() => previous()}
        />
        <Typography variant="header/sm/bold" color="brand.9">
          {t('template.header')}
        </Typography>
        <Typography variant="body/base/regular">
          {t('template.indications')}
        </Typography>
      </Flex>
      <Grid container alignSelf="center" flex={7}>
        {isLoading ? (
          <Grid padding="8px" item lg={3} md={4} sm={6} xs={12}>
            <TemplateLoadingCard />
          </Grid>
        ) : (
          templates?.map((template: Template, index) =>
            index !== templates.length - 1 ? (
              <Grid
                key={template.id}
                padding="8px"
                item
                lg={3}
                md={4}
                sm={6}
                xs={12}
              >
                <TemplateCard
                  template={template}
                  onClick={() => setSelectedTemplateId(template.id)}
                />
              </Grid>
            ) : (
              <Grid padding="8px" item lg={3} md={4} sm={6} xs={12}>
                <Box ref={scrollerRef}>
                  <TemplateCard
                    template={template}
                    onClick={() => setSelectedTemplateId(template.id)}
                  />
                </Box>
              </Grid>
            ),
          )
        )}
        <Grid padding="8px" item lg={3} md={4} sm={6} xs={12}>
          <Card height="50vh">
            <Card.Content>
              <Flex
                direction="column"
                gap="sm-3"
                alignItems="center"
                justifyContent="center"
                height="40vh"
              >
                <Typography variant="body/sm/bold">
                  {t('template.create')}
                </Typography>
                <Button
                  testId="open-template-creator"
                  size="sm"
                  variant="outlined"
                  onClick={() => {
                    track(ContentCreationEvents.OpenTemplateCreatorPanelInFlow);
                    setIsCreatorOpen(true);
                  }}
                >
                  {t('template.createNow')}
                </Button>
              </Flex>
            </Card.Content>
          </Card>
        </Grid>
      </Grid>
    </Flex>
  );
}

function TemplatePreview({
  templateId,
  onCancel,
  onConfirm,
}: {
  templateId: string;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const t = useTranslate();
  const { data: templateInfo } = useTemplateDetailsQuery(templateId);

  if (!templateInfo) {
    return null; // FIXME: show loader
  }

  return (
    <Flex flex={3} direction="column" gap="sm-4" height="100%" overflow="auto">
      <Flex
        alignItems="center"
        marginTop="md-2"
        marginBottom="sm-2"
        justifyContent="space-between"
      >
        <Typography variant="header/sm/bold" color="brand.9">
          {t('template.choose.header', { name: templateInfo.name })}
        </Typography>
        <Flex gap="sm-2">
          <Button type="button" variant="outlined" onClick={() => onCancel()}>
            {t('template.choose.cancel')}
          </Button>
          <Button
            testId="select-template"
            type="submit"
            variant="filled"
            onClick={() => onConfirm()}
          >
            {t('template.choose.confirm')}
          </Button>
        </Flex>
      </Flex>
      <Divider />
      <Typography variant="body/base/semibold">
        {t('template.choose.content')}
      </Typography>
      <MarkdownViewer
        contentToDisplay={generateLoremIpsumMarkdown(templateInfo.structure)}
      />
    </Flex>
  );
}
