import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EventSourceParserStream } from 'eventsource-parser/stream';
import { useCallback } from 'react';
import { default as fetch } from 'src/_shared/fetch';
import { commPersonaDraftQueryKeys } from '../queries/useCommPersonaDraftQuery';
import { Communication, PersonaDraft } from '../types';
import { commDetailsQueryKeys } from '../queries/useCommDetailsQuery';

type ChunkData = {
  delta: string;
};

type ErrorData = {
  message: string;
  code: string;
  id: string;
};

export function useReworkPersonaDraftMutation({
  onNewDraftPending,
  onNewDraftCancelled,
  onNewDraftSaved,
}: {
  onNewDraftPending?: (pendingDraft: PersonaDraft) => void;
  onNewDraftCancelled?: (cancelledDraft: PersonaDraft) => void;
  onNewDraftSaved?: (savedDraft: PersonaDraft) => void;
}) {
  const queryClient = useQueryClient();

  const handleNewDraft = useCallback(
    (newDraft: PersonaDraft, commId: string) => {
      // Update the draft in the cache
      const draftQueryKey = commPersonaDraftQueryKeys.byCommIdAndDraftId(
        commId,
        newDraft.id,
      );
      queryClient.setQueryData<PersonaDraft>(draftQueryKey, newDraft);

      // Add the draft to the list of drafts
      const commQueryKey = commDetailsQueryKeys.byId(commId);
      queryClient.setQueryData<Communication>(commQueryKey, (comm) => {
        if (!comm) return comm;
        return {
          ...comm,
          personaDrafts: [...comm.personaDrafts, newDraft],
        };
      });

      // Notify the caller that a new draft is pending
      onNewDraftPending?.(newDraft);
    },
    [queryClient, onNewDraftPending],
  );

  const handleNewChunk = useCallback(
    (data: ChunkData, commId: string, draftId: string) => {
      // Update the draft in the cache
      queryClient.setQueryData<PersonaDraft>(
        commPersonaDraftQueryKeys.byCommIdAndDraftId(commId, draftId),
        (draft) => {
          if (!draft) {
            console.error("Draft doesn't exist in cache");
            return;
          }
          const content = draft.draft ?? '';
          return { ...draft, draft: content + data.delta };
        },
      );
      // No need to update the list of drafts
    },
    [queryClient],
  );

  const handleError = useCallback(
    (newDraft: PersonaDraft, commId: string) => {
      // Invalidate all related queries
      const promises = [
        queryClient.invalidateQueries({
          queryKey: commPersonaDraftQueryKeys.byCommIdAndDraftId(
            commId,
            newDraft.id,
          ),
        }),
        queryClient.invalidateQueries({
          queryKey: commDetailsQueryKeys.byId(commId),
        }),
      ];
      Promise.all(promises)
        .catch(console.error)
        // Notify the caller that the draft was cancelled
        .finally(() => onNewDraftCancelled?.(newDraft));
    },
    [queryClient, onNewDraftCancelled],
  );

  const handleComplete = useCallback(
    (newDraft: PersonaDraft, commId: string) => {
      // Invalidate all related queries
      const promises = [
        queryClient.invalidateQueries({
          queryKey: commPersonaDraftQueryKeys.byCommIdAndDraftId(
            commId,
            newDraft.id,
          ),
        }),
        queryClient.invalidateQueries({
          queryKey: commDetailsQueryKeys.byId(commId),
        }),
      ];
      Promise.all(promises)
        .catch(console.error)
        // Notify the caller that the draft was saved
        .finally(() => onNewDraftSaved?.(newDraft));
    },
    [queryClient, onNewDraftSaved],
  );

  return useMutation({
    mutationFn: reworkPersonaDraft,
    async onSuccess(stream, { commId, draftId }) {
      // Cancel related queries while we spoil the cache
      const cancelPromises = [
        queryClient.cancelQueries({
          queryKey: commPersonaDraftQueryKeys.byCommIdAndDraftId(
            commId,
            draftId,
          ),
        }),
        queryClient.cancelQueries({
          queryKey: commDetailsQueryKeys.byId(commId),
        }),
      ];
      await Promise.all(cancelPromises);

      let newDraft: PersonaDraft | undefined;
      try {
        for await (const chunk of readStream(stream)) {
          const data: unknown = JSON.parse(chunk.data);
          if (chunk.event === 'newDraft') {
            newDraft = data as PersonaDraft;
            handleNewDraft(data as PersonaDraft, commId);
          } else if (chunk.event === 'message') {
            if (!newDraft) throw new Error('Received message before newDraft');
            handleNewChunk(data as ChunkData, commId, newDraft.id);
          } else if (chunk.event === 'error') {
            const error = data as ErrorData;
            throw new Error(`${error.code}: ${error.message}`);
          }
        }
        if (!newDraft) throw new Error('Stream ended without newDraft');
        handleComplete(newDraft, commId);
      } catch (e) {
        console.error(e);
        if (newDraft) handleError(newDraft, commId);
      }
    },
  });
}

async function reworkPersonaDraft({
  commId,
  draftId,
  newPersonaId,
  newName,
  directions,
}: {
  commId: string;
  draftId: string;
  newPersonaId: string;
  newName?: string;
  directions?: string;
}) {
  const response = await fetch.post<Response>(
    `/communications/${commId}/persona-drafts/${draftId}/rework`,
    {
      personaId: newPersonaId,
      name: newName,
      directions: directions || undefined,
    },
    {
      headers: {
        'Accept-Encoding': 'chunked',
      },
      extractJson: false,
    },
  );
  if (!response.body) throw new Error('Failed to get rework draft stream');
  return response.body;
}

async function* readStream(stream: ReadableStream<Uint8Array>) {
  const eventStream = stream
    .pipeThrough(new TextDecoderStream())
    .pipeThrough(new EventSourceParserStream())
    .getReader();

  while (true) {
    const { done, value } = await eventStream.read();
    if (done) break;
    yield value;
  }
}
