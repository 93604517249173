// import { createMutation } from '@xtreamsrl/react-query';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCommunicationAPI } from '../apis/deleteCommunication';
import { searchCommsListQueryKeys } from '../queries/useSearchCommsQuery';
import { commDetailsQueryKeys } from 'src/contentCreation/queries/useCommDetailsQuery';

// export const { hook: useDeleteCommunicationMutation } = createMutation({
//   mutatingFunction: (data: { communicationId: string }) =>
//     deleteCommunicationAPI(data),
// });

export function useDeleteCommMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteCommunicationAPI,
    async onMutate({ communicationId }) {
      // Cancel related queries
      await Promise.allSettled([
        queryClient.cancelQueries({
          queryKey: searchCommsListQueryKeys.all,
        }),
        queryClient.cancelQueries({
          queryKey: commDetailsQueryKeys.byId(communicationId),
        }),
      ]);
    },
    async onSuccess(_, { communicationId }) {
      // Invalidate related queries
      await queryClient.invalidateQueries({
        queryKey: searchCommsListQueryKeys.all,
      });
      queryClient.removeQueries({
        queryKey: commDetailsQueryKeys.byId(communicationId),
      });
    },
  });
}
