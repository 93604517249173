import Grid from '@mui/material/Grid';
import { FeatureWrapper } from '@xtreamsrl/react-feature-flags';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Card } from '@xtreamsrl/react-ui-kit/Card';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import StyledCard from '../../_shared/components/StyledCard';
import { ContentCreationEvents, useTracker } from '../analytics';
import { useStepper } from '../hooks/useStepper';
import { communicationsTypes } from '../types';
import { useContentCreationSlice } from '../slice/contentCreationSlice';
import { useCallback } from 'react';

export function CommTypeSelector() {
  return (
    <Flex gap="sm-3" direction="column" padding="sm-4" height="100%">
      <Header />
      <Grid container alignSelf="center" flex={7} overflow="initial">
        {communicationsTypes.map((communicationType) => (
          <FeatureWrapper
            feature={communicationType.feature}
            key={communicationType.text}
          >
            <Option commType={communicationType} />
          </FeatureWrapper>
        ))}
      </Grid>
    </Flex>
  );
}

function Header() {
  const { previous } = useStepper();
  const t = useTranslate();

  return (
    <Flex
      direction="column"
      gap="sm-6"
      padding="sm-8"
      alignItems="flex-start"
      justifyContent="center"
      flexBasis="25vh"
      flexShrink="0"
    >
      <FeatureWrapper feature={'ShowCommunicationAim'}>
        <Button
          style={{ borderRadius: '50px' }}
          size="sm"
          variant="outlined"
          onlyIcon={true}
          icon={<Icon name="ArrowLeft" />}
          onClick={() => previous()}
        />
      </FeatureWrapper>
      <Typography variant="header/sm/bold" color="brand.9">
        {t('communicationTypeSelector.header')}
      </Typography>
    </Flex>
  );
}

function Option({ commType }: { commType: (typeof communicationsTypes)[0] }) {
  const CommTypeIcon = commType.icon;
  const tracker = useTracker();
  const stepper = useStepper();
  const t = useTranslate();

  const setCommunicationType = useContentCreationSlice(
    (state) => state.setCommunicationType,
  );

  const handleClick = useCallback(() => {
    tracker.track(ContentCreationEvents.SelectCommunicationType);
    setCommunicationType(commType.code);
    stepper.next();
  }, [tracker, setCommunicationType, commType, stepper]);

  return (
    <Grid padding="8px" key={commType.text} item lg={3} md={4} sm={6} xs={12}>
      <StyledCard testId={commType.code} height="50vh">
        <Card.Content>
          <Box height="40vh" onClick={handleClick}>
            <Flex
              direction="column"
              gap="sm-8"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <CommTypeIcon />
              <Typography variant="body/sm/bold">{t(commType.text)}</Typography>
            </Flex>
          </Box>
        </Card.Content>
      </StyledCard>
    </Grid>
  );
}
