import React, {
  Children,
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Descendant, Editor, Node, Transforms } from 'slate';
import { Editable, ReactEditor, Slate } from 'slate-react';
import {
  instantiateEditor,
  processor,
  renderElement,
  renderLeaf,
} from '../../_shared/utils/slate';
import { EditorWrapper } from './EditorWrapper';
import SelectionToolbar from './SelectionToolbar';

const EditorHeader: React.FC<PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};
EditorHeader.displayName = 'MarkdownEditor.Header';

export interface MarkdownEditorRef {
  setEditorContent: (content: string) => void;
}

export type MarkdownEditorNode = Descendant;

export type MarkdownEditorProps = {
  onChange: (value: MarkdownEditorNode[]) => void;
  initialString: string;
  draftId?: string;
  commId?: string;
  children: ReactNode;
};

// TODO - remove as MarkdownEditorValue[] when obtaining the components from the markdown parser
const MarkdownEditorContainer = forwardRef<
  MarkdownEditorRef,
  MarkdownEditorProps
>(function MarkdownEditor(
  { onChange, initialString, children, draftId, commId },
  ref: React.ForwardedRef<MarkdownEditorRef>,
) {
  const editor = useMemo(() => instantiateEditor(), []);

  // The initial draft to display. This reference should stay constant.
  // If the initialString is fetched from a server, you should wait for the data
  // to be available before rendering the component.
  const [initialDraft] = useState(() => {
    const nodes = processor.processSync(initialString)
      .result as MarkdownEditorNode[];
    editor.children = nodes;
    // This will fix syntax errors found in the nodes
    // If you want to test this, try passing "# /n/n # /n/n" as initialString
    Editor.normalize(editor, { force: true });
    return editor.children;
  });

  // The presence of this flag prevents the constant processing of the descendant array (value) after each change.
  // Without it, the processor would repeatedly process the array to obtain the corresponding string and compare it with the initialString,
  // to avoid unnecessary calls to handleSave.
  // This safeguard ensures that the system doesn't unintentionally mark everything as "still to be saved," when edits have just been discarded.
  const isContentImperativelySet = React.useRef(false);

  const header = useMemo(() => {
    return Children.toArray(children).find((ch) => {
      return React.isValidElement(ch) && ch.type === EditorHeader;
    });
  }, [children]);

  useImperativeHandle(
    ref,
    () => {
      return {
        setEditorContent(content: string) {
          Transforms.select(editor, {
            anchor: Editor.start(editor, []),
            focus: Editor.end(editor, []),
          });
          Transforms.delete(editor);
          Transforms.insertNodes(
            editor,
            processor.processSync(content).result as MarkdownEditorNode[],
            { at: [0] },
          );
          // Optionally, inspect and remove the last node if it's an unwanted newline
          const lastNodePath = [editor.children.length - 1];
          const lastNode = Editor.node(editor, lastNodePath);
          if (lastNode && Node.string(lastNode[0]).trim() === '') {
            Transforms.removeNodes(editor, { at: lastNodePath });
          }
          isContentImperativelySet.current = true;
          ReactEditor.focus(editor);
        },
      };
    },
    [editor],
  );

  return (
    <Slate
      editor={editor}
      initialValue={initialDraft}
      onValueChange={(value) => {
        // is there a way to avoid the continuous processing of the value?
        // the alternative would be the deep comparison between the initialDraft and the value,
        // but the cost of the comparison is higher than the cost of the processing of the value itself
        if (isContentImperativelySet.current) {
          isContentImperativelySet.current = false;
          return;
        }
        onChange(value);
      }}
    >
      {header}
      {draftId && commId && (
        <SelectionToolbar draftId={draftId} commId={commId} />
      )}
      <EditorWrapper>
        <Editable
          className="markdown-content"
          readOnly={false}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          disableDefaultStyles={false}
          style={{ outline: 'none' }}
        />
      </EditorWrapper>
    </Slate>
  );
});

MarkdownEditorContainer.displayName = 'MarkdownEditor';

const MarkdownEditor = Object.assign(MarkdownEditorContainer, {
  Header: EditorHeader,
});
export default MarkdownEditor;
