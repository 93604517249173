import { useMutation, useQueryClient } from '@tanstack/react-query';
import { default as fetch } from 'src/_shared/fetch';
import { commDetailsQueryKeys } from '../queries/useCommDetailsQuery';
import { PersonaDraft } from '../types';

export function useDuplicatePersonaDraftMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: duplicatePersonaDraft,
    async onMutate({ commId }) {
      // Cancel related queries
      await queryClient.cancelQueries({
        queryKey: commDetailsQueryKeys.byId(commId),
      });
    },
    async onSettled(_, __, { commId }) {
      // Invalidate related queries
      await queryClient.invalidateQueries({
        queryKey: commDetailsQueryKeys.byId(commId),
      });
    },
  });
}

function duplicatePersonaDraft({
  draftId,
  commId,
  personaId,
}: {
  draftId: string;
  commId: string;
  personaId?: string;
}) {
  return fetch.post<PersonaDraft>(
    `/communications/${commId}/persona-drafts/${draftId}/copy`,
    {
      personaId,
    },
    {
      extractJson: true,
    },
  );
}
