import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { styled } from '@xtreamsrl/react-ui-kit/styles';
import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ButtonShadow, InputShadow } from '../Panel';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { TranslationKeys } from 'src/_shared/assets/i18n';

export type FollowupType = 'modify';

export type FollowupActionsProps = {
  type: FollowupType;
  onModify: (directions: string) => void;
  close: () => void;
};

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '0.5rem',
});

const Input = styled(InputShadow)({
  width: '40ch',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  resize: 'none', // draggable resize handle does't work for some reason
});

export function FollowupActions({
  type,
  onModify,
  close,
}: FollowupActionsProps) {
  const [input, setInput] = useState('');
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    (e) => setInput(e.target.value),
    [],
  );

  const inputTrimmed = useMemo(() => input.trim(), [input]);
  const submitDisabled = !inputTrimmed;

  const handleSubmit = useCallback<FormEventHandler>(
    (e) => {
      e.preventDefault();
      if (submitDisabled) return;
      if (type === 'modify') onModify(inputTrimmed);
    },
    [submitDisabled, type, onModify, inputTrimmed],
  );

  const t = useTranslate();
  const placeholder = t('editing.enterDirections' satisfies TranslationKeys);

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        ref={inputRef}
        value={input}
        onChange={handleChange}
        placeholder={placeholder}
        rows={4}
      />
      <Flex gap="0.5rem">
        <ButtonShadow
          type="button"
          size="sm"
          variant="outlined"
          onClick={close}
        >
          Close
        </ButtonShadow>
        <ButtonShadow
          type="submit"
          size="sm"
          variant="outlined"
          disabled={submitDisabled}
        >
          Confirm
        </ButtonShadow>
      </Flex>
    </Form>
  );
}
