import { useMenuVisibility } from '@reaidy/hooks';
import { ReaidyLogoInlineSvg, SideMenuSvg } from '@reaidy/icons';
import { pages } from '@reaidy/pages';
import { useBrowserNavigation } from '@xtreamsrl/react-routing';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { ReactNode } from 'react';
import { SharedEvents, useTracker } from '../analytics';

export function TopBar({ children }: { children: ReactNode }) {
  const { goTo } = useBrowserNavigation();
  const { isMenuOpen, setIsMenuOpen } = useMenuVisibility();
  const { track } = useTracker();

  return (
    <Flex
      borderBottom=" 1px solid lightgrey"
      alignItems="center"
      justifyContent="space-between"
      p="sm-4"
    >
      <Flex gap="sm-4">
        <Button
          onlyIcon={true}
          variant="plain"
          color="grey"
          icon={
            <Icon>
              <SideMenuSvg />
            </Icon>
          }
          onClick={() => {
            track(SharedEvents.HideShowSideMenu);
            setIsMenuOpen(!isMenuOpen);
          }}
        />
        {!isMenuOpen ? (
          <Flex alignSelf="center" flex="1">
            <ReaidyLogoInlineSvg />
          </Flex>
        ) : null}
      </Flex>
      <Box flex="0.7">{children}</Box>
      <Button
        onlyIcon={true}
        variant="plain"
        color="grey"
        icon={<Icon name="X" />}
        onClick={() => {
          track(SharedEvents.CloseCreationView);
          goTo(pages.home);
        }}
      />
    </Flex>
  );
}
