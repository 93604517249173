import { useMutation, useQueryClient } from '@tanstack/react-query';
import { recreateCommunication } from '../apis/recreateCommunication';
import { commDetailsQueryKeys } from '../queries/useCommDetailsQuery';
import { useGeneratePersonaDraftMutation } from './useGeneratePersonaDraftMutation';
import type { Communication } from '../types';

export function useRecreateCommMutation({
  onStreamError,
}: {
  onStreamError: (error: unknown) => void;
}) {
  const queryClient = useQueryClient();
  const { mutate: generatePersonaDraft } = useGeneratePersonaDraftMutation({
    onStreamError,
  });

  return useMutation({
    mutationFn: recreateCommunication,
    async onMutate({ commId }) {
      // Cancel related queries
      await queryClient.cancelQueries({
        queryKey: commDetailsQueryKeys.byId(commId),
      });
    },
    onSuccess(communication, { commId }) {
      // Update communication right away
      queryClient.setQueryData<Communication>(
        commDetailsQueryKeys.byId(commId),
        communication,
      );
      // Generate persona draft
      const { personaDrafts } = communication;
      generatePersonaDraft({
        commId,
        draftId: personaDrafts[0].id,
      });
    },
  });
}
