import { SVGProps } from 'react';

export const LandingPageSvg = (_props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={'communicationType-svg'}
      width="91"
      height="91"
      viewBox="0 0 91 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.25"
        y="1.5"
        width="88"
        height="88"
        rx="44"
        stroke="#5F4DDB"
        strokeWidth="2"
      />
      <path
        d="M59.6875 31.0625H36.0625C35.3663 31.0625 34.6986 31.3391 34.2063 31.8313C33.7141 32.3236 33.4375 32.9913 33.4375 33.6875V36.3125H30.8125C30.1163 36.3125 29.4486 36.5891 28.9563 37.0813C28.4641 37.5736 28.1875 38.2413 28.1875 38.9375V57.3125C28.1875 58.0087 28.4641 58.6764 28.9563 59.1687C29.4486 59.6609 30.1163 59.9375 30.8125 59.9375H54.4375C55.1337 59.9375 55.8014 59.6609 56.2937 59.1687C56.7859 58.6764 57.0625 58.0087 57.0625 57.3125V54.6875H59.6875C60.3837 54.6875 61.0514 54.4109 61.5437 53.9187C62.0359 53.4264 62.3125 52.7587 62.3125 52.0625V33.6875C62.3125 32.9913 62.0359 32.3236 61.5437 31.8313C61.0514 31.3391 60.3837 31.0625 59.6875 31.0625ZM54.4375 38.9375V41.5625H30.8125V38.9375H54.4375ZM59.6875 52.0625H57.0625V38.9375C57.0625 38.2413 56.7859 37.5736 56.2937 37.0813C55.8014 36.5891 55.1337 36.3125 54.4375 36.3125H36.0625V33.6875H59.6875V52.0625Z"
        fill="#43DB37"
      />
    </svg>
  );
};
