import { useVisualSlice } from '../slice/visualSlice';

export function useMenuVisibility(): {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
  isMenuExpanded: boolean;
  setIsMenuExpanded: (value: boolean) => void;
} {
  return {
    isMenuOpen: useVisualSlice((state) => state.isMenuOpen),
    setIsMenuOpen: useVisualSlice((state) => state.setIsMenuOpen),
    isMenuExpanded: useVisualSlice((state) => state.isMenuExpanded),
    setIsMenuExpanded: useVisualSlice((state) => state.setIsMenuExpanded),
  };
}
