import { useTranslate } from '@xtreamsrl/react-i18n';
import { useCallback } from 'react';
import type { TranslationKeys } from 'src/_shared/assets/i18n';
import { useCommPersonaDraftQuery } from '../queries/useCommPersonaDraftQuery';
import { PersonaDraft } from '../types';
import { Fieldset, Legend, RadioButton } from './CommEditingDraftList';

/**
 * @see https://www.figma.com/design/Vs6TnPuZy8bXd8p5X6SRKU/Reaidy-%7C-MVP-Prototipo?node-id=2493-9507&t=kUti0xEh58RT3Ve5-0
 */
export function CommViewingDraftList({
  commId,
  selectedDraft,
  personaDrafts,
  setSelectedDraft,
}: {
  commId: string;
  selectedDraft?: PersonaDraft;
  personaDrafts: PersonaDraft[];
  setSelectedDraft: (draft: PersonaDraft) => void;
}) {
  const t = useTranslate();
  return (
    <Fieldset>
      <Legend>
        {t('commEditing.draftList.title' satisfies TranslationKeys)}
      </Legend>
      {personaDrafts.map((draft) => (
        <ListItem
          key={draft.id}
          draftId={draft.id}
          commId={commId}
          selectedDraft={selectedDraft}
          setSelectedDraft={setSelectedDraft}
          personaDrafts={personaDrafts}
        />
      ))}
    </Fieldset>
  );
}

function ListItem({
  commId,
  draftId,
  selectedDraft,
  setSelectedDraft,
}: {
  commId: string;
  draftId: string;
  selectedDraft?: PersonaDraft;
  setSelectedDraft: (draft: PersonaDraft) => void;
  personaDrafts: PersonaDraft[];
}) {
  const { data: draft } = useCommPersonaDraftQuery({
    commId,
    draftId,
  });

  const isSelected = selectedDraft?.id === draft?.id;

  const handleClick = useCallback(() => {
    if (draft) setSelectedDraft(draft);
  }, [draft, setSelectedDraft]);

  if (!draft) return null;

  return (
    <RadioButton.Label isSelected={isSelected} onClick={handleClick}>
      <RadioButton.Title isVisible>{draft.name}</RadioButton.Title>
      <RadioButton.PersonaLabel
        personaId={draft.personaId}
        sx={{ right: '1rem' }}
      />
    </RadioButton.Label>
  );
}
