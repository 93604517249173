import { styled } from '@mui/material/styles';
import { Accordion } from '@xtreamsrl/react-ui-kit/Accordion';

export const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: `0px 10px 15px -3px hsla(0, 0%, 0%, 0.08)`,
  ':focus, :active, :focus-within, :focus-visible': {
    boxShadow: `0px 10px 15px -3px hsla(0, 0%, 0%, 0.08)`,
  },
}));

export default StyledAccordion;
