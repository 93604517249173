import { useForm } from '@xtreamsrl/react-forms';
import { useState } from 'react';
import { ModalState } from '../../_shared/components/ConfirmationWithFeedbackModal/ConfirmationWithFeedbackModal.tsx';
import { CommunicationType } from '../../contentCreation/types';
import { ComponentType, GenericTemplateComponent } from '../types';
import {
  basicInitialValues,
  getValuesWithMaxLengthParsed,
  removeDescriptionsIfEmpty,
  validationSchemas,
} from '../utils';
import { useCreateTemplateMutation } from '../mutations/useCreateTemplateMutation.ts';
import { useUpdateTemplateMutation } from '../mutations/useUpdateTemplateMutation.ts';

type CreationData = {
  onSuccess: () => void;
  initialValues?: GenericTemplateComponent;
  name?: string;
  templateId?: string;
  communicationType: CommunicationType;
};

export function useTemplateForm({
  onSuccess,
  initialValues: initialFormValues,
  name: initialName,
  templateId,
  communicationType,
}: CreationData) {
  const validationSchema = validationSchemas[communicationType];

  const initialValues =
    initialFormValues || basicInitialValues[communicationType];

  const form = useForm({ initialValues, validationSchema, mode: 'onBlur' });
  const [name, setName] = useState(initialName || 'Template');
  const [isTitle, setIsTitle] = useState(
    'mainTitle' in initialValues && initialValues.mainTitle !== undefined,
  );
  const [modalState, setModalState] = useState<ModalState>(undefined);
  const [failureCode, setFailureCode] = useState<string | undefined>();

  const createMutation = useCreateTemplateMutation();
  const updateMutation = useUpdateTemplateMutation();

  const isPending = createMutation.isPending || updateMutation.isPending;

  const submit = form.formProps.handleSubmit((values, event) => {
    event?.preventDefault();
    //here the mainTitle length is received as string due to the different validation on the field
    // in the following lines the cast cannot fail due to the form validation success
    const updatedValues = removeDescriptionsIfEmpty(
      getValuesWithMaxLengthParsed(values),
    );
    if (templateId) {
      updateMutation.mutate(
        {
          name,
          communicationType,
          template: updatedValues,
          templateId,
        },
        {
          onSuccess: () => {
            onSuccess();
          },
          onError: (error) => {
            setModalState('FEEDBACK');
            // TODO: fix this type definition
            setFailureCode((error as Error & { code: string }).code);
          },
        },
      );
    } else {
      createMutation.mutate(
        {
          name,
          communicationType,
          template: updatedValues,
        },
        {
          onSuccess: () => {
            onSuccess();
          },
          onError: (error) => {
            setModalState('FEEDBACK');
            // TODO: fix this type definition
            setFailureCode((error as Error & { code: string }).code);
          },
        },
      );
    }
  });

  function closeFailureModal() {
    setModalState(undefined);
  }

  function addTitle() {
    form.formProps.setValue('mainTitle', {
      type: ComponentType.MAIN_TITLE,
      description: undefined,
      maxLength: 20,
    });
    setIsTitle(true);
  }

  function removeTitle() {
    setIsTitle(false);
    form.formProps.setValue('mainTitle', undefined);
  }

  function resetForm(commType: CommunicationType) {
    form.formProps.reset(basicInitialValues[commType]);
  }

  return {
    modalState,
    failureCode,
    closeFailureModal,
    formProps: form.formProps,
    name,
    setName,
    isTitle,
    addTitle,
    removeTitle,
    submit,
    isPending,
    resetForm,
  };
}
