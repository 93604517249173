import { FeatureWrapper } from '@xtreamsrl/react-feature-flags';
import { FormSelect } from '@xtreamsrl/react-form-input-kit/FormSelect';
import { FormTextInput } from '@xtreamsrl/react-form-input-kit/FormTextInput';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { BaseSyntheticEvent } from 'react';
import { ConfirmationWithFeedbackModal } from '../../_shared/components/ConfirmationWithFeedbackModal';
import { Persona } from '../types';

type BriefDEMFormProps = {
  previous?: () => void;
  isNewCommunication: boolean;
  fillWithMock?: () => void;
  submit: (e?: BaseSyntheticEvent | undefined) => Promise<void>;
  isPending: boolean;
  showModal: 'CONFIRMATION' | 'FEEDBACK' | undefined;
  setShowModal: (value: 'CONFIRMATION' | 'FEEDBACK' | undefined) => void;
  personas: Persona[];
};

export function BriefDEMForm({
  previous,
  showModal,
  setShowModal,
  fillWithMock,
  submit,
  isNewCommunication,
  isPending,
  personas,
}: BriefDEMFormProps) {
  const t = useTranslate();

  // TODO: add distinction between 409 and other status codes
  const modalProps = {
    state: showModal,
    confirmationHeaderKey: 'brief.regenerateHeader',
    onConfirm: () => {
      submit().catch((err) => {
        console.error('Error regenerating communication', err);
      });
      setShowModal(undefined);
    },
    onCancel: () => {
      setShowModal(undefined);
    },
    feedbackText: {
      headerKey: 'brief.creationFailureHeader',
      contentKey: isNewCommunication
        ? 'brief.creationFailureContent'
        : 'brief.generationFailureContent',
    },
  };

  return (
    <>
      <ConfirmationWithFeedbackModal {...modalProps}>
        <Typography variant="body/sm/regular">
          {t('brief.regenerateContent')}
        </Typography>
      </ConfirmationWithFeedbackModal>
      <Flex
        direction="column"
        gap="sm-6"
        padding="sm-8"
        flex="1"
        overflow="auto"
      >
        {previous ? (
          <Box>
            <Button
              testId="previous-button"
              style={{ borderRadius: '50px' }}
              size="sm"
              variant="outlined"
              onlyIcon={true}
              icon={<Icon name="ArrowLeft" />}
              disabled={isPending}
              onClick={previous}
            />
          </Box>
        ) : null}
        <Typography variant="header/sm/bold" color="brand.9">
          {t('brief.header')}
        </Typography>
        <Typography variant="body/base/regular">
          {t('brief.indications')}
        </Typography>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            submit().catch((err) => {
              console.error('Error creating communication', err);
            });
          }}
        >
          <FormTextInput
            required={true}
            multiline={false}
            label={t('brief.name')}
            name="name"
          />
          <FormSelect
            required={true}
            label={t('brief.persona')}
            name="personaId"
            variant="filled"
          >
            {personas.map((persona) => (
              <FormSelect.Option key={persona.id} value={persona.id}>
                {persona.name}
              </FormSelect.Option>
            ))}
          </FormSelect>
          <FormTextInput
            required={true}
            multiline={true}
            label={t('brief.description')}
            name="description"
            minRows={3}
          />
          <FormTextInput
            required={false}
            multiline={true}
            label={t('brief.plusOrIncentives')}
            name="plusOrIncentives"
            minRows={3}
          />
          <Flex width="100%" gap="sm-8" justifyContent="center">
            <FeatureWrapper feature="ShowMockBriefButton" fallback={null}>
              <Button type="button" onClick={fillWithMock}>
                Mock
              </Button>
            </FeatureWrapper>
            <Button
              type="button"
              testId="create-communication"
              leftIcon={<Icon name="Sparkles" />}
              disabled={isPending}
              loading={isPending}
              onClick={() => {
                if (isNewCommunication) {
                  submit().catch((err) => {
                    console.error('Error creating communication', err);
                  });
                } else {
                  setShowModal('CONFIRMATION');
                }
              }}
            >
              {t('brief.generate')}
            </Button>
          </Flex>
        </form>
      </Flex>
    </>
  );
}
