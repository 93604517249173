import { useQuery } from '@tanstack/react-query';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { useEffect } from 'react';
import { getCommPersonaDraftQuery } from 'src/contentCreation/queries/useCommPersonaDraftQuery.ts';
import { MarkdownViewer } from '../MarkdownViewer.tsx';
import {
  HeaderStatus,
  MarkdownViewerHeader,
} from '../MarkdownViewerHeader.tsx';

const statusMap: {
  [key: string]: HeaderStatus | undefined;
} = {
  CREATED: 'loading',
  DRAFT_SAVED: 'loaded',
  ERROR: 'error',
};

export function PersonaDraftStream({
  commId,
  draftId,
}: {
  commId: string;
  draftId: string;
}) {
  const queryOptions = getCommPersonaDraftQuery({ commId, draftId });
  const { data: personaDraft } = useQuery({
    ...queryOptions,
    staleTime: Infinity, // Never refetch
  });

  useEffect(() => {
    // If the user refreshes the page during the generation process the page will be blank.
    // This is a workaround to alert the user before they leave the page.
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  const content = personaDraft?.draft ?? '';
  const status: HeaderStatus =
    statusMap[personaDraft?.status ?? 'CREATED'] ?? 'error';

  return (
    <Flex direction="column" height="100%" backgroundColor="grey.1">
      <MarkdownViewerHeader status={status} />
      <MarkdownViewer contentToDisplay={content} />
    </Flex>
  );
}
