import { theme } from '@reaidy/components';
import { FormProvider } from '@xtreamsrl/react-forms';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Divider } from '@xtreamsrl/react-ui-kit/Divider';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Select } from '@xtreamsrl/react-ui-kit/Select';
import { SelectBasic } from '@xtreamsrl/react-ui-kit/SelectBasic';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { createContext, useState } from 'react';
import { FeedbackModal } from '../../_shared/components/FeedbackModal';
import { SlateInLineEditor } from '../../_shared/components/SlateInLineEditor';
import { CommunicationType } from '../../contentCreation/types';
import { TemplatesEvents, useTracker } from '../analytics';
import { TemplateFixedItem } from '../components/TemplateFixedItem.tsx';
import { TemplatePreview } from '../components/TemplatePreview.tsx';
import { TemplateSections } from '../components/TemplateSections.tsx';
import { useTemplateForm } from '../hooks/useTemplateForm.ts';
import { ComponentType, GenericTemplateComponent } from '../types';

export const CommunicationTypeContext = createContext<CommunicationType>(
  CommunicationType.DEM,
);

export const TemplateCreator = ({
  closeCreator,
  initialValues,
  name: initialName,
  templateId,
  communicationType: externallySetCommunicationType,
}: {
  closeCreator: () => void;
  initialValues?: GenericTemplateComponent;
  templateId?: string;
  communicationType?: CommunicationType;
  name?: string;
}) => {
  const t = useTranslate();
  // if the communicationType is received as prop, it means that the user is duplicating or updating a template or the user is in the contentCreation path.
  // in this two cases the communicationType is already set and the user can't change it -> see the disabled prop in the SelectBasic component below
  // if the communicationType is not received as prop, the default value is DEM (it means that the user is creating a new template from the archive and will be able to choose the communicationType freely)
  const [communicationType, setCommunicationType] = useState<CommunicationType>(
    externallySetCommunicationType || CommunicationType.DEM,
  );
  const {
    isPending,
    modalState,
    failureCode,
    closeFailureModal,
    formProps,
    submit,
    name,
    setName,
    isTitle,
    addTitle,
    removeTitle,
    resetForm,
  } = useTemplateForm({
    onSuccess: closeCreator,
    initialValues,
    templateId,
    name: initialName,
    communicationType: communicationType,
  });
  const { track } = useTracker();
  const contentKey = `template.creation.failure.${failureCode}`;

  return (
    <CommunicationTypeContext.Provider value={communicationType}>
      <FeedbackModal
        visible={modalState === 'FEEDBACK'}
        headerKey={'template.creation.failure.header'}
        contentKey={contentKey}
        primaryAction={closeFailureModal}
      />
      <Divider direction="vertical" />
      <FormProvider {...formProps}>
        <form
          style={{ height: '100%' }}
          onSubmit={(e) => {
            e.preventDefault();
            submit().catch((error) => {
              console.error('Failed to submit form:', error);
            });
          }}
        >
          <Flex
            flex={3}
            direction="column"
            gap="sm-4"
            height="100%"
            overflow="auto"
          >
            {/* View's header */}
            <Flex
              alignItems="center"
              marginTop="md-2"
              marginBottom="sm-2"
              justifyContent="space-between"
            >
              <SlateInLineEditor
                content={name}
                saveContent={(content) => setName(content)}
              />
              <Flex gap="sm-2">
                <Button type="button" variant="outlined" onClick={closeCreator}>
                  {t('template.creation.cancel')}
                </Button>
                <Button
                  testId="create-template"
                  loading={isPending}
                  type="submit"
                  variant="filled"
                >
                  {t(
                    templateId
                      ? 'template.update.submit'
                      : 'template.creation.submit',
                  )}
                </Button>
              </Flex>
            </Flex>
            <Divider />
            <Flex direction="row" gap="sm-4">
              <TemplatePreview />
              <Divider direction="vertical" />
              <Flex
                direction="column"
                gap="sm-4"
                pl="sm-8"
                pt="sm-8"
                pb="sm-8"
                flex={2}
              >
                <Box
                  padding="sm-6"
                  pt="sm-2"
                  border={`1px solid ${theme.palette.grey[5]}`}
                  style={{ borderRadius: '10px' }}
                >
                  <Flex gap="sm-4" alignItems="center" marginBottom="sm-4">
                    <Icon fontSize="large" name="TextSelect" />
                    <Typography>
                      {t(`template.creation.communicationType`)}
                    </Typography>
                  </Flex>
                  <SelectBasic
                    name="template-type"
                    // if the communicationType is received as prop, the user can't change it. It means that the user is duplicating or updating a template or the user is in the contentCreation path
                    disabled={!!externallySetCommunicationType}
                    value={communicationType}
                    onChange={(e) => {
                      !templateId && removeTitle(); // Remove title if it's a new template
                      setCommunicationType(e.target.value as CommunicationType);
                      resetForm(e.target.value as CommunicationType);
                    }}
                  >
                    {Object.values(CommunicationType).map((type) => (
                      <Select.Option key={type} value={type}>
                        {t(`shared.communicationTypes.${type}`)}
                      </Select.Option>
                    ))}
                  </SelectBasic>
                </Box>
                {/* TODO: abstract on some info line hasSubject or similar */}
                {(communicationType === CommunicationType.DEM ||
                  communicationType === CommunicationType.PAPER_MAIL) && (
                  <>
                    <TemplateFixedItem
                      communicationType={communicationType}
                      fieldName="subject"
                      value={ComponentType.SUBJECT}
                    />
                    {!isTitle ? (
                      <Box alignSelf="center">
                        <Button
                          variant="filled"
                          color="grey"
                          onClick={() => {
                            track(TemplatesEvents.AddTemplateTitle);
                            addTitle();
                          }}
                        >
                          {t('template.creation.addTitle')}
                        </Button>
                      </Box>
                    ) : (
                      <TemplateFixedItem
                        communicationType={communicationType}
                        fieldName="mainTitle"
                        value={ComponentType.MAIN_TITLE}
                        remove={() => {
                          track(TemplatesEvents.RemoveTemplateTitle);
                          removeTitle();
                        }}
                      />
                    )}
                  </>
                )}
                <TemplateSections />
              </Flex>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    </CommunicationTypeContext.Provider>
  );
};
