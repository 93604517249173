import { RefObject, useCallback } from 'react';
import { SearchCommFilters } from '../apis/searchCommunications.ts';
import { useSearchCommsQuery } from '../queries/useSearchCommsQuery.ts';
import { useInfiniteScroll } from './useInfiniteScroll.ts';

export function useCommunicationsList<TScrollerElement extends HTMLElement>({
  filters,
  scrollerRef,
}: {
  filters?: SearchCommFilters;
  scrollerRef: RefObject<TScrollerElement>;
}) {
  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useSearchCommsQuery({
    filters,
  });

  const handleNextPage = useCallback(() => {
    fetchNextPage().catch((error) => {
      console.error('Error fetching next page:', error);
    });
  }, [fetchNextPage]);

  useInfiniteScroll({
    isLoading,
    hasNextPage,
    handleNextPage,
    scrollerRef,
  });

  const communications =
    data && data.pages.flatMap((singlePage) => singlePage.content);

  return {
    isInitialLoading: status === 'pending',
    isFetchingNextPage,
    scrollerRef,
    communications,
  };
}
