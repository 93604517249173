import fetch from '../../_shared/fetch';
import { CommunicationType } from '../../contentCreation/types';
import { GenericTemplateComponent, TemplateData } from '../types';
import { transformTemplateRequestData } from '../utils';

export function createTemplateAPI(data: {
  name: string;
  communicationType: CommunicationType;
  template: GenericTemplateComponent;
}): Promise<TemplateData> {
  const requestData = transformTemplateRequestData(data);
  return fetch.post('/templates', requestData, { extractJson: true });
}
