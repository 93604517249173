import { pages } from '@reaidy/pages';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { useBrowserNavigation } from '@xtreamsrl/react-routing';
import { Tag } from '@xtreamsrl/react-ui-kit/Tag';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Spinner } from '@xtreamsrl/react-ui-kit/Spinner';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { useCallback, useRef, useState } from 'react';
import { TranslationKeys } from 'src/_shared/assets/i18n/index.ts';
import { useUserQuery } from 'src/_shared/hooks/useUserQuery.ts';
import { SearchCommFilters } from '../apis/searchCommunications.ts';
import { useCommunicationsList } from '../hooks/useCommunicationsList.ts';
import { CommunicationElement } from '../types/index.ts';
import { CommunicationItem } from './CommunicationItem';

const Scroller = styled('div')({
  width: '1px',
  height: '10px',
});

export function CommunicationList() {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const [filters, setFilters] = useState<SearchCommFilters>({});

  const {
    isInitialLoading,
    communications = [],
    isFetchingNextPage,
  } = useCommunicationsList({
    filters,
    scrollerRef,
  });

  if (isInitialLoading) {
    return <CommLoading />;
  }
  if (communications.length > 0) {
    return (
      <>
        <Filters filters={filters} setFilters={setFilters} />
        <TableHeader />
        <TableRows
          communications={communications}
          isFetchingNextPage={isFetchingNextPage}
        />
        <Scroller ref={scrollerRef} />
      </>
    );
  }
  return <CreateFirstComm />;
}

function Filters({
  filters,
  setFilters,
}: {
  filters: SearchCommFilters;
  setFilters: (filters: SearchCommFilters) => void;
}) {
  const t = useTranslate();
  const { data: { id: userId } = {} } = useUserQuery();

  const userFilterOn = filters.userId === userId;
  const toggleUserFilter = useCallback(() => {
    if (userFilterOn) {
      setFilters({});
    } else {
      setFilters({ userId });
    }
  }, [setFilters, userFilterOn, userId]);

  if (userId === undefined) {
    return null;
  }
  return (
    <Flex gap="sm-4" alignItems="center" mb="1rem" ml="1rem">
      <Typography variant="body/sm/semibold">
        {t('archive.filters' satisfies TranslationKeys)}
      </Typography>
      <Tag
        size="md"
        icon={userFilterOn ? <Icon name="X" /> : undefined}
        text={t('archive.filterBy.createdByMe' satisfies TranslationKeys)}
        variant="outlined"
        checked={userFilterOn}
        onClick={toggleUserFilter}
      />
    </Flex>
  );
}

function CommLoading() {
  return (
    <Flex
      direction="column"
      height="100%"
      alignItems="center"
      justifyContent="center"
      gap="sm-6"
    >
      <Spinner state="indeterminate" size={32} />
    </Flex>
  );
}

function TableHeader() {
  const t = useTranslate();
  return (
    <Flex width="100%" gap="md-4" paddingRight="sm-8" paddingLeft="sm-8">
      <Box flex="2">
        <Typography variant="body/sm/semibold">{t('archive.date')}</Typography>
      </Box>
      <Box flex="8">
        <Typography variant="body/sm/semibold">
          {t('archive.project')}
        </Typography>
      </Box>
      <Box flex="3">
        <Typography textAlign="center" variant="body/sm/semibold">
          {t('archive.type')}
        </Typography>
      </Box>
      <Box flex="1" />
    </Flex>
  );
}

function TableRows({
  communications,
  isFetchingNextPage,
}: {
  communications: CommunicationElement[];
  isFetchingNextPage: boolean;
}) {
  return (
    <Flex direction="column" width="100%" gap="sm-4" alignItems="stretch">
      {communications?.map((communication) => {
        return (
          <CommunicationItem
            key={communication.id}
            communication={communication}
          />
        );
      })}
      <Box overflow="hidden">
        {isFetchingNextPage && <Spinner state="indeterminate" />}
      </Box>
    </Flex>
  );
}

function CreateFirstComm() {
  const t = useTranslate();
  const { goTo } = useBrowserNavigation();

  return (
    <Flex
      direction="column"
      height="100%"
      alignItems="center"
      justifyContent="center"
      gap="sm-6"
    >
      <Icon name="Frown" fontSize="80px" />
      <Typography variant="body/base/regular">
        {t('archive.noCommunications')}
      </Typography>
      <Button onClick={() => goTo(pages.contentCreation)}>
        {t('archive.createNow')}
      </Button>
    </Flex>
  );
}
