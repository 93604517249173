import { useTranslate } from '@xtreamsrl/react-i18n';
import { EditorHeader } from './EditorHeader';
import { EditorToolbar } from './EditorToolbar';
import { TranslationKeys } from 'src/_shared/assets/i18n';
import { EditorStatus } from './EditorStatus';

export function MarkdownEditorHeader({ editSaved }: { editSaved: boolean }) {
  const t = useTranslate();

  return (
    <EditorHeader>
      <EditorStatus pinColor={editSaved ? 'green.8' : 'red.9'}>
        {editSaved
          ? t('editing.saved' satisfies TranslationKeys)
          : t('editing.unsaved' satisfies TranslationKeys)}
      </EditorStatus>
      <EditorToolbar />
    </EditorHeader>
  );
}
