import fetch from '../../_shared/fetch';
import { PersonaDraft } from '../types';

export async function updatePersonaDraft({
  commId,
  draftId,
  update,
}: {
  commId: string;
  draftId: string;
  update: Partial<PersonaDraft>;
}) {
  const result: PersonaDraft = await fetch.patch(
    `/communications/${commId}/persona-drafts/${draftId}`,
    update,
    false, // extractJson
  );
  return result;
}
