import fetch from '../../_shared/fetch';
import { CommunicationType } from '../../contentCreation/types';
import { GenericTemplateComponent, TemplateData } from '../types';
import { transformTemplateRequestData } from '../utils';

export function updateTemplate(data: {
  templateId: string;
  name: string;
  communicationType: CommunicationType;
  template: GenericTemplateComponent;
}): Promise<TemplateData> {
  const { templateId, ...dataToTransform } = data;
  const requestData = transformTemplateRequestData(dataToTransform);

  return fetch.put(`/templates/${templateId}`, requestData, {
    extractJson: true,
  });
}
