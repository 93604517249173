import { config } from '@xtreamsrl/react-config';
export async function enableMocking() {
  if (config.enableMock === false) {
    return;
  }
  const { worker } = await import('./browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}
