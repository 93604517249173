import {
  ArchiveSvg,
  CollapseSvg,
  CreateSvg,
  ExpandSvg,
  HomeSvg,
  ReaidyLogoSvg,
  TemplatesSvg,
} from '@reaidy/icons';
import { pages } from '@reaidy/pages';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { useLogout } from '../../login/hooks/useLogout.ts';
import { SharedEvents, useTracker } from '../analytics';
import { useSideMenu } from '../hooks/useSideMenu';
import { useUser } from '../hooks/useUser.ts';
import { SideMenuButton } from './SideMenuButton';

const menuItems = [
  {
    icon: (
      <Icon>
        <HomeSvg />
      </Icon>
    ),
    label: 'sideMenu.home',
    page: pages.home,
  },
  {
    icon: (
      <Icon>
        <CreateSvg />
      </Icon>
    ),
    label: 'sideMenu.write',
    page: pages.contentCreation,
  },
  {
    icon: (
      <Icon>
        {' '}
        <ArchiveSvg />{' '}
      </Icon>
    ),
    label: 'sideMenu.archive',
    page: pages.archive,
  },
  {
    icon: (
      <Icon>
        <TemplatesSvg />
      </Icon>
    ),
    label: 'sideMenu.templates',
    page: pages.templates,
  },
];

export function SideMenu() {
  const t = useTranslate();
  const { goTo, isMenuExpanded, setIsMenuExpanded, pathname } = useSideMenu();
  const { logout } = useLogout();
  const nickname = useUser();
  const { track } = useTracker();

  return (
    <Flex
      direction="column"
      backgroundColor="#394455"
      justifyContent="space-between"
      gap="md-8"
      p="sm-5"
      flexGrow="0"
      height="100vh"
    >
      <Box alignSelf="center">
        <ReaidyLogoSvg />
      </Box>
      <Flex direction="column" gap="sm-4">
        {menuItems.map((item) => (
          <SideMenuButton
            key={item.label}
            testId={item.label}
            icon={item.icon}
            label={t(item.label)}
            justifyContent="flex-start"
            expanded={isMenuExpanded}
            active={pathname === item.page}
            onClick={() => {
              goTo(item.page);
            }}
          />
        ))}
      </Flex>
      <Flex direction="column" gap="sm-4">
        <Flex direction="column" alignItems="center" gap="sm-3">
          <Icon name="Diamond" />
          {isMenuExpanded ? (
            <Typography variant="body/sm/regular" color="grey.8">
              {nickname}
            </Typography>
          ) : null}
        </Flex>
        <SideMenuButton
          icon={
            isMenuExpanded ? (
              <Icon>
                <CollapseSvg />
              </Icon>
            ) : (
              <Icon>
                <ExpandSvg />
              </Icon>
            )
          }
          label={t('sideMenu.reduce')}
          expanded={isMenuExpanded}
          active={false}
          onClick={() => {
            track(
              isMenuExpanded
                ? SharedEvents.ReduceSideMenu
                : SharedEvents.ExpandSideMenu,
            );
            setIsMenuExpanded(!isMenuExpanded);
          }}
        />
        <Button
          variant="plain"
          size="md"
          onlyIcon={true}
          color="grey"
          icon={<Icon name="LogOut" />}
          onClick={() => logout()}
        ></Button>
      </Flex>
    </Flex>
  );
}
