import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { useFieldArray } from 'react-hook-form';

import { GenericTemplateComponent } from '../types';
import { TemplateSectionField } from './TemplateSectionField.tsx';

export function TemplateSectionFields({
  sectionIndex,
}: {
  sectionIndex: number;
}) {
  const {
    fields: sectionFields,
    insert: insertField,
    remove: removeField,
  } = useFieldArray<GenericTemplateComponent, `sections.${number}.components`>({
    name: `sections.${sectionIndex}.components` as const,
  });

  return (
    <Flex direction="column" gap="sm-2">
      {sectionFields.map((field, indexField) => (
        <TemplateSectionField
          key={field.id}
          sectionIndex={sectionIndex}
          index={indexField}
          isFirstAndOnly={sectionFields.length === 1}
          insert={insertField}
          remove={removeField}
        />
      ))}
    </Flex>
  );
}
