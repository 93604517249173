import { useCallback, useState } from 'react';
import { FollowupActions, FollowupType } from './ActionMenu/FollowupActions';
import { PrimaryActions } from './ActionMenu/PrimaryActions';
import { Menu, MenuPosition } from './Menu';

type ActionMenuProps = {
  menuPosition?: MenuPosition;
  onCopy: () => void;
  onPaste: () => void;
  onCut: () => void;
  onModify: (directions: string) => void;
  onEnhance: () => void;
  onDelete: () => void;
  onShorten: () => void;
  onExpand: () => void;
  onRephrase: () => void;
};

export function ActionMenu({
  menuPosition,
  onCopy,
  onPaste,
  onCut,
  onModify,
  onEnhance,
  onDelete,
  onShorten,
  onExpand,
  onRephrase,
}: ActionMenuProps) {
  const [followUp, setFollowUp] = useState<FollowupType | undefined>();
  const closeFollowup = useCallback(() => setFollowUp(undefined), []);

  return (
    <Menu position={menuPosition}>
      {followUp ? (
        <FollowupActions
          type={followUp}
          onModify={onModify}
          close={closeFollowup}
        />
      ) : (
        <PrimaryActions
          setFollowUp={setFollowUp}
          onCopy={onCopy}
          onPaste={onPaste}
          onCut={onCut}
          onEnhance={onEnhance}
          onShorten={onShorten}
          onExpand={onExpand}
          onRephrase={onRephrase}
          onDelete={onDelete}
        />
      )}
    </Menu>
  );
}
