import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Communication } from '../types';
import { Heading, SubHeading } from './CommEditingHeader';

export function CommViewingHeader({
  communication,
}: {
  communication: Communication;
}) {
  const commType = communication.details.type;
  return (
    <Flex width="100%" justifyContent="space-between" alignItems="center">
      <Flex
        gap={0}
        direction="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        {commType && <SubHeading>{commType}</SubHeading>}
        <Heading>{communication.name}</Heading>
      </Flex>
    </Flex>
  );
}
