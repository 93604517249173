import { useMenuVisibility } from '@reaidy/hooks';
import { pages } from '@reaidy/pages';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { SharedEvents, useTracker } from './_shared/analytics';
import { matchPathToEvent } from './_shared/analytics/pageViews.ts';
import { SideMenu } from './_shared/components/SideMenu';
import { Archive } from './archive/views/Archive';
import { CommViewing } from './contentCreation/views/CommViewing.tsx';
import { ContentCreator } from './contentCreation/views/ContentCreator';
import { ContentEditor } from './contentCreation/views/ContentEditor';
import { Home } from './dashboard/views/Home';
import { TemplatesArchive } from './templates/views/TemplatesArchive.tsx';

export function Layout() {
  const { isMenuOpen } = useMenuVisibility();
  const { pathname } = useLocation();
  const { track } = useTracker();

  useEffect(() => {
    const props = matchPathToEvent(pathname);
    if (props) {
      track(SharedEvents.PageView, props);
    }
  }, [track, pathname]);

  return (
    <Flex width="100vw" height="100vh">
      {isMenuOpen ? <SideMenu /> : null}
      <Routes>
        <Route path={pages.home} element={<Home />} />
        <Route path={pages.contentCreation} element={<ContentCreator />} />
        <Route path={pages.archive} element={<Archive />} />
        <Route path={pages.templates} element={<TemplatesArchive />} />
        <Route path={pages.edit} element={<ContentEditor />} />
        <Route path={pages.view} element={<CommViewing />} />
        <Route path="*" element={<Navigate to={pages.home} replace={true} />} />
      </Routes>
    </Flex>
  );
}
