import { RefObject, useCallback, useEffect } from 'react';

function useIntersection<TElement extends HTMLElement>({
  elementRef,
  onIntersect: onIntersection,
}: {
  elementRef: RefObject<TElement>;
  onIntersect: () => void;
}) {
  useEffect(() => {
    const scroller = elementRef.current;
    if (!scroller) return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) onIntersection();
    });

    observer.observe(scroller);
    return () => {
      observer.disconnect();
    };
  }, [onIntersection, elementRef]);

  return {
    elementRef,
  };
}

export function useInfiniteScroll<TElement extends HTMLElement>({
  isLoading,
  hasNextPage,
  handleNextPage,
  scrollerRef,
}: {
  isLoading: boolean;
  hasNextPage: boolean;
  handleNextPage: () => void;
  scrollerRef: RefObject<TElement>;
}) {
  const handleIntersect = useCallback(() => {
    if (isLoading || !hasNextPage) return;
    handleNextPage();
  }, [isLoading, hasNextPage, handleNextPage]);

  return useIntersection({
    elementRef: scrollerRef,
    onIntersect: handleIntersect,
  });
}
