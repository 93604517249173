import { Icon, LucideIcon } from '@xtreamsrl/react-ui-kit/Icon';
import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { Modal } from '@xtreamsrl/react-ui-kit/Modal';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { Select } from '@xtreamsrl/react-ui-kit/Select';
import { Persona } from '../types';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { TextInput } from '@xtreamsrl/react-ui-kit/TextInput';
import {
  ChangeEventHandler,
  startTransition,
  useCallback,
  useState,
} from 'react';
import { usePersonaListQuery } from '../queries/usePersonaListQuery';
import { styled } from '@xtreamsrl/react-ui-kit/styles';

// FIXME: Modal.Header should allow ReactNode as children
const castAsString = (node: ReactNode) => node as string;

export function CommEditingModal(
  props: PropsWithChildren<{
    title: ReactNode;
    iconName: LucideIcon['name'];
    subtitle: ReactNode;
    paragraph: ReactNode;
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode | ReactNode[];
  }>,
) {
  const { title, subtitle, paragraph, isOpen, onClose, children, iconName } =
    props;
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>
        {castAsString(<Header title={title} iconName={iconName} />)}
      </Modal.Header>
      <Modal.Content>
        <Content subtitle={subtitle} paragraph={paragraph}>
          {children}
        </Content>
      </Modal.Content>
    </Modal>
  );
}

export const Emphasis = styled('em')(({ theme }) => ({
  color: theme.palette.brand['10'],
}));

function Header({
  title,
  iconName,
}: {
  title: ReactNode;
  iconName: LucideIcon['name'];
}) {
  return (
    <Flex alignItems="center" gap={'.5rem'}>
      <Icon name={iconName} color="brand.10" fontSize="1.25em" />
      <Typography variant="body/xxl/semibold" color="brand.10">
        {title}
      </Typography>
    </Flex>
  );
}

function Content({
  children,
  paragraph,
  subtitle,
}: PropsWithChildren<{
  subtitle: ReactNode;
  paragraph: ReactNode;
}>) {
  return (
    <Modal.Content>
      <Flex direction="column" gap=".35rem" mt={'0.5rem'} mb={'1rem'}>
        <Typography variant="body/base/bold">{subtitle}</Typography>
        <Typography>{paragraph}</Typography>
      </Flex>
      {children}
    </Modal.Content>
  );
}

export function PersonaSelector({
  selectedPersona,
  setSelectedPersona,
  label,
}: {
  selectedPersona: Persona | undefined;
  setSelectedPersona: (persona: Persona) => void;
  label: string;
}) {
  const { data: personas = [] } = usePersonaListQuery();
  const personaOptions = useMemo(
    () =>
      personas.map((persona) => ({
        label: persona.name,
        value: persona.id,
      })),
    [personas],
  );

  const handleChange = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    (e) => {
      const persona = personas.find((p) => p.id === e.target.value);
      if (!persona) {
        console.error('Persona not found');
        return;
      }
      setSelectedPersona(persona);
    },
    [personas, setSelectedPersona],
  );

  return (
    <Select
      name="persona"
      label={label}
      value={selectedPersona?.id ?? ''}
      // @ts-expect-error FIXME: the type of onChange is wrong
      onChange={handleChange}
    >
      {personaOptions.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export function PromptInput({
  prompt,
  setPrompt,
  inputRef,
  label,
}: {
  prompt: string;
  setPrompt: (prompt: string) => void;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  label: string;
}) {
  const [value, setValue] = useState(prompt);

  const onChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    (e) => {
      setValue(e.target.value);
      startTransition(() => setPrompt(e.target.value));
    },
    [setPrompt],
  );

  return (
    <TextInput
      inputRef={inputRef}
      label={label}
      name="prompt"
      minRows={3}
      // @ts-expect-error FIXME: maxRows should be allowed
      maxRows={6}
      multiline
      value={value}
      onChange={onChange}
    />
  );
}

export function SubmitButton({
  children,
  isLoading,
  isDisabled,
}: {
  children: string;
  isLoading?: boolean;
  isDisabled?: boolean;
}) {
  return (
    <Flex justifyContent="center">
      <Button
        variant="filled"
        color="green"
        type="submit"
        loading={isLoading}
        disabled={isDisabled}
      >
        {children}
      </Button>
    </Flex>
  );
}
