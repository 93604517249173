import { useDateTimeFormat, useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { SidePanel } from '@xtreamsrl/react-ui-kit/SidePanel';
import { Tag } from '@xtreamsrl/react-ui-kit/Tag';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { useState } from 'react';
import { DateTimeFormat } from '../../_shared/assets/i18n';
import {
  communicationsTypes,
  CommunicationType,
} from '../../contentCreation/types';
import { TemplatesEvents, useTracker } from '../analytics';
import { ColumnHeader } from '../components/ColumnHeader.tsx';
import { TemplatesList } from '../components/TemplatesList.tsx';
import { ColumnName, SortDirection } from '../types';
import { filterMapByDefinedValues, findNextSortState } from '../utils';
import { TemplateCreator } from './TemplateCreator.tsx';

const initialSortDirection: Record<ColumnName, SortDirection> = {
  [ColumnName.createdAt]: undefined,
  [ColumnName.name]: undefined,
  [ColumnName.communicationType]: undefined,
};

export function TemplatesArchive() {
  const t = useTranslate();
  const dtf = useDateTimeFormat();
  const date = new Date();
  const [isCreatorOpen, setIsCreatorOpen] = useState(false);
  const [sortDirections, setSortDirections] =
    useState<Record<ColumnName, SortDirection>>(initialSortDirection);
  const [communicationTypeFilter, setCommunicationTypeFilter] = useState<
    CommunicationType | undefined
  >();
  const { track } = useTracker();

  const updateSortDirection = (columnName: ColumnName) => {
    setSortDirections((prevState) => ({
      ...prevState,
      [columnName]: findNextSortState(prevState[columnName]),
    }));
  };

  const dateSection = (
    <Flex alignItems="center" alignSelf="flex-end" gap="sm-4">
      <Icon name="Clock3" color="grey.12" />
      <Typography variant="body/sm/regular">
        {dtf(date, DateTimeFormat.FULL_DATE_TIME)}
      </Typography>
    </Flex>
  );

  return (
    <>
      <Flex
        direction="column"
        width="100%"
        padding="sm-8"
        gap="sm-4"
        overflow="auto"
      >
        {dateSection}
        <Typography variant="body/xl/bold" color="brand.9">
          {t('templatesArchive.header')}
        </Typography>
        <Typography variant="body/lg/regular" color="grey.12">
          {t('templatesArchive.content')}
        </Typography>

        <Flex gap="sm-4" alignSelf="flex-end" marginBottom="sm-4">
          <Button
            testId={'create-new-template-in-templates-archive'}
            leftIcon={<Icon name="Pencil" />}
            onClick={() => {
              track(TemplatesEvents.StartTemplateCreate);
              setIsCreatorOpen(true);
            }}
          >
            {t('templatesArchive.newTemplate')}
          </Button>
        </Flex>

        <Flex p="sm-2">
          <Box
            height="100%"
            width="8px"
            backgroundColor="brand.9"
            style={{
              borderBottomLeftRadius: '10px',
              borderTopLeftRadius: '10px',
            }}
          />
          <Box
            pl="sm-2"
            backgroundColor="brand.4"
            style={{
              borderBottomRightRadius: '10px',
              borderTopRightRadius: '10px',
            }}
          >
            <Typography variant="body/sm/regular" color="grey.12">
              {t('templatesArchive.info')}
            </Typography>
          </Box>
        </Flex>

        <Flex gap="sm-2" alignItems="center">
          <Typography variant="body/sm/semibold">
            {t('templatesArchive.filterByType')}
          </Typography>
          {/* todo: replace 'isImplemented' with feature flag.
                Now it is better to keep it this way otherwise using dev and selecting PaperMail,
                 all the other unimplemented types are shown as selected filters */}
          {communicationsTypes
            .filter((t) => t.isImplemented)
            .map((type) => (
              <Tag
                size="sm"
                icon={
                  type.code === communicationTypeFilter ? (
                    <Icon name="X" />
                  ) : undefined
                }
                text={t(type.text)}
                key={type.code}
                variant="outlined"
                checked={type.code === communicationTypeFilter}
                onClick={() => {
                  if (type.code !== communicationTypeFilter) {
                    setCommunicationTypeFilter(type.code);
                    track(TemplatesEvents.FilterTemplates, {
                      communicationType: type.code,
                    });
                  } else setCommunicationTypeFilter(undefined);
                }}
              />
            ))}
        </Flex>
        <Flex width="100%" gap="md-4" paddingRight="md-1" paddingLeft="md-1">
          <ColumnHeader
            flex="2"
            columnName={ColumnName.createdAt}
            updateSortDirection={updateSortDirection}
            sortDirection={sortDirections[ColumnName.createdAt]}
          />
          <ColumnHeader
            flex="8"
            columnName={ColumnName.name}
            updateSortDirection={updateSortDirection}
            sortDirection={sortDirections[ColumnName.name]}
          />
          <ColumnHeader
            flex="2"
            columnName={ColumnName.communicationType}
            updateSortDirection={updateSortDirection}
            sortDirection={sortDirections[ColumnName.communicationType]}
            justifyContent="center"
          />
          <Box flex="1" />
          <Box flex="1" />
        </Flex>
        <TemplatesList
          sortBy={filterMapByDefinedValues(sortDirections)}
          communicationType={communicationTypeFilter}
          createNewTemplate={() => setIsCreatorOpen(true)}
        />
      </Flex>
      <SidePanel
        open={isCreatorOpen}
        onClose={() => setIsCreatorOpen(false)}
        anchor="right"
      >
        <SidePanel.Content width="80vw">
          <TemplateCreator closeCreator={() => setIsCreatorOpen(false)} />
        </SidePanel.Content>
      </SidePanel>
    </>
  );
}
