import ButtonGroup from '@mui/material/ButtonGroup';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Divider } from '@xtreamsrl/react-ui-kit/Divider';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { useCallback } from 'react';
import { TranslationKeys } from 'src/_shared/assets/i18n';
import { Panel } from '../Panel';
import { FollowupType } from './FollowupActions';

type PrimaryActionsProps = {
  onCopy: () => void;
  onPaste: () => void;
  onCut: () => void;
  onEnhance: () => void;
  onDelete: () => void;
  onShorten: () => void;
  onExpand: () => void;
  onRephrase: () => void;
  setFollowUp: (followUp: FollowupType) => void;
};

export function PrimaryActions(props: PrimaryActionsProps) {
  const t = useTranslate();
  const { setFollowUp } = props;

  const onModify = useCallback(() => setFollowUp('modify'), [setFollowUp]);

  return (
    <Panel>
      <ButtonGroup>
        <Button
          size="sm"
          onlyIcon
          variant="plain"
          onClick={props.onCopy}
          icon={<Icon fontSize={20} name="Copy" />}
        />
        <Button
          size="sm"
          onlyIcon
          variant="plain"
          onClick={props.onPaste}
          icon={<Icon name="Clipboard" />}
        />
        <Button
          size="sm"
          onlyIcon
          variant="plain"
          onClick={props.onCut}
          icon={<Icon name="Scissors" />}
        />
        <Divider direction="vertical" />
        <Button
          size="sm"
          variant="plain"
          onClick={onModify}
          leftIcon={<Icon name="Sparkles" />}
        >
          {t('editing.modify' satisfies TranslationKeys)}
        </Button>
        <Button
          size="sm"
          variant="plain"
          onClick={props.onEnhance}
          leftIcon={<Icon name="TrendingUp" />}
        >
          {t('editing.improve' satisfies TranslationKeys)}
        </Button>
        <Button
          size="sm"
          variant="plain"
          onClick={props.onShorten}
          leftIcon={<Icon name="ArrowUpWideNarrow" />}
        >
          {t('editing.shorten' satisfies TranslationKeys)}
        </Button>
        <Button
          size="sm"
          variant="plain"
          onClick={props.onExpand}
          leftIcon={<Icon name="ArrowDownNarrowWide" />}
        >
          {t('editing.expand' satisfies TranslationKeys)}
        </Button>
        <Button
          size="sm"
          variant="plain"
          onClick={props.onRephrase}
          leftIcon={<Icon name="Repeat" />}
        >
          {t('editing.rephrase' satisfies TranslationKeys)}
        </Button>
        <Divider direction="vertical" />
        <Button
          size="sm"
          onlyIcon
          variant="plain"
          onClick={props.onDelete}
          icon={<Icon name="Trash2" />}
        />
      </ButtonGroup>
    </Panel>
  );
}
