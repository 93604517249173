import { AnalyticsEvent } from '@xtreamsrl/react-analytics';
import { TemplatesEvents } from './events.ts';

interface TemplateRelatedEventProps {
  templateId: string;
}

interface CommunicationTypeRelatedEventProps {
  communicationType: string;
}

export const templatesEventsMapping = {
  [TemplatesEvents.AddTemplateTitle]: () => ({
    type: 'addTemplateTitle',
  }),
  [TemplatesEvents.RemoveTemplateTitle]: () => ({
    type: 'removeTemplateTitle',
  }),
  [TemplatesEvents.AddTemplateSection]: () => ({
    type: 'addTemplateSection',
  }),
  [TemplatesEvents.RemoveTemplateSection]: () => ({
    type: 'removeTemplateSection',
  }),
  [TemplatesEvents.StartTemplateCreate]: () => ({
    type: 'startTemplateCreate',
  }),
  [TemplatesEvents.StartTemplateEdit]: (props: TemplateRelatedEventProps) => ({
    type: 'startTemplateEdit',
    props,
  }),
  [TemplatesEvents.StartTemplateDuplicate]: (
    props: TemplateRelatedEventProps,
  ) => ({
    type: 'startTemplateDuplicate',
    props,
  }),
  [TemplatesEvents.OpenTemplatePreview]: (
    props: TemplateRelatedEventProps,
  ) => ({
    type: 'openTemplatePreview',
    props,
  }),
  [TemplatesEvents.FilterTemplates]: (
    props: CommunicationTypeRelatedEventProps,
  ) => ({
    type: 'filterTemplates',
    props,
  }),
  [TemplatesEvents.StartTemplateDelete]: (
    props: TemplateRelatedEventProps,
  ) => ({
    type: 'startTemplateDelete',
    props,
  }),
  // eslint-disable-next-line
} satisfies Record<TemplatesEvents, (...props: any[]) => AnalyticsEvent>;
