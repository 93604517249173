import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deletePersonaDraft } from '../apis/deletePersonaDraft';
import { commDetailsQueryKeys } from '../queries/useCommDetailsQuery';
import { Communication, PersonaDraft } from '../types';
import { commPersonaDraftQueryKeys } from '../queries/useCommPersonaDraftQuery';

export function useDeletePersonaDraftMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deletePersonaDraft,
    onMutate: async ({ commId, draftId }) => {
      // Cancel any outgoing queries for the comm details
      const commQueryKey = commDetailsQueryKeys.byId(commId);
      const draftQueryKey = commPersonaDraftQueryKeys.byCommIdAndDraftId(
        commId,
        draftId,
      );

      await Promise.allSettled([
        queryClient.cancelQueries({
          queryKey: commQueryKey,
        }),
        queryClient.cancelQueries({
          queryKey: draftQueryKey,
        }),
      ]);

      // Snapshot the previous values
      const communication =
        queryClient.getQueryData<Communication>(commQueryKey);
      const personaDraft =
        queryClient.getQueryData<PersonaDraft>(draftQueryKey);

      // Optimistically update the cache
      queryClient.setQueryData<Communication>(commQueryKey, {
        ...communication!,
        personaDrafts: communication!.personaDrafts.filter(
          (draft) => draft.id !== draftId,
        ),
      });
      queryClient.removeQueries({
        queryKey: draftQueryKey,
        exact: true,
      });

      // Return context to rollback the cache update
      return { communication, personaDraft, commQueryKey, draftQueryKey };
    },
    onError: (error, _, context) => {
      console.error(error);
      if (!context) return;
      // Rollback the cache update
      queryClient.setQueryData(context.commQueryKey, context.communication);
      queryClient.setQueryData(context.draftQueryKey, context.personaDraft);
    },
  });
}
