import { Banner } from '@xtreamsrl/react-ui-kit/Banner';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Modal } from '@xtreamsrl/react-ui-kit/Modal';
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { slateToRemark } from 'remark-slate-transformer';
import { slateToMarkdownProcessor } from 'src/_shared/utils/slate.tsx';
import { useUpdatePersonaDraftMutation } from 'src/contentCreation/mutations/useUpdatePersonaCommMutation.ts';
import { useCommPersonaDraftQuery } from 'src/contentCreation/queries/useCommPersonaDraftQuery.ts';
import { MarkdownEditorNode, MarkdownEditorRef } from '../MarkdownEditor';
import DraftEditor from './DraftEditor.tsx';
import debounce from 'lodash.debounce';

export function PersonaDraftEditor({
  commId,
  draftId,
}: {
  commId: string;
  draftId: string;
}) {
  const [_, startTransition] = useTransition();
  const editorRef = React.useRef<MarkdownEditorRef>(null);
  const [latestDraft, setLatestDraft] = useState<string>();

  const {
    // Reference stable variables.
    // Safe to include in depencency arrays
    isPending: isUpdating,
    mutateAsync: updateDraftAsync,
    mutate: updateDraft,
  } = useUpdatePersonaDraftMutation();

  const { data: personaDraft } = useCommPersonaDraftQuery({
    commId,
    draftId,
  });
  const isOptimistic = personaDraft?._optimistic ?? false;
  const savedDraft = personaDraft?.draft ?? '';
  const isEditSaved = latestDraft
    ? isOptimistic
      ? false
      : latestDraft === savedDraft
    : true;

  const unsavedDraftRef = useRef<string | null>(null);
  const saveDraft = useCallback(
    (draft: string) => {
      // Make sure only one mutation is executed at a time
      if (isUpdating) {
        // console.log('Save postponed');
        // Save the draft in a ref so that we can reuse it later
        unsavedDraftRef.current = draft;
      } else {
        // console.log('Saving draft');
        // Send the draft to the server
        updateDraftAsync({
          commId,
          draftId,
          update: { draft },
        })
          // .then(() => console.log('Draft saved'))
          .catch((error) => {
            console.error('Failed to save draft', error);
          })
          .finally(() => {
            // Do not call React.setState because the component may be unmounted
            if (unsavedDraftRef.current) {
              // console.log('Save was postponed, retrying now');
              // Reset the ref and recursively call saveDraft
              const draft = unsavedDraftRef.current;
              unsavedDraftRef.current = null;
              saveDraft(draft);
            }
          });
      }
    },
    [commId, draftId, updateDraftAsync, isUpdating],
  );

  const debouncedSaveDraft = useMemo(
    () => debounce(saveDraft, 1000),
    [saveDraft],
  );

  const handleSave = useCallback(
    (nodes: MarkdownEditorNode[]) => {
      // Convert Slate nodes to stringified markdown
      const remark = slateToRemark(nodes);
      const newDraft = slateToMarkdownProcessor.stringify(remark);

      // Debounce the save operation to avoid sending too many requests
      debouncedSaveDraft(newDraft);

      // Update the UI to show the saved status
      startTransition(() => setLatestDraft(newDraft));
    },
    [debouncedSaveDraft, startTransition],
  );

  const handleReset = useCallback(() => {
    // This gets called when there is an error in the text submitted
    // This can happen when markdown syntax is invalid like "##\n\n##\n\n##"
    updateDraft({
      commId,
      draftId,
      update: { draft: '' }, // TODO: what do we do here?
    });
  }, [commId, draftId, updateDraft]);

  return (
    <Flex direction="column" height="100%" backgroundColor="grey.1">
      <ErrorBoundary fallbackRender={fallbackRender} onReset={handleReset}>
        {savedDraft && ( // Initial content should be ready before mounting the editor
          <DraftEditor
            key={draftId} // Remount the editor when the draft changes
            ref={editorRef}
            handleSave={handleSave}
            initialContent={savedDraft}
            isEditSaved={isEditSaved}
            draftId={draftId}
            commId={commId}
          />
        )}
      </ErrorBoundary>
    </Flex>
  );
}

function fallbackRender({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  return (
    <Modal open onClose={resetErrorBoundary}>
      <Modal.Header>Error</Modal.Header>
      <Modal.Content>
        Cound not render the content. Please reset to continue editing.
        <Banner>
          <Banner.Message>{error.message}</Banner.Message>
        </Banner>
      </Modal.Content>
      <Modal.Footer type="fullwidth">
        <Modal.PrimaryAction onClick={resetErrorBoundary}>
          Reset
        </Modal.PrimaryAction>
      </Modal.Footer>
    </Modal>
  );
}
