import { useDateTimeFormat, useTranslate } from '@xtreamsrl/react-i18n';
import { Accordion } from '@xtreamsrl/react-ui-kit/Accordion';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Modal } from '@xtreamsrl/react-ui-kit/Modal';
import { SidePanel } from '@xtreamsrl/react-ui-kit/SidePanel';
import { Tooltip } from '@xtreamsrl/react-ui-kit/Tooltip';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { DateTimeFormat } from '../../_shared/assets/i18n';
import { ConfirmationWithFeedbackModal } from '../../_shared/components/ConfirmationWithFeedbackModal';
import { LoadingSection } from '../../_shared/components/LoadingSection';
import StyledAccordion from '../../_shared/components/StyledAccordion.tsx';
import { MarkdownViewer } from '../../contentCreation/components/MarkdownViewer.tsx';
import { TemplatesEvents, useTracker } from '../analytics';
import { Mode, useTemplateActions } from '../hooks/useTemplateActions.ts';
import { Template } from '../types';
import { generateLoremIpsumMarkdown } from '../utils';
import { TemplateCreator } from '../views/TemplateCreator.tsx';

export function TemplateItem({ template }: { template: Template }) {
  const dtf = useDateTimeFormat();
  const t = useTranslate();
  const { track } = useTracker();
  const { mode, setMode, templateDetails, deleteTemplate } =
    useTemplateActions(template);

  const openPanel = mode === Mode.Edit || mode === Mode.Clone;
  const previewContent = templateDetails.isLoading ? (
    <LoadingSection />
  ) : templateDetails.data ? (
    <MarkdownViewer
      contentToDisplay={generateLoremIpsumMarkdown(
        templateDetails.data.structure,
      )}
    />
  ) : (
    <Typography variant="body/sm/regular">
      {t('templatesArchive.preview.error')}
    </Typography>
  );

  return (
    <>
      <Modal
        maxWidthSize="md"
        open={mode === Mode.Preview}
        onClose={() => setMode(undefined)}
      >
        <Modal.Header>{t('templatesArchive.preview.header')}</Modal.Header>
        <Modal.Content>{previewContent}</Modal.Content>
        <Modal.Footer type="fullwidth">
          <Modal.PrimaryAction onClick={() => setMode(undefined)}>
            {t('shared.close')}
          </Modal.PrimaryAction>
        </Modal.Footer>
      </Modal>
      <ConfirmationWithFeedbackModal
        state={
          mode === Mode.DeleteConfirm
            ? 'CONFIRMATION'
            : mode === Mode.DeleteFeedback
              ? 'FEEDBACK'
              : undefined
        }
        onConfirm={deleteTemplate}
        onCancel={() => setMode(undefined)}
        confirmationHeaderKey="templatesArchive.delete.header"
        feedbackText={{
          headerKey: 'templatesArchive.delete.error.header',
          contentKey: 'templatesArchive.delete.error.content',
        }}
      >
        <Typography variant="body/lg/regular">
          {t('templatesArchive.delete.content', {
            templateName: template.name,
          })}
        </Typography>
      </ConfirmationWithFeedbackModal>
      <StyledAccordion variant="block" testId={`accordion-${template.id}`}>
        <Accordion.Summary expandIcon={undefined}>
          <Flex width="100%" gap="md-4" alignItems="center">
            <Box flex="2">
              <Typography variant="body/sm/regular">
                {dtf(template.createdAt, DateTimeFormat.FULL_DATE)}
              </Typography>
            </Box>
            <Box flex="8">
              <Typography variant="body/sm/semibold">
                {template.name}
              </Typography>
            </Box>
            <Box flex="2">
              <Typography variant="body/sm/regular" textAlign="center">
                {t(`shared.communicationTypes.${template.communicationType}`)}
              </Typography>
            </Box>
            <Box flex="1" textAlign="right">
              <Tooltip
                text={t('templatesArchive.preview.tooltip')}
                variant="light"
              >
                <Button
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    track(TemplatesEvents.OpenTemplatePreview, {
                      templateId: template.id,
                    });
                    setMode(Mode.Preview);
                  }}
                  onlyIcon={true}
                  icon={<Icon name="Eye" />}
                  variant="plain"
                />
              </Tooltip>
            </Box>
            <Box flex="1" textAlign="right">
              <Button
                testId={`template-${template.id}-delete`}
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  track(TemplatesEvents.StartTemplateDelete, {
                    templateId: template.id,
                  });
                  setMode(Mode.DeleteConfirm);
                }}
                onlyIcon={true}
                color="grey"
                icon={<Icon name="Trash2" />}
                variant="plain"
              />
            </Box>
          </Flex>
        </Accordion.Summary>
        <Accordion.Details>
          <Button
            loading={templateDetails.isLoading}
            size="sm"
            variant="plain"
            onClick={() => {
              track(TemplatesEvents.StartTemplateEdit, {
                templateId: template.id,
              });
              setMode(Mode.Edit);
            }}
          >
            {t('templatesArchive.edit')}
          </Button>
          <Button
            size="sm"
            variant="plain"
            onClick={() => {
              track(TemplatesEvents.StartTemplateDuplicate, {
                templateId: template.id,
              });
              setMode(Mode.Clone);
            }}
          >
            {t('templatesArchive.duplicate')}
          </Button>
        </Accordion.Details>
      </StyledAccordion>
      {templateDetails.data && openPanel && (
        <SidePanel
          open={openPanel}
          onClose={() => setMode(undefined)}
          anchor="right"
        >
          <SidePanel.Content width="80vw">
            <TemplateCreator
              initialValues={templateDetails.data.structure}
              name={`${templateDetails.data.name}${
                mode === Mode.Clone ? ` - copy` : ''
              }`}
              communicationType={templateDetails.data.communicationType}
              templateId={mode === Mode.Clone ? undefined : template.id}
              closeCreator={() => setMode(undefined)}
            />
          </SidePanel.Content>
        </SidePanel>
      )}
    </>
  );
}
