import { useFormContext } from '@xtreamsrl/react-forms';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { useEffect, useState } from 'react';
import { MarkdownViewer } from '../../contentCreation/components/MarkdownViewer.tsx';
import { GenericTemplateComponent } from '../types';
import { generateLoremIpsumMarkdown } from '../utils';

export function TemplatePreview() {
  const t = useTranslate();
  const { watch } = useFormContext();
  const [previewContent, setPreviewContent] = useState('');

  useEffect(() => {
    const subscription = watch((value) => {
      const content = generateLoremIpsumMarkdown(
        value as GenericTemplateComponent,
      );
      setPreviewContent(content);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  return (
    <Flex direction="column" flex={1} gap="sm-4" pr="sm-8" pt="sm-8" pb="sm-8">
      <Flex gap="sm-4">
        <Box width="8px" height="100%" backgroundColor={'brand.6'} />
        <Typography variant="body/sm/regular">
          {t('template.creation.preview')}
        </Typography>
      </Flex>
      {previewContent && previewContent.length > 0 ? (
        <MarkdownViewer contentToDisplay={previewContent} />
      ) : null}
    </Flex>
  );
}
