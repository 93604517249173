import { default as fetch } from '../../_shared/fetch';
import { Communication } from '../types';

export async function duplicateCommunication(input: {
  communicationId: string;
  name: string;
}): Promise<Communication> {
  const communication: Promise<Communication> = fetch.post(
    `/communications/${input.communicationId}/copy`,
    {
      name: input.name,
    },
  );
  return communication;
}
