import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useDeleteTemplateMutation } from '../mutations/useDeleteTemplateMutation.ts';
import { getTemplateDetailsQuery } from '../queries/useTemplateDetailsQuery.ts';
import { Template } from '../types';

export enum Mode {
  Edit = 'edit',
  Clone = 'clone',
  Preview = 'preview',
  DeleteConfirm = 'deleteConfirm',
  DeleteFeedback = 'deleteFeedback',
}

export function useTemplateActions(template: Template) {
  const [mode, setMode] = useState<Mode>();

  const templateDetails = useQuery({
    ...getTemplateDetailsQuery(template.id),
    enabled: !!mode,
  });

  const { mutate: deleteMutation } = useDeleteTemplateMutation();

  const deleteTemplate = () => {
    deleteMutation(
      { templateId: template.id },
      {
        onError: () => setMode(Mode.DeleteFeedback),
      },
    );
  };

  return { mode, setMode, templateDetails, deleteTemplate };
}
