import { useTranslate } from '@xtreamsrl/react-i18n';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import {
  ComponentPropsWithoutRef,
  MouseEventHandler,
  ReactNode,
  memo,
  useCallback,
} from 'react';
import { MarkdownViewer } from '../MarkdownViewer';
import { Menu, MenuPosition } from './Menu';
import { ButtonShadow, Panel } from './Panel';

type ContainerProps = ComponentPropsWithoutRef<typeof Menu> & {
  buttons: ReactNode;
};

const Container = memo<ContainerProps>(function ReviewMenuContainer({
  children,
  position,
  buttons,
}) {
  // prevent toolbar from taking focus away from editor
  const handleMouseDown = useCallback<MouseEventHandler>((e) => {
    e.preventDefault();
  }, []);
  return (
    <Menu onMouseDown={handleMouseDown} position={position}>
      <Panel maxWidth={'50vw'}>{children}</Panel>
      {buttons}
    </Menu>
  );
});

function Buttons({
  onAcceptMod,
  onRejectMod,
}: {
  onAcceptMod: MouseEventHandler;
  onRejectMod: MouseEventHandler;
}) {
  const t = useTranslate();
  return (
    <Flex direction="row" justifyContent="flex-end" mt="sm-4" gap="sm-4">
      <ButtonShadow
        size="sm"
        variant="outlined"
        onClick={onAcceptMod}
        leftIcon={<Icon name="Check" />}
      >
        {t('editing.accept')}
      </ButtonShadow>
      <ButtonShadow
        size="sm"
        variant="outlined"
        onClick={onRejectMod}
        leftIcon={<Icon name="Trash" />}
      >
        {t('editing.discard')}
      </ButtonShadow>
    </Flex>
  );
}

export function ReviewMenu({
  menuPosition,
  content,
  onAcceptMod,
  onRejectMod,
  isLoading,
}: {
  menuPosition?: MenuPosition;
  content: string;
  onAcceptMod: () => void;
  onRejectMod: () => void;
  isLoading: boolean;
}) {
  const buttons = (
    <Buttons onAcceptMod={onAcceptMod} onRejectMod={onRejectMod} />
  );
  let display = content;
  if (!display && isLoading) display = 'Loading...';

  return (
    <Container buttons={buttons} position={menuPosition}>
      <MarkdownViewer
        key={display}
        contentToDisplay={display}
        paddingX="md-2"
        paddingY="sm-6"
      />
    </Container>
  );
}
