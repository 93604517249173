import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { Descendant, Node } from 'slate';
import { Editable, ReactEditor, Slate } from 'slate-react';
import {
  instantiateEditor,
  processor,
  withSingleLine,
} from '../../utils/slate';

type Props = {
  content: string;
  saveContent: (content: string) => void;
};

const editor = withSingleLine(instantiateEditor());

const SlateInLineEditor: React.FC<PropsWithChildren<Props>> = ({
  content,
  saveContent,
}) => {
  const initialDescendant = useMemo(
    () => processor.processSync(content).result as Descendant[],
    [content],
  );
  const [updatedContent, setUpdatedContent] =
    useState<Descendant[]>(initialDescendant);

  return (
    <Flex gap="sm-4" alignItems="center">
      <Typography variant={'header/sm/black'}>
        <Slate
          editor={editor}
          initialValue={initialDescendant}
          onValueChange={(value) => {
            setUpdatedContent(value);
          }}
        >
          <Editable
            id="slate-inline-editor"
            onBlur={() => {
              const text = Node.string(updatedContent[0]).trim();
              if (!text) {
                editor.insertFragment(initialDescendant);
              } else saveContent(text);
            }}
            style={{
              maxWidth: '30vw',
              padding: 4,
            }}
          />
        </Slate>
      </Typography>
      <Button
        testId="edit-template-name"
        variant="plain"
        onlyIcon={true}
        icon={<Icon name="Pencil" />}
        onClick={() => {
          ReactEditor.focus(editor);
        }}
      />
    </Flex>
  );
};

SlateInLineEditor.displayName = 'SlateInLineEditor';
export default SlateInLineEditor;
