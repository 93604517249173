import * as yup from 'yup';
import { CommunicationType } from '../../contentCreation/types';
import {
  ColumnName,
  ComponentType,
  DemTemplateComponent,
  GenericSectionContainerComponent,
  GenericTemplateComponent,
  RCSTemplateComponent,
  SectionFieldComponent,
  SortDirection,
} from '../types';

export function findNextSortState(currentSortState: SortDirection) {
  switch (currentSortState) {
    case undefined:
      return 'desc';
    case 'desc':
      return 'asc';
    case 'asc':
      return undefined;
    default:
      exhaustiveCheck(currentSortState);
  }
}

export function filterMapByDefinedValues(
  map: Record<ColumnName, SortDirection>,
): Record<ColumnName, SortDirection> | undefined {
  const hasDefinedValue = Object.values(map).some(
    (value) => value !== undefined,
  );

  return hasDefinedValue
    ? (Object.fromEntries(
        Object.entries(map).filter(([_, value]) => value !== undefined),
      ) as Record<ColumnName, SortDirection>)
    : undefined;
}

export function transformTemplateRequestData(data: {
  name: string;
  communicationType: CommunicationType;
  template: GenericTemplateComponent;
}) {
  return {
    name: data.name,
    communicationType: data.communicationType,
    structure: {
      ...data.template, // this will contain subject, mainTitle and sections in case of DEM template and only sections in case of SMS template

      // if the first component of a section is a SECTION_TITLE, it is moved externally from the components array to the title field
      // inside the section in order to fit the API structure
      sections: data.template.sections.map((section) => {
        const [firstComponent, ...rest] = section.components;
        return firstComponent.type === ComponentType.SECTION_TITLE
          ? {
              ...section,
              title: firstComponent,
              components: rest,
            }
          : {
              ...section,
              title: undefined,
            };
      }),
    },
  };
}

export const emptyTemplateSection: Record<
  CommunicationType,
  GenericSectionContainerComponent
> = {
  [CommunicationType.DEM]: {
    type: ComponentType.SECTION,
    description: '',
    components: [
      {
        type: ComponentType.SECTION_TITLE,
        maxLength: 30,
      },
      {
        type: ComponentType.TEXT,
        maxLength: 200,
      },
    ],
  },
  [CommunicationType.PAPER_MAIL]: {
    type: ComponentType.SECTION,
    description: '',
    components: [
      {
        type: ComponentType.SECTION_TITLE,
        maxLength: 30,
      },
      {
        type: ComponentType.TEXT,
        maxLength: 200,
      },
    ],
  },
  [CommunicationType.RCS]: {
    type: ComponentType.SECTION,
    description: '',
    components: [
      {
        type: ComponentType.SECTION_TITLE,
        maxLength: 50,
      },
      {
        type: ComponentType.TEXT,
        maxLength: 300,
      },
    ],
  },
  /*  [CommunicationType.SMS]: {
    type: ComponentType.SECTION,
    description: '',
    components: [
      {
        type: ComponentType.TEXT,
        maxLength: 160,
      },
    ],
  },*/
};

const subjectSchema = yup.object().shape({
  type: yup.string().required(),
  description: yup.string(),
  maxLength: yup
    .number()
    .typeError('template.creation.invalidMaxLength')
    .required()
    .min(10, 'template.creation.invalidMaxLength'), // template.creation.invalidMaxLength is an empty string -> see i18n file
});

const mainTitleSchema = yup
  .mixed()
  .test('is-valid-mainTitle', (value, context) => {
    if (!value) return true; // pass validation if mainTitle is undefined
    return (
      yup
        .object()
        .shape({
          type: yup.string().required(),
          description: yup.string(),
          maxLength: yup.number().required().min(10),
        })
        .isValidSync(value) ||
      context.createError({
        path: 'mainTitle.maxLength',
        message: 'template.creation.invalidMaxLength',
      })
    );
  });

const demSectionFieldComponentSchema = yup.lazy((value) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  value && value.type === ComponentType.TITLED_BULLET_POINT
    ? yup.object().shape({
        type: yup
          .string()
          .required()
          .oneOf([ComponentType.TITLED_BULLET_POINT]),
        maxTitleLength: yup
          .number()
          .typeError('template.creation.invalidMaxLength')
          .required('template.creation.invalidMaxLength')
          .min(10, 'template.creation.invalidMaxLength'),
        maxContentLength: yup
          .number()
          .typeError('template.creation.invalidMaxLength')
          .required('template.creation.invalidMaxLength')
          .min(10, 'template.creation.invalidMaxLength'),
      })
    : yup.object().shape({
        type: yup
          .string()
          .required()
          .oneOf([
            ComponentType.TEXT,
            ComponentType.BULLET_POINT,
            ComponentType.SECTION_TITLE,
            ComponentType.CTA,
          ]),
        maxLength: yup
          .number()
          .typeError('template.creation.invalidMaxLength')
          .required('template.creation.invalidMaxLength')
          .min(10, 'template.creation.invalidMaxLength'),
      }),
);

const demSectionContainerComponentSchema = yup.object().shape({
  type: yup.string().required(),
  description: yup.string().required('template.creation.missingDescription'),
  components: yup.array(demSectionFieldComponentSchema),
});

const demValidationSchema = yup.object().shape({
  subject: subjectSchema,
  mainTitle: mainTitleSchema,
  sections: yup.array().of(demSectionContainerComponentSchema).required(),
});

const paperMailSectionFieldComponentSchema = yup.lazy((value) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  value && value.type === ComponentType.TITLED_BULLET_POINT
    ? yup.object().shape({
        type: yup
          .string()
          .required()
          .oneOf([ComponentType.TITLED_BULLET_POINT]),
        maxTitleLength: yup
          .number()
          .typeError('template.creation.invalidMaxLength')
          .required('template.creation.invalidMaxLength')
          .min(10, 'template.creation.invalidMaxLength'),
        maxContentLength: yup
          .number()
          .typeError('template.creation.invalidMaxLength')
          .required('template.creation.invalidMaxLength')
          .min(10, 'template.creation.invalidMaxLength'),
      })
    : yup.object().shape({
        type: yup
          .string()
          .required()
          .oneOf([
            ComponentType.TEXT,
            ComponentType.BULLET_POINT,
            ComponentType.SECTION_TITLE,
          ]),
        maxLength: yup
          .number()
          .typeError('template.creation.invalidMaxLength')
          .required('template.creation.invalidMaxLength')
          .min(10, 'template.creation.invalidMaxLength'),
      }),
);

const paperMailSectionContainerComponentSchema = yup.object().shape({
  type: yup.string().required(),
  description: yup.string().required('template.creation.missingDescription'),
  components: yup.array(paperMailSectionFieldComponentSchema),
});

const paperMailValidationSchema = yup.object().shape({
  subject: subjectSchema,
  mainTitle: mainTitleSchema,
  sections: yup.array().of(paperMailSectionContainerComponentSchema).required(),
});

const rcsSectionFieldComponentSchema = yup.lazy((value) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  value && value.type === ComponentType.TITLED_BULLET_POINT
    ? yup.object().shape({
        type: yup
          .string()
          .required()
          .oneOf([ComponentType.TITLED_BULLET_POINT]),
        maxTitleLength: yup
          .number()
          .typeError('template.creation.invalidMaxLength')
          .required('template.creation.invalidMaxLength')
          .min(10, 'template.creation.invalidMaxLength'),
        maxContentLength: yup
          .number()
          .typeError('template.creation.invalidMaxLength')
          .required('template.creation.invalidMaxLength')
          .min(10, 'template.creation.invalidMaxLength'),
      })
    : yup.object().shape({
        type: yup
          .string()
          .required()
          .oneOf([
            ComponentType.SECTION_TITLE,
            ComponentType.TEXT,
            ComponentType.CTA,
          ]),
        maxLength: yup
          .number()
          .typeError('template.creation.invalidMaxLength')
          .required('template.creation.invalidMaxLength')
          .min(10, 'template.creation.invalidMaxLength'),
      }),
);

const rcsSectionContainerComponentSchema = yup.object().shape({
  type: yup.string().required(),
  description: yup.string().required('template.creation.missingDescription'),
  components: yup.array(rcsSectionFieldComponentSchema),
});

const rcsValidationSchema = yup.object().shape({
  sections: yup.array().of(rcsSectionContainerComponentSchema).required(),
});

/*const smsSectionContainerComponentSchema = yup.object().shape({
  type: yup.string().required(),
  description: yup.string().required('template.creation.missingDescription'),
  components: yup.array(
    yup.object().shape({
      type: yup.string().required().oneOf([ComponentType.TEXT]),
      maxLength: yup
        .number()
        .typeError('template.creation.invalidMaxLength')
        .required()
        .min(10, 'template.creation.invalidMaxLength'),
    }),
  ),
});*/

/*const smsValidationSchema = yup.object().shape({
  sections: yup.array().of(smsSectionContainerComponentSchema).required(),
});*/

export const validationSchemas: Record<
  CommunicationType,
  // eslint-disable-next-line
  yup.ObjectSchema<any>
> = {
  [CommunicationType.DEM]: demValidationSchema,
  [CommunicationType.PAPER_MAIL]: paperMailValidationSchema,
  [CommunicationType.RCS]: rcsValidationSchema,
  //[CommunicationType.SMS]: smsValidationSchema,
};

const demBasicInitialValues: DemTemplateComponent = {
  subject: {
    type: ComponentType.SUBJECT,
    description: undefined,
    maxLength: 20,
  },
  mainTitle: undefined,
  sections: [
    {
      type: ComponentType.SECTION,
      description: '',
      components: [
        {
          type: ComponentType.SECTION_TITLE,
          maxLength: 30,
        },
        {
          type: ComponentType.TEXT,
          maxLength: 200,
        },
        {
          type: ComponentType.BULLET_POINT,
          maxLength: 200,
        },
      ],
    },
  ],
};

const rcsBasicInitialValues: RCSTemplateComponent = {
  sections: [
    {
      type: ComponentType.SECTION,
      description: '',
      components: [
        {
          type: ComponentType.SECTION_TITLE,
          maxLength: 100,
        },
        {
          type: ComponentType.TEXT,
          maxLength: 300,
        },
        {
          type: ComponentType.CTA,
          maxLength: 300,
        },
      ],
    },
  ],
};

/*
const smsBasicInitialValues: SMSTemplateComponent = {
  sections: [
    {
      type: ComponentType.SECTION,
      description: '',
      components: [
        {
          type: ComponentType.TEXT,
          maxLength: 160, // this is the maximum length for an SMS
        },
      ],
    },
  ],
};
*/

export const basicInitialValues: Record<
  CommunicationType,
  GenericTemplateComponent
> = {
  [CommunicationType.DEM]: demBasicInitialValues,
  [CommunicationType.PAPER_MAIL]: demBasicInitialValues, // PAPER_MAIL templates are the same as DEM templates
  [CommunicationType.RCS]: rcsBasicInitialValues,
  // [CommunicationType.SMS]: smsBasicInitialValues,
};

export function getValuesWithMaxLengthParsed(values: GenericTemplateComponent) {
  return {
    ...values,
    mainTitle:
      'mainTitle' in values && values.mainTitle
        ? {
            ...values.mainTitle,
            maxLength: Number(values.mainTitle?.maxLength),
          }
        : undefined,
  };
}

export function removeDescriptionsIfEmpty(values: GenericTemplateComponent) {
  if ('subject' in values && values.subject) {
    return {
      ...values,
      subject:
        values.subject.description &&
        values.subject.description.trim().length > 0
          ? values.subject
          : {
              ...values.subject,
              description: undefined,
            },
      mainTitle: values.mainTitle
        ? values.mainTitle.description &&
          values.mainTitle.description.trim().length > 0
          ? values.mainTitle
          : {
              ...values.mainTitle,
              description: undefined,
            }
        : undefined,
    };
  } else return values;
}

// Simple Lorem Ipsum text for demonstration purposes
const loremIpsum =
  'Lorem ipsum, quia dolor sit, amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt, ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit, qui in ea voluptate velit esse, quam nihil molestiae consequatur, vel illum, qui dolorem eum fugiat, quo voluptas nulla pariatur? At vero eos et accusamus et iusto odio dignissimos ducimus, qui blanditiis praesentium voluptatum deleniti atque corrupti, quos dolores et quas molestias excepturi sint, obcaecati cupiditate non provident, similique sunt in culpa, qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio, cumque nihil impedit, quo minus id, quod maxime placeat, facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet, ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.';
// Helper function to obtainTextWithLength Lorem Ipsum text to a specific length
export const obtainTextWithLength = (maxLength: number) => {
  let result = loremIpsum;
  while (result.length < maxLength) {
    result += loremIpsum;
  }
  return result.substring(0, maxLength).trim();
};

function exhaustiveCheck(x: never): never {
  throw new Error(`Unhandled type: ${x as unknown as string}`);
}

export const generateLoremIpsumMarkdown = (
  structure: GenericTemplateComponent,
): string => {
  let markdownContent = '';

  // Handling the subject
  if ('subject' in structure && structure.subject) {
    markdownContent += `# ${obtainTextWithLength(
      structure.subject.maxLength,
    )}\n\n`;
  }

  // Handling the main title
  if ('mainTitle' in structure && structure.mainTitle) {
    markdownContent += `## ${obtainTextWithLength(
      structure.mainTitle.maxLength,
    )}\n\n`;
  }

  // Handling the sections
  structure.sections.forEach((section: GenericSectionContainerComponent) => {
    // Section components
    section.components.forEach((component: SectionFieldComponent) => {
      const type = component.type;
      switch (type) {
        case ComponentType.SECTION_TITLE:
          markdownContent += `### ${obtainTextWithLength(
            component.maxLength,
          )}\n\n`;
          break;
        case ComponentType.TEXT:
          markdownContent += `${obtainTextWithLength(component.maxLength)}\n\n`;
          break;
        case ComponentType.BULLET_POINT:
          markdownContent += `- ${obtainTextWithLength(
            component.maxLength,
          )}\n\n`;
          break;
        case ComponentType.TITLED_BULLET_POINT:
          markdownContent += `- **${obtainTextWithLength(
            component.maxTitleLength,
          )}** - ${obtainTextWithLength(component.maxContentLength)}\n\n`;
          break;
        case ComponentType.CTA:
          markdownContent += `### ${obtainTextWithLength(
            component.maxLength,
          )}\n\n`;
          break;
        default:
          exhaustiveCheck(type);
          break;
      }
    });

    markdownContent += '\n';
  });

  return markdownContent;
};
