import { useMutation, useQueryClient } from '@tanstack/react-query';
import { duplicateCommunication } from '../apis/duplicateCommunication';
import { searchCommsListQueryKeys } from 'src/archive/queries/useSearchCommsQuery';

export function useDuplicateCommMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: duplicateCommunication,
    async onMutate() {
      // Cancel related queries
      await queryClient.cancelQueries({
        queryKey: searchCommsListQueryKeys.all,
      });
    },
    async onSuccess() {
      // Invalidate related queries
      await queryClient.invalidateQueries({
        queryKey: searchCommsListQueryKeys.all,
      });
    },
  });
}
