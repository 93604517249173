import { queryOptions, useQuery } from '@tanstack/react-query';
import { getCommunicationAPI } from '../apis/getCommunication';

export const commDetailsQueryKeys = {
  all: ['comm', 'details'] as const,
  byId: (commId: string) => [...commDetailsQueryKeys.all, commId] as const,
};

export function getCommDetailsQuery(commId: string) {
  return queryOptions({
    queryKey: commDetailsQueryKeys.byId(commId),
    queryFn: ({ queryKey }) => {
      return getCommunicationAPI(queryKey[2]);
    },
  });
}

export function useCommDetailsQuery(commId: string) {
  return useQuery(getCommDetailsQuery(commId));
}
