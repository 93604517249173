import Grid from '@mui/material/Grid';
import {
  CommercialMailSvg,
  InfotainmentMailSvg,
  ServiceMailSvg,
} from '@reaidy/icons';
import { useFlag } from '@xtreamsrl/react-feature-flags';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Card } from '@xtreamsrl/react-ui-kit/Card';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';
import StyledCard from '../../_shared/components/StyledCard.tsx';
import { useActiveStep } from '../hooks/useActiveStep.ts';
import { useStepper } from '../hooks/useStepper.ts';

const aimTypes = [
  {
    code: 'COMMERCIAL',
    icon: CommercialMailSvg,
    text: 'shared.aimTypes.COMMERCIAL',
  },
  {
    code: 'INFOTAINMENT',
    icon: InfotainmentMailSvg,
    text: 'shared.aimTypes.INFOTAINMENT',
  },
  {
    code: 'SERVICE',
    icon: ServiceMailSvg,
    text: 'shared.aimTypes.SERVICE',
  },
];

export const AimSelector: React.FC = () => {
  const t = useTranslate();
  const { next } = useStepper();

  // This two lines of code are necessary to skip the AimSelector.
  // We have not found a way of doing this differently, but of course we cannot access feature flags in a static context (utils.ts and contentCreationSlice.ts)
  const [_, setActiveStep] = useActiveStep();
  const shouldBeShown = useFlag('ShowCommunicationAim', false);
  if (!shouldBeShown) {
    setActiveStep('communicationTypeSelection');
    return null;
  }

  return (
    <Flex gap="sm-3" direction="column" padding="sm-4" height="100%">
      <Flex
        direction="column"
        gap="sm-6"
        padding="sm-8"
        alignItems="flex-start"
        justifyContent="center"
        flexBasis="25vh"
        flexShrink="0"
      >
        <Typography variant="header/sm/bold" color="brand.9">
          {t('aimTypes.header')}
        </Typography>
        <Typography variant="body/base/regular">
          {t('aimTypes.indications')}
        </Typography>
      </Flex>
      <Grid container alignSelf="center" flex={7}>
        {aimTypes?.map((aimType) => (
          <Grid
            padding="8px"
            key={aimType.code}
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
          >
            <StyledCard key={aimType.code} testId={aimType.code} height="50vh">
              <Card.Content>
                <Box
                  height="40vh"
                  onClick={() => {
                    next();
                  }}
                >
                  <Flex
                    direction="column"
                    gap="sm-8"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <aimType.icon />
                    <Typography variant="body/sm/bold">
                      {t(aimType.text)}
                    </Typography>
                  </Flex>
                </Box>
              </Card.Content>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Flex>
  );
};

AimSelector.displayName = 'AimSelector';
