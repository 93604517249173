import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import React, { forwardRef } from 'react';
import { Descendant } from 'slate';
import MarkdownEditor, { MarkdownEditorRef } from '../MarkdownEditor';
import { MarkdownEditorHeader } from '../MarkdownEditorHeader.tsx';

type DraftEditorProps = {
  initialContent: string;
  handleSave: (value: Descendant[]) => void;
  isEditSaved: boolean;
  draftId: string;
  commId: string;
};

export const DraftEditor = forwardRef(function DraftEditor(
  {
    isEditSaved,
    handleSave,
    initialContent,
    draftId,
    commId,
  }: DraftEditorProps,
  ref: React.ForwardedRef<MarkdownEditorRef>,
) {
  return (
    <Flex direction="column" height="100%" backgroundColor="grey.1">
      <MarkdownEditor
        onChange={handleSave}
        initialString={initialContent}
        draftId={draftId}
        commId={commId}
        ref={ref}
      >
        <MarkdownEditor.Header>
          <MarkdownEditorHeader editSaved={isEditSaved} />
        </MarkdownEditor.Header>
      </MarkdownEditor>
    </Flex>
  );
});

export default DraftEditor;
