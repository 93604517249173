import { useSlate } from 'slate-react';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Tooltip } from '@xtreamsrl/react-ui-kit/Tooltip';
// todo - move it into the react-ui-kit
import ButtonGroup from '@mui/material/ButtonGroup';
import { ContentCreationEvents, useTracker } from '../analytics';
import { useCommunication } from '../hooks/useCommunication.ts';
import { useStepper } from '../hooks/useStepper.ts';

export const EditorToolbar = () => {
  const editor = useSlate();
  const { id } = useCommunication();
  const { activeStepKey } = useStepper();
  const { track } = useTracker();

  return (
    <Flex gap="sm-2">
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
      >
        <Tooltip text="Undo">
          <Button
            size="sm"
            onlyIcon
            disabled={editor.history.undos.length == 0}
            variant="outlined"
            onClick={() => {
              track(ContentCreationEvents.UndoEdit, {
                communicationId: id,
                activeStep: activeStepKey,
              });
              editor.undo();
            }}
            icon={<Icon name="Undo" />}
          />
        </Tooltip>
        <Tooltip text="Redo">
          <Button
            size="sm"
            onlyIcon
            disabled={editor.history.redos.length == 0}
            variant="outlined"
            onClick={() => {
              track(ContentCreationEvents.RedoEdit, {
                communicationId: id,
                activeStep: activeStepKey,
              });
              editor.redo();
            }}
            icon={<Icon name="Redo" />}
          />
        </Tooltip>
      </ButtonGroup>
    </Flex>
  );
};
