import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { useMemo } from 'react';
import { usePersonaListQuery } from '../queries/usePersonaListQuery';

const Pill = styled('span')(({ theme }) => ({
  borderRadius: '9999px',
  background: theme.palette.brand[2],
  color: theme.palette.brand[10],
  ...theme.typography['body/sm/regular'],
  width: '4rem',
  textAlign: 'center',
  padding: '0.3rem 1rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'inline-block',
  boxSizing: 'content-box',
}));

export function PersonaLabel({
  personaId,
  className,
}: {
  personaId: string;
  className?: string;
}) {
  const { data: personas = [] } = usePersonaListQuery();
  const persona = useMemo(
    () => personas?.find((persona) => persona.id === personaId),
    [personas, personaId],
  );
  if (!persona) return null;
  return (
    <Pill role="presentation" className={className}>
      {persona.name}
    </Pill>
  );
}
