import { FormProvider } from '@xtreamsrl/react-forms';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { BriefDEMForm } from '../components/BriefDEMForm.tsx';
import { useCreateCommunication } from '../hooks/useCreateCommunication.ts';
import { useStepper } from '../hooks/useStepper.ts';
import { usePersonaListQuery } from '../queries/usePersonaListQuery.ts';
import { Persona } from '../types/index.ts';
import { mockedBrief } from '../utils/utils.tsx';

export function BriefDEMFormFullPage() {
  const { data: personas = [], isLoading } = usePersonaListQuery();
  // We need to mount the form only when the personas are loaded
  if (isLoading) return null;
  return (
    <Flex height="100%">
      <BriefDEMFormWrapped personas={personas} />
    </Flex>
  );
}

function BriefDEMFormWrapped({ personas }: { personas: Persona[] }) {
  const { previous, next } = useStepper();

  const {
    isNewCommunication,
    submit,
    isPending,
    form,
    showModal,
    setShowModal,
  } = useCreateCommunication({
    onSuccess: next,
    personas,
  });

  const fillWithMock = () => {
    form.formProps.setValue('name', mockedBrief.name);
    form.formProps.setValue('description', mockedBrief.description);
    form.formProps.setValue('plusOrIncentives', mockedBrief.plusOrIncentives);
  };

  return (
    <FormProvider {...form.formProps}>
      <BriefDEMForm
        previous={previous}
        submit={submit}
        isPending={isPending}
        showModal={showModal}
        setShowModal={setShowModal}
        fillWithMock={fillWithMock}
        isNewCommunication={isNewCommunication}
        personas={personas}
      />
    </FormProvider>
  );
}
