import fetch from '../../_shared/fetch';

import { BETemplateData, TemplateData } from '../types';

export function getTemplateDetails(templateId: string): Promise<TemplateData> {
  const beTemplateDetails: Promise<BETemplateData> = fetch.get(
    `/templates/${templateId}`,
  );

  return beTemplateDetails.then((templateDetails) => {
    const { structure, ...rest } = templateDetails;
    return {
      ...rest,
      structure: {
        subject: structure.subject,
        mainTitle: structure.mainTitle,
        sections: structure.sections.map((section) => {
          const { title, components, ...rest } = section;
          return {
            ...rest,
            components: title
              ? [
                  { type: title.type, maxLength: title.maxLength },
                  ...components,
                ]
              : components,
          };
        }),
      },
    };
  });
}
