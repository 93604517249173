import { useTranslate } from '@xtreamsrl/react-i18n';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Spinner } from '@xtreamsrl/react-ui-kit/Spinner';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';

const LoadingSection: React.FC = () => {
  const t = useTranslate();

  return (
    <Flex
      direction="column"
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      gap="sm-2"
    >
      <Typography variant="body/base/regular">{t('loading')}</Typography>
      <Spinner size={20} state="indeterminate" />
    </Flex>
  );
};

LoadingSection.displayName = 'LoadingSection';
export default LoadingSection;
