import { useMutation, useQueryClient } from '@tanstack/react-query';
import { default as fetch } from 'src/_shared/fetch';
import { commPersonaDraftQueryKeys } from '../queries/useCommPersonaDraftQuery';
import { PersonaDraft } from '../types';
import { NdjsonDecoderStream } from '../utils/utils';

async function generatePersonaDraft(
  {
    commId,
    draftId,
  }: {
    commId: string;
    draftId: string;
  },
  signal?: AbortSignal,
) {
  const response = await fetch.get<Response>(
    `/communications/${commId}/persona-drafts/${draftId}/content`,
    {}, // query params
    false, // extractJson
    signal,
  );
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  const stream = response.body;
  if (!stream) {
    throw new Error('No content stream');
  }
  return stream;
}

type ChunkType =
  | { delta: string }
  | { id: string; message: string; code: string };

export function useGeneratePersonaDraftMutation({
  onStreamError,
}: {
  onStreamError: (error: unknown) => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: generatePersonaDraft,
    async onSuccess(stream, { commId, draftId }) {
      try {
        for await (const chunk of readStream(stream)) {
          // Update the draft in the cache on each chunk
          queryClient.setQueryData<PersonaDraft>(
            commPersonaDraftQueryKeys.byCommIdAndDraftId(commId, draftId),
            (oldData) => {
              if (!oldData) return oldData;
              const oldDraft = oldData.draft ?? '';
              return {
                ...oldData,
                draft: oldDraft + chunk,
              };
            },
          );
        }
      } catch (error) {
        console.error(error);
        onStreamError(error);
      }
      // Invalidate the query to refetch the draft
      await queryClient.invalidateQueries({
        queryKey: commPersonaDraftQueryKeys.byCommIdAndDraftId(commId, draftId),
      });
    },
  });
}

async function* readStream(stream: ReadableStream<Uint8Array>) {
  const reader = stream
    .pipeThrough(new TextDecoderStream())
    .pipeThrough(new NdjsonDecoderStream<ChunkType>())
    .getReader();

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;

    if ('delta' in value) {
      yield value.delta;
    } else {
      throw new Error(`${value.code}: ${value.message}`);
    }
  }
}
