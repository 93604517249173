import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { useCommPersonaDraftQuery } from 'src/contentCreation/queries/useCommPersonaDraftQuery.ts';
import { MarkdownViewer } from '../MarkdownViewer.tsx';

export function PersonaDraftView({
  commId,
  draftId,
}: {
  commId: string;
  draftId: string;
}) {
  const { data: personaDraft } = useCommPersonaDraftQuery({
    commId,
    draftId,
  });
  const contentToDisplay = personaDraft?.draft || '';

  return (
    <Flex direction="column" height="100%" backgroundColor="transparent">
      <MarkdownViewer contentToDisplay={contentToDisplay} />
    </Flex>
  );
}
