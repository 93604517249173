import { queryOptions, useQuery } from '@tanstack/react-query';
import { default as fetch } from '../../_shared/fetch';
import { PersonaDraft } from '../types';

export const commPersonaDraftQueryKeys = {
  all: ['comm', 'persona-draft'] as const,
  byCommId: (commId: string) =>
    [...commPersonaDraftQueryKeys.all, commId] as const,
  byCommIdAndDraftId: (commId: string, draftId: string) =>
    [...commPersonaDraftQueryKeys.byCommId(commId), draftId] as const,
};

export function getCommPersonaDraftQuery({
  commId,
  draftId,
}: {
  commId: string;
  draftId: string;
}) {
  return queryOptions({
    queryKey: commPersonaDraftQueryKeys.byCommIdAndDraftId(commId, draftId),
    queryFn: ({ queryKey }) => {
      return getCommPersonaDraftAPI(queryKey[2], queryKey[3]);
    },
  });
}

function isNotFoundError(error: unknown) {
  return (
    error instanceof Error && (error as Error & { code: number }).code === 404
  );
}

export async function getCommPersonaDraftAPI(commId: string, draftId: string) {
  try {
    const response: PersonaDraft = await fetch.get(
      `/communications/${commId}/persona-drafts/${draftId}`,
    );
    return response;
  } catch (error) {
    if (isNotFoundError(error)) return null;
    throw error;
  }
}

export function useCommPersonaDraftQuery({
  commId,
  draftId,
}: {
  commId: string;
  draftId: string;
}) {
  return useQuery(getCommPersonaDraftQuery({ commId, draftId }));
}
