import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { DateTimeFormat } from '../../_shared/assets/i18n';
import { useDateTimeFormat, useTranslate } from '@xtreamsrl/react-i18n';
import HeroSection from '../components/HeroSection';
import { CommunicationList } from '../../archive/components/CommunicationList.tsx';

export function Home() {
  const t = useTranslate();
  const dtf = useDateTimeFormat();
  const date = new Date();
  const dateSection = (
    <Flex alignItems="center" alignSelf="flex-end" gap="sm-4">
      <Icon name="Clock3" color="grey.12" />
      <Typography variant="body/sm/regular">
        {dtf(date, DateTimeFormat.FULL_DATE_TIME)}
      </Typography>
    </Flex>
  );

  return (
    <Flex
      direction="column"
      width="100%"
      padding="sm-8"
      gap="sm-4"
      overflow="auto"
      height="100vh"
    >
      {dateSection}

      <Flex direction="column" flex={4} height="100%" gap="md-2">
        <HeroSection />
        <Flex flex={1} direction="column" gap="sm-4">
          <Typography variant="body/base/bold">
            {t('dashboard.lastProjects')}
          </Typography>
          <CommunicationList />
        </Flex>
      </Flex>
    </Flex>
  );
}
