import { create, StateCreator } from 'zustand';

type VisualSlice = {
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
  isMenuExpanded: boolean;
  setIsMenuExpanded: (isMenuExpanded: boolean) => void;
};

const initialValues = {
  isMenuOpen: true,
  isMenuExpanded: false,
};

const createVisualSlice: StateCreator<VisualSlice, [], [], VisualSlice> = (
  set,
) => ({
  ...initialValues,
  setIsMenuOpen: (isMenuOpen: boolean) => {
    set((state) => ({ ...state, isMenuOpen }));
  },
  setIsMenuExpanded: (isMenuExpanded: boolean) => {
    set((state) => ({ ...state, isMenuExpanded }));
  },
});
export const useVisualSlice = create<VisualSlice>((...a) => ({
  ...createVisualSlice(...a),
}));
