import { default as fetch } from '../../_shared/fetch';
import { Communication } from '../types';
import { CreateCommunicationInput } from './createCommunication';

export async function recreateCommunication({
  commId,
  input,
}: {
  commId: string;
  input: CreateCommunicationInput;
}): Promise<Communication> {
  const communication: Communication = await fetch.put(
    `/communications/${commId}`,
    input,
    {
      extractJson: true,
    },
  );
  return communication;
}
