import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createTemplateAPI } from '../apis/createTemplate';
import { templatesListQueryKeys } from '../queries/useTemplateListQuery';

export function useCreateTemplateMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createTemplateAPI,
    async onMutate() {
      // Cancel related queries
      await queryClient.cancelQueries({
        queryKey: templatesListQueryKeys.all,
      });
    },
    async onSuccess() {
      // Invalidate related queries
      await queryClient.invalidateQueries({
        queryKey: templatesListQueryKeys.all,
      });
    },
  });
}
