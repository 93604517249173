import { useQuery } from '@tanstack/react-query';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { useState } from 'react';
import { EditingLayout } from '../components/CommEditingLayout';
import { CommViewingDraftList } from '../components/CommViewingDraftList';
import { CommViewingHeader } from '../components/CommViewingHeader';
import { PersonaDraftView } from '../components/DraftEditor/PersonaDraftView';
import { useRetrieveCommunication } from '../hooks/useRetrieveCommunication';
import { getCommDetailsQuery } from '../queries/useCommDetailsQuery';
import { Communication, PersonaDraft } from '../types';

export function CommViewing() {
  const { communication } = useRetrieveCommunication();
  if (!communication) return null;

  return <CommViewingComponent communication={communication} />;
}

function CommViewingComponent({
  communication,
}: {
  communication: Communication;
}) {
  const commId = communication.id;
  const { data: personaDrafts = [] } = useQuery({
    ...getCommDetailsQuery(commId),
    select: (data) => data?.personaDrafts,
    initialData: communication,
  });

  const [selectedDraft, setSelectedDraft] = useState<PersonaDraft | undefined>(
    personaDrafts[0],
  );

  return (
    <EditingLayout
      heading={<CommViewingHeader communication={communication} />}
      leftPanel={
        selectedDraft && (
          <LeftPanel commId={commId} draftId={selectedDraft?.id} />
        )
      }
      rightPanel={
        <RightPanel
          commId={commId}
          selectedDraft={selectedDraft}
          setSelectedDraft={setSelectedDraft}
          personaDrafts={personaDrafts}
        />
      }
    />
  );
}

function LeftPanel({ draftId, commId }: { draftId: string; commId: string }) {
  return (
    <Box height="100%">
      <PersonaDraftView commId={commId} draftId={draftId} />
    </Box>
  );
}

function RightPanel({
  commId,
  selectedDraft,
  setSelectedDraft,
  personaDrafts,
}: {
  commId: string;
  selectedDraft?: PersonaDraft;
  setSelectedDraft: (draft: PersonaDraft) => void;
  personaDrafts: PersonaDraft[];
}) {
  return (
    <Flex direction="column" gap="3rem">
      <CommViewingDraftList
        commId={commId}
        selectedDraft={selectedDraft}
        personaDrafts={personaDrafts}
        setSelectedDraft={setSelectedDraft}
      />
    </Flex>
  );
}
