import { useInfiniteScroll } from '../../archive/hooks/useInfiniteScroll.ts';
import { CommunicationType } from '../../contentCreation/types';
import { ColumnName, SortDirection } from '../types';
import { useTemplatesListQuery } from '../queries/useTemplateListQuery.ts';
import { useCallback } from 'react';

export function useTemplates<TScrollerElement extends HTMLElement>({
  commType,
  sortBy,
  scrollerRef,
}: {
  commType?: CommunicationType;
  sortBy?: Record<ColumnName, SortDirection>;
  scrollerRef: React.RefObject<TScrollerElement>;
}) {
  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useTemplatesListQuery({ commType, sortBy });

  const handleNextPage = useCallback(() => {
    fetchNextPage().catch((error) => {
      console.error('Failed to fetch next page:', error);
    });
  }, [fetchNextPage]);

  useInfiniteScroll({
    isLoading,
    hasNextPage,
    handleNextPage,
    scrollerRef,
  });

  const templates =
    data && data.pages.flatMap((singlePage) => singlePage.content);

  return {
    isInitialLoading: status === 'pending',
    isFetchingNextPage,
    templates,
  };
}
