import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { ReactNode } from 'react';

export function EditorStatus({
  pinColor,
  children,
}: {
  pinColor: string;
  children: ReactNode;
}) {
  return (
    <Flex gap="sm-4" alignItems="center" {...{ role: 'status' }}>
      <Box
        style={{ borderRadius: 50 }}
        textAlign="right"
        width="10px"
        height="10px"
        backgroundColor={pinColor}
        {...{ role: 'presentation' }}
      />
      <Typography variant="body/sm/regular" style={{ fontStyle: 'italic' }}>
        {children}
      </Typography>
    </Flex>
  );
}
