import { styled } from '@mui/material/styles';
import { Card } from '@xtreamsrl/react-ui-kit/Card';

export const StyledCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover,&:focus': {
    border: `1px solid ${theme.palette.brand[9]}`,
    '.communicationType-svg': {
      rect: { stroke: theme.palette.brand[9] },
      fill: theme.palette.brand[9],
      path: { fill: 'white' },
    },
  },
}));

export default StyledCard;
