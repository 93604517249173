import { Theme } from '@reaidy/components';
import { Box } from '@xtreamsrl/react-ui-kit/Box';

export function EditorWrapper({
  paddingX,
  paddingY,
  children,
}: {
  paddingX?: string | number;
  paddingY?: string | number;
  children: React.ReactNode;
}) {
  return (
    <Box
      {...{
        sx: (theme: Theme) => ({
          paddingBlock: paddingY ?? theme.spacing('sm-6'),
          paddingInline: paddingX ?? theme.spacing('md-5'),
          overflow: 'auto',
        }),
      }}
    >
      {children}
    </Box>
  );
}
