import { styled } from '@xtreamsrl/react-ui-kit/styles';
import { ReactNode } from 'react';

const DIVIDER_COLOR = 'rgba(0, 0, 0, 0.12)';

const Grid = styled('section')(({ theme }) => ({
  height: '100%',
  display: 'grid',
  gridTemplateAreas: `
    "h h r"
    "l l r" 
  `,
  gridTemplateRows: 'auto 1fr',
  gridTemplateColumns: '1fr 1fr 30rem',

  // Mobile
  [theme.breakpoints.down('lg')]: {
    gridTemplateAreas: `
    "h h h"
    "l l l"
  `,
  },
}));

const Header = styled('header')(({ theme }) => ({
  gridArea: 'h',
  paddingInline: theme.spacing('md-5'),
  paddingBlock: theme.spacing('sm-6'),
  borderBottom: 'solid 1px',
  borderColor: DIVIDER_COLOR,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
}));

const LeftPanel = styled('div')(() => ({
  gridArea: 'l',
  overflow: 'auto',
}));

const RightPanel = styled('div')(({ theme }) => ({
  gridArea: 'r',
  overflow: 'auto',
  padding: theme.spacing('md-3'),
  borderLeft: `1px solid ${DIVIDER_COLOR}`,

  // Mobile
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

export function EditingLayout({
  rightPanel,
  leftPanel,
  heading,
}: {
  rightPanel: ReactNode;
  leftPanel: ReactNode;
  heading?: ReactNode;
}) {
  return (
    <Grid>
      <Header>{heading}</Header>
      <LeftPanel>{leftPanel}</LeftPanel>
      <RightPanel>{rightPanel}</RightPanel>
    </Grid>
  );
}
