import { useTranslate } from '@xtreamsrl/react-i18n';
import { Modal } from '@xtreamsrl/react-ui-kit/Modal';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';

export type FeedbackProps = {
  headerKey: string;
  contentKey: string;
  visible: boolean;
  primaryAction: () => void;
};
const FeedbackModal: React.FC<FeedbackProps> = ({
  headerKey,
  contentKey,
  visible,
  primaryAction,
}) => {
  const t = useTranslate();

  return (
    <Modal open={visible} onClose={primaryAction} showCloseButton={false}>
      <Modal.Header>{t(headerKey)}</Modal.Header>
      <Modal.Content>
        <Typography variant="body/sm/regular">
          {t(contentKey, { defaults: [{ scope: 'genericError' }] })}
        </Typography>
      </Modal.Content>
      <Modal.Footer type="right">
        <Modal.PrimaryAction onClick={primaryAction}>
          {t('shared.ok')}
        </Modal.PrimaryAction>
      </Modal.Footer>
    </Modal>
  );
};

FeedbackModal.displayName = 'FeedbackModal';
export default FeedbackModal;
