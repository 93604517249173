import { theme } from '@reaidy/components';
import { FormTextInputBasic } from '@xtreamsrl/react-form-input-kit/FormTextInputBasic';
import { useTranslate } from '@xtreamsrl/react-i18n';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Icon } from '@xtreamsrl/react-ui-kit/Icon';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import { useContext } from 'react';
import { TemplatesEvents, useTracker } from '../analytics';
import { emptyTemplateSection } from '../utils';
import { GenericSectionContainerComponent } from '../types';
import { CommunicationTypeContext } from '../views/TemplateCreator.tsx';
import { TemplateSectionFields } from './TemplateSectionFields';

export function TemplateSection({
  index,
  insert,
  remove,
}: {
  index: number;
  insert: (index: number, value: GenericSectionContainerComponent) => void;
  remove: (index: number) => void;
}) {
  const t = useTranslate();
  const { track } = useTracker();
  const communicationType = useContext(CommunicationTypeContext);

  return (
    <>
      <Box
        padding="sm-6"
        pt="sm-2"
        border={`1px solid ${theme.palette.grey[5]}`}
        style={{ borderRadius: '10px' }}
      >
        {/* Section header */}
        <Flex
          justifyContent="space-between"
          marginBottom="sm-4"
          alignItems="center"
        >
          <Flex gap="sm-4" alignItems="center">
            <Icon fontSize="large" name="TextSelect" />
            <Typography>
              {t('template.creation.section', { index: index + 1 })}
            </Typography>
          </Flex>
          <Button
            variant="plain"
            onlyIcon={true}
            onClick={() => {
              track(TemplatesEvents.RemoveTemplateSection);
              remove(index);
            }}
            icon={<Icon color={theme.palette.grey[9]} name="Trash2" />}
          />
        </Flex>
        <Flex direction="column" gap="sm-2">
          <Box pl="sm-2" pr="sm-2" marginBottom="sm-2">
            <FormTextInputBasic
              testId={`section.${index}.description`}
              hint={t('template.creation.missingDescription')}
              name={`sections.${index}.description`}
              multiline={true}
              placeholder={t('template.creation.description')}
            />
          </Box>
          <TemplateSectionFields sectionIndex={index} />
        </Flex>
      </Box>
      <Box alignSelf="center">
        <Button
          variant="tinted"
          onClick={() => {
            track(TemplatesEvents.AddTemplateSection);
            insert(index + 1, emptyTemplateSection[communicationType]);
          }}
        >
          {t('template.creation.addSection')}
        </Button>
      </Box>
    </>
  );
}
