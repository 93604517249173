import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { ReactNode } from 'react';

export const SideMenuButton = ({
  testId,
  icon,
  label,
  justifyContent,
  expanded,
  active,
  onClick,
}: {
  testId?: string;
  icon: ReactNode;
  label: string;
  justifyContent?: string;
  expanded: boolean;
  active: boolean;
  onClick: () => void;
}) => {
  return expanded ? (
    <Button
      testId={testId}
      style={{ justifyContent: justifyContent }}
      variant={active ? 'filled' : 'plain'}
      size="md"
      color={active ? 'brand' : 'grey'}
      leftIcon={icon}
      onClick={onClick}
    >
      {label}
    </Button>
  ) : (
    <Button
      testId={testId}
      variant={active ? 'filled' : 'plain'}
      onlyIcon={true}
      size="md"
      color={active ? 'brand' : 'grey'}
      icon={icon}
      onClick={onClick}
    />
  );
};
