import { useTranslate } from '@xtreamsrl/react-i18n';
import { Dropmenu } from '@xtreamsrl/react-ui-kit/Dropmenu';
import { Icon, type LucideIcon } from '@xtreamsrl/react-ui-kit/Icon';
import { type TypographyVariants } from '@xtreamsrl/react-ui-kit/Typography';
import { styled } from '@xtreamsrl/react-ui-kit/styles';
import {
  ChangeEventHandler,
  ComponentPropsWithoutRef,
  useCallback,
  useRef,
  useState,
} from 'react';
import { flushSync } from 'react-dom';
import type { TranslationKeys } from 'src/_shared/assets/i18n';
import { useDeletePersonaDraftMutation } from '../mutations/useDeletePersonaDraftMutation';
import { useUpdatePersonaDraftMutation } from '../mutations/useUpdatePersonaCommMutation';
import { useCommPersonaDraftQuery } from '../queries/useCommPersonaDraftQuery';
import { PersonaDraft } from '../types';
import { CommEditingDuplicateModal } from './CommEditingDuplicateModal';
import { CommEditingVariantModal } from './CommEditingVariantModal';
import { PersonaLabel } from './PersonaLabel';

// Must be shared between the NameField.Input and RadioButton.Title
// to make sure that the input and the text conincide in size and position
// (padding plays a part too)
const TITLE_TYPOGRAPHY: keyof TypographyVariants = 'body/base/regular';
const ITEM_HEIGHT = '3.25rem';

export const Fieldset = styled('fieldset')(() => ({
  border: 'none',
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

export const Legend = styled('legend')(({ theme }) => {
  const typography = theme.typography['body/xl/regular'];
  return {
    ...typography,
    marginBottom: '3rem',
    color: theme.palette.grey[10],
  };
});

/**
 * @see https://www.figma.com/design/Vs6TnPuZy8bXd8p5X6SRKU/Reaidy-%7C-MVP-Prototipo?node-id=2493-9507&t=kUti0xEh58RT3Ve5-0
 */
export function CommEditingDraftList({
  commId,
  selectedDraft,
  personaDrafts,
  setSelectedDraft,
}: {
  commId: string;
  selectedDraft?: PersonaDraft;
  personaDrafts: PersonaDraft[];
  setSelectedDraft: (draft: PersonaDraft) => void;
}) {
  const t = useTranslate();
  return (
    <Fieldset>
      <Legend>
        {t('commEditing.draftList.title' satisfies TranslationKeys)}
      </Legend>
      {personaDrafts.map((draft) => (
        <ListItem
          key={draft.id}
          draftId={draft.id}
          commId={commId}
          selectedDraft={selectedDraft}
          setSelectedDraft={setSelectedDraft}
          personaDrafts={personaDrafts}
        />
      ))}
    </Fieldset>
  );
}

function ListItem({
  commId,
  draftId,
  selectedDraft,
  setSelectedDraft,
  personaDrafts,
}: {
  commId: string;
  draftId: string;
  selectedDraft?: PersonaDraft;
  setSelectedDraft: (draft: PersonaDraft) => void;
  personaDrafts: PersonaDraft[];
}) {
  const { data: draft } = useCommPersonaDraftQuery({
    commId,
    draftId,
  });

  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [menuOpen, setMenuOpen] = useState(false);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const isSelected = selectedDraft?.id === draft?.id;

  // When the user clicks on the draft name, we switch to edit mode
  const handleRenameAction = useCallback(() => {
    flushSync(() => setMode('edit'));
    nameInputRef.current?.focus();
  }, []);

  const { mutateAsync: deleteDraft } = useDeletePersonaDraftMutation();

  // When the user clicks on the delete button, we delete the draft
  const handleDeleteAction = useCallback(() => {
    // After deleting, we select the next draft. This is done with a promise
    // because this component is unmounted when the mutation finishes. [...]
    deleteDraft({
      draftId,
      commId,
    })
      .then(() => {
        // [...] Do not use hooks that are created from inside this component.
        // The setSelectedDraft function should be fine.
        if (selectedDraft?.id !== draftId) return;
        const draftIndex = personaDrafts.findIndex(
          (draft) => draft.id === draftId,
        );
        if (draftIndex === -1) return;
        const nextDraft = personaDrafts[draftIndex + 1];
        if (nextDraft) return setSelectedDraft(nextDraft);
        const prevDraft = personaDrafts[draftIndex - 1];
        if (prevDraft) return setSelectedDraft(prevDraft);
      })
      .catch((error) => {
        console.error('Failed to delete draft', error);
      });
  }, [
    deleteDraft,
    draftId,
    commId,
    personaDrafts,
    setSelectedDraft,
    selectedDraft,
  ]);

  const { mutate: updateDraft } = useUpdatePersonaDraftMutation();

  // When the user submits the new name, we save it and switch back to view mode
  const handleNameChange = useCallback(
    (newName: string) => {
      setMode('view');
      if (newName === draft?.name) return;
      updateDraft({
        commId,
        draftId: draftId,
        update: { name: newName },
      });
    },
    [commId, draftId, updateDraft, draft?.name],
  );

  // Selects the pending draft
  const handleNewDraftPending = useCallback(
    (pendingDraft: PersonaDraft) => setSelectedDraft(pendingDraft),
    [setSelectedDraft],
  );

  // Selects the new draft
  const handleNewDraftCreated = useCallback(
    (newDraft: PersonaDraft) => setSelectedDraft(newDraft),
    [setSelectedDraft],
  );

  // Selects next available draft
  const handleNewDraftCancelled = useCallback(() => {
    const draftIndex = personaDrafts.findIndex((draft) => draft.id === draftId);
    if (draftIndex === -1) return;

    const nextDraft = personaDrafts[draftIndex + 1];
    if (nextDraft) return setSelectedDraft(nextDraft);

    const prevDraft = personaDrafts[draftIndex - 1];
    if (prevDraft) return setSelectedDraft(prevDraft);
  }, [setSelectedDraft, personaDrafts, draftId]);

  if (!draft) return null;
  return (
    <div>
      <div style={{ display: 'flex', position: 'relative' }}>
        <RadioButton
          draft={draft}
          mode={mode}
          selectedDraft={selectedDraft}
          setSelectedDraft={setSelectedDraft}
          onRenameAction={handleRenameAction}
        />
        {mode === 'edit' && (
          <NameField
            onNameChange={handleNameChange}
            inputRef={nameInputRef}
            initialValue={draft.name}
          />
        )}
        <InlineMenu
          draft={draft}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          onDeleteAction={handleDeleteAction}
          onRenameAction={handleRenameAction}
        />
      </div>
      {isSelected && (
        <BlockMenu
          draft={draft}
          commId={commId}
          onNewDraftPending={handleNewDraftPending}
          onNewDraftCancelled={handleNewDraftCancelled}
          onNewDraftCreated={handleNewDraftCreated}
        />
      )}
    </div>
  );
}

RadioButton.Label = styled('label')<{ isSelected?: boolean }>(
  ({ theme, isSelected }) => ({
    width: '100%',
    border: 'solid 2px',
    padding: '0.75rem',
    borderColor: isSelected ? theme.palette.brand[10] : theme.palette.grey[3],
    borderRadius: '0.5rem',
    height: ITEM_HEIGHT,
    cursor: 'pointer',
    background: theme.palette.white?.[5],
    boxShadow: '0px 5px 8px 0px rgba(128, 159, 184, 0.14);',
    position: 'relative',
  }),
);

RadioButton.Label.displayName = 'RadioButtonLabel';

RadioButton.Input = styled('input')(() => ({
  position: 'fixed',
  top: -100,
  left: -100,
}));
RadioButton.Input.displayName = 'RadioButtonInput';

RadioButton.Title = styled('span')<{ isVisible: boolean }>(
  ({ isVisible, theme }) => {
    const typography = theme.typography[TITLE_TYPOGRAPHY];
    return {
      opacity: isVisible ? 1 : 0,
      ...typography,
      userSelect: 'none',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '15rem',
      display: 'block',
    };
  },
);

RadioButton.PersonaLabel = styled(PersonaLabel)({
  position: 'absolute',
  top: '50%',
  right: '3rem',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
});

export function RadioButton({
  draft,
  mode,
  selectedDraft,
  setSelectedDraft,
  onRenameAction,
}: {
  selectedDraft?: PersonaDraft;
  setSelectedDraft: (draft: PersonaDraft) => void;
  draft: PersonaDraft;
  mode: 'view' | 'edit';
  onRenameAction: () => void;
}) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    () => setSelectedDraft(draft),
    [draft, setSelectedDraft],
  );

  const isSelected = draft.id === selectedDraft?.id;
  const handleClick = useCallback(() => {
    if (isSelected) onRenameAction();
  }, [isSelected, onRenameAction]);

  return (
    <RadioButton.Label isSelected={isSelected} onClick={handleClick}>
      <RadioButton.Input
        type="radio"
        name="draft"
        value={draft.id}
        checked={isSelected}
        aria-haspopup="menu"
        aria-controls={`block-menu-${draft.id}`}
        aria-expanded={isSelected}
        onChange={handleChange}
      />
      <RadioButton.Title isVisible={mode === 'view'} id={`draft-${draft.id}`}>
        {draft.name}
      </RadioButton.Title>
      {mode === 'view' && (
        <RadioButton.PersonaLabel personaId={draft.personaId} />
      )}
    </RadioButton.Label>
  );
}

NameField.Input = styled('input')(({ theme }) => {
  const typography = theme.typography[TITLE_TYPOGRAPHY];
  return {
    appearance: 'none',
    border: 'none',
    background: 'transparent',
    position: 'absolute',
    height: ITEM_HEIGHT,
    width: '90%',
    insetBlock: 0,
    insetInlineStart: 0,
    zIndex: 1,
    fontSize: '1rem',
    '&:focus': {
      outline: 'none',
    },
    ...typography,
    padding: '0.875rem', // align with RadioButton.Label
  };
});
NameField.Input.displayName = 'NameFieldInput';

function NameField({
  onNameChange,
  inputRef,
  initialValue = '',
}: {
  onNameChange: (name: string) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  initialValue?: string;
}) {
  const t = useTranslate();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [value, setValue] = useState(initialValue);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const handleBlur = useCallback(() => {
    buttonRef.current?.click();
  }, []);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const trimmed = value.trim();
      if (!trimmed) return;
      onNameChange(trimmed);
    },
    [value, onNameChange],
  );

  return (
    // We wrap this in a form to make the action clearer to screen readers,
    // and to allow the user to submit the form by pressing Enter.
    // When the input loses focus the form is submitted.
    <form
      onSubmit={handleSubmit}
      aria-label={t(
        'commEditing.draftList.renameForm.label' satisfies TranslationKeys,
      )}
    >
      <NameField.Input
        ref={inputRef}
        type="text"
        name="draftName"
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        autoComplete="off"
        autoCapitalize="off"
        autoCorrect="off"
        spellCheck={false}
      />
      <button ref={buttonRef} type="submit" hidden />
    </form>
  );
}

InlineMenu.Button = styled('button')(({ theme }) => ({
  paddingInline: '0.75rem',
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  position: 'absolute',
  insetBlock: 4,
  insetInlineEnd: 4,
  borderRadius: 2,
  display: 'grid',
  placeContent: 'center',
  color: theme.palette.grey[10],
  ':hover': {
    color: 'inherit',
  },
  '&[aria-expanded=true]': {
    color: 'inherit',
  },
}));

InlineMenu.Button.displayName = 'InlineMenuButton';

function InlineMenu({
  draft,
  menuOpen,
  setMenuOpen,
  onRenameAction,
  onDeleteAction,
}: {
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onRenameAction: () => void;
  onDeleteAction: () => void;
  draft: PersonaDraft;
}) {
  const handleMenuClick = useCallback(() => {
    setMenuOpen((prev) => !prev);
  }, [setMenuOpen]);

  const handleRenameAction = useCallback(() => {
    setMenuOpen(false);
    onRenameAction();
  }, [onRenameAction, setMenuOpen]);

  const handleDeleteAction = useCallback(() => {
    setMenuOpen(false);
    onDeleteAction();
  }, [onDeleteAction, setMenuOpen]);

  const buttomRef = useRef<HTMLButtonElement>(null);
  const t = useTranslate();

  return (
    <>
      <InlineMenu.Button
        ref={buttomRef}
        id={`inline-menubutton-${draft.id}`}
        aria-label="Open menu"
        aria-haspopup="menu"
        aria-controls={`inline-menu-${draft.id}`}
        aria-expanded={menuOpen}
        onClick={handleMenuClick}
      >
        <Icon
          name="MoreHorizontal"
          {...{ role: 'presentation' }}
          color="currentColor"
        />
      </InlineMenu.Button>
      <Dropmenu
        anchorEl={buttomRef.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        position="right"
        {...{
          id: `inline-menu-${draft.id}`,
          role: 'menu',
          tabIndex: 0,
          hidden: !menuOpen,
        }}
      >
        <Dropmenu.Item onClick={handleRenameAction}>
          {t(
            'commEditing.draftList.inlineMenu.rename' satisfies TranslationKeys,
          )}
        </Dropmenu.Item>
        <Dropmenu.Item onClick={handleDeleteAction}>
          {t(
            'commEditing.draftList.inlineMenu.delete' satisfies TranslationKeys,
          )}
        </Dropmenu.Item>
      </Dropmenu>
    </>
  );
}

BlockMenu.Container = styled('div')(() => ({
  display: 'flex',
  gap: '1rem',
  marginBlock: '.75rem',
}));

BlockMenu.Button = styled(function BlockMenuButton(
  props: ComponentPropsWithoutRef<'button'> & { iconName: LucideIcon['name'] },
) {
  const { iconName, children, ...rest } = props;
  return (
    <button {...rest}>
      <Icon
        name={iconName}
        fontSize="1em"
        {...{ role: 'presentation' }}
        color="currentColor"
      />
      {children}
    </button>
  );
})(({ theme }) => {
  const typography = theme.typography['body/base/regular'];
  return {
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: theme.palette.brand[9],
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    ...typography,
    ':hover': {
      color: theme.palette.brand['dark'],
    },
  };
});

BlockMenu.Divider = styled('div')(({ theme }) => ({
  width: '1px',
  height: '1.5em',
  background: theme.palette.brand[10],
}));

function BlockMenu({
  draft,
  commId,
  onNewDraftPending,
  onNewDraftCancelled,
  onNewDraftCreated,
}: {
  draft: PersonaDraft;
  commId: string;
  onNewDraftPending: (pendingDraft: PersonaDraft) => void;
  onNewDraftCreated: (newDraft: PersonaDraft) => void;
  onNewDraftCancelled: (cancelledDraft: PersonaDraft) => void;
}) {
  return (
    <BlockMenu.Container role="menu" id={`block-menu-${draft.id}`}>
      <DuplicateButton
        draft={draft}
        commId={commId}
        onDraftCreated={onNewDraftCreated}
      />
      <BlockMenu.Divider />
      <VariantButton
        draft={draft}
        onNewDraftPending={onNewDraftPending}
        onNewDraftCancelled={onNewDraftCancelled}
        commId={commId}
      />
    </BlockMenu.Container>
  );
}

function DuplicateButton({
  draft,
  commId,
  onDraftCreated,
}: {
  draft: PersonaDraft;
  commId: string;
  onDraftCreated: (draft: PersonaDraft) => void;
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = useCallback(() => setModalOpen(false), []);
  const handleOpen = useCallback(() => setModalOpen(true), []);
  const t = useTranslate();
  return (
    <>
      <BlockMenu.Button
        role="menuitem"
        iconName="CopyPlus"
        onClick={handleOpen}
      >
        {t('commEditing.draftList.blockMenu.duplicate' as TranslationKeys)}
      </BlockMenu.Button>
      <CommEditingDuplicateModal
        draft={draft}
        isOpen={modalOpen}
        onClose={handleClose}
        onDraftCreated={onDraftCreated}
        commId={commId}
      />
    </>
  );
}

function VariantButton({
  onNewDraftPending,
  onNewDraftCancelled,
  draft,
  commId,
}: {
  onNewDraftPending: (pendingDraft: PersonaDraft) => void;
  onNewDraftCancelled: (cancelledDraft: PersonaDraft) => void;
  draft: PersonaDraft;
  commId: string;
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = useCallback(() => setModalOpen(false), []);
  const handleOpen = useCallback(() => setModalOpen(true), []);
  const t = useTranslate();
  return (
    <>
      <BlockMenu.Button
        role="menuitem"
        iconName="RefreshCw"
        onClick={handleOpen}
      >
        {t('commEditing.draftList.blockMenu.variant' as TranslationKeys)}
      </BlockMenu.Button>
      <CommEditingVariantModal
        isOpen={modalOpen}
        onClose={handleClose}
        onNewDraftPending={onNewDraftPending}
        onNewDraftCancelled={onNewDraftCancelled}
        draft={draft}
        commId={commId}
      />
    </>
  );
}
